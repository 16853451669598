.webcastRecordingBtn {
  composes: webcastNavBtn from '../WebcastNav.module.less';
}
.webcastRecordingBtn :global(.record) {
  color: #c41616;
}
.webcastRecordingBtnActive {
  composes: webcastRecordingBtn;
  color: #fff !important;
  background: #c41616 !important;
}
.webcastRecordingBtnActive :global(.glyphicons) {
  color: #fff;
}
.deviceUnavailableTooltipHost {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
