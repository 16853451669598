.grid {
  min-height: 350px;
}
.searchWrapper {
  padding: 10px;
  margin-right: 48px;
  padding: 10px 0 10px 10px;
  display: flex;
  justify-content: flex-end;
}
.search {
  flex: 0 0 20%;
}
@media screen and (max-width: 1199px) {
  .search {
    flex: 0 0 30%;
  }
}
@media screen and (max-width: 767px) {
  .search {
    flex: 1 1 100%;
  }
}
