webcast-attendees-list .attendee-box {
  height: 100%;
  position: relative;
}
webcast-attendees-list .attendee-box .searchGroup {
  display: flex;
}
webcast-attendees-list .attendee-box .searchGroup .input-group {
  flex: 1 1 85%;
  max-width: 85%;
}
webcast-attendees-list .attendee-box input,
webcast-attendees-list .attendee-box .search-btn {
  height: 35px;
}
webcast-attendees-list .attendee-box .search-btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
webcast-attendees-list .attendee-box .input-group-btn > .btn {
  padding: 8px 12px;
}
webcast-attendees-list .attendee-box .list-container {
  position: absolute;
  top: 40px;
  bottom: 0;
  width: 100%;
  padding: 0 !important;
}
webcast-attendees-list .attendee-box vb-ui-infinite-scroll-grid .ag-body-horizontal-scroll {
  display: none !important;
}
