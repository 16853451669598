@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

.row {
	line-height: 1;
	padding: 5px;
	display: flex;
}

.personalInfo {
	display: flex;
	flex-direction: column;
	flex: 1;
	max-width: 95%;
	justify-content: center;
}

.name {
	&:extend(.TextClip);
	font-weight: bold;
	font-size: @type12;
	line-height: 1.1;
}

.email {
	&:extend(.TextClip);
	font-size: @type10;
	opacity: .8;
	margin-top: 5px;
	line-height: 1.1;
}

.removed {
	background-color: @blackFade10;
	color: @gray60;
}

.imgComp {
	margin-right: 5px;
	margin-top: 1px;
}

.removeRestoreBtn {
	padding: 3px 6px;
}
