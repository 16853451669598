.sectionWrapper {
  display: block;
  justify-content: space-around;
  position: relative;
}
.tileWrapper {
  display: inline-block;
  width: calc(100% / 6 - 1%);
  margin-right: 1%;
}
@media all and (max-width: 768px) {
  .tileWrapper {
    width: 100%;
  }
  .tileWrapper:not(:first-child) {
    display: none;
  }
}
.tileSmall {
  background: #fff;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
.infoContainer {
  padding: 8px;
}
@media all and (max-width: 768px) {
  .infoContainer {
    display: none;
  }
}
.infoContainer h4 {
  margin: 0;
  padding: 0;
}
.greekedText {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  display: inline-block;
  height: 10px;
  width: 100%;
}
.noItems {
  position: absolute;
  z-index: 2;
  top: 40%;
  margin-top: -16px;
  width: 95%;
  text-align: center;
}
@media all and (max-width: 768px) {
  .noItems {
    left: 75px;
    text-align: left;
    top: 50%;
    width: auto;
  }
}
.noItems h4 {
  border: 1px solid rgba(48, 48, 48, 0.1);
  display: inline;
  color: #898989;
  padding: 8px 20px;
  border-radius: 8px;
  background: #fff;
}
@media all and (max-width: 768px) {
  .noItems h4 {
    border: transparent;
    border-radius: 0;
  }
}
@media all and (max-width: 992px) {
  .noItems h4 {
    color: #b0b0b0;
    font-size: 1rem;
  }
}
@media all and (max-width: 1200px) {
  .hideBox {
    display: none;
  }
}
@media all and (max-width: 768px) {
  .fixedRatio {
    display: inline-block;
    height: 40px;
    width: 75px;
  }
}
@media all and (max-width: 768px) {
  .greekedImage {
    margin: 2px 0 0 2px !important;
  }
}
