.presentationViewport {
  composes: presentation-viewport from global;
  position: relative;
}
@media (min-width: 992px) {
  .presentationViewport {
    overflow: hidden;
    position: relative;
  }
}
@media (min-width: 1200px) {
  .presentationViewport :global(.webcast-toolbar) div:first-child {
    padding-left: 16px !important;
  }
}
:global(.vertical-layout) .presentationViewport {
  border-top-width: 1px;
  border-top-style: solid;
}
@media (min-width: 1200px) {
  :global(.vertical-layout) .presentationViewport :global(.webcast-toolbar) div:first-child {
    padding-left: 32px !important;
  }
}
@media (min-width: 992px) {
  :global(.no-video) .presentationViewport {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
