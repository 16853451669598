@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.dashboard-carousel {
	.carousel-cell {
		display: block;
		position: relative !important;
		@media all and (min-width: @viewPortMedWidth) {
			min-width: 16.65%;
			position: absolute !important;
			width: 16.65%;
		}
	}

	.flickity-button:focus {
		box-shadow: none;
		outline: none;
	}

	.flickity-prev-next-button {
		display: none;
		@media all and (min-width: @viewPortMedWidth) {
			display: block;
			height: 30px;
			background: @blackFade20;
			.TransitionAll();
			width: 30px;
		}

		&[disabled] {
			display: none;
		}
	}

	.flickity-prev-next-button.next,
	.flickity-prev-next-button.previous {
		background: transparent;
	}

	.flickity-prev-next-button.previous {
		left: -40px;

		@media all and (max-width: @viewPortLargeWidth) {
			left: -35px;
		}
	}

	.flickity-prev-next-button.next {
		right: -40px;

		@media all and (max-width: @viewPortLargeWidth) {
			right: -35px;
		}
	}

	.flickity-resize .carousel-cell {
		min-height: 100%;
	}

	video-360-indicator {
		position: absolute;
		top: 1.5rem;
		right: 4px;
	}

	@media all and (max-width: (@viewPortMedWidth - 1px)) {
		.flickity-slider {
			position: relative;
			transform: none !important;
		}

		.flickity-viewport {
			height: auto !important;
		}
	}
}

.borderSolidBottom {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.header {
	.flexWrap(nowrap);
}

.headerPrimaryWrapper {
	max-width: 85%;
}

.title {
	color: @white;
	margin: 0;
	padding: 6px;
	.TextClip;
	outline: 1px solid @blackFade00;
	width: 30rem;
	max-width: 30rem;
}

.title,
.titleLink {
	font-size: @type18;
}

.marginBottom {
	margin-bottom: 30px;

	@media all and (max-width: @viewPortMedWidth) {
		margin-bottom: 10px;
	}
}

.browseAll {
	.displayFlex();
	.alignItems(center);
	.flexItem(1);
	.flexJustifyContent(flex-end);
}

.browseAll > a:hover {
	text-decoration: underline;
}
