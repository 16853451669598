//====================== Graphs and Report Styles =============================
//	1, Imports
//	2. Themes
//	3. Main Graph Container
//	4. Box Themes
//	5. Reports
//	6. Data Trends
//	7. Graphs
//	8. Polls
//	9. Shared
//	10. Box Models
//	11. Typography
//	12. Bootstrap Overrides
//===========================================================================*/


@import (reference) 'global/variables';
@import (reference) 'global/mixins';
@import (reference) 'global/animate';

/* refactor to use this as the base class for all analytics */
.modal-body, .reports-container {
	&:extend(.fadein-effect);
}

//====================== Themes Color Variables =============================*/


@blueMedium: #045e81;
@blueDark: #32a1bc;
@cinnamonDark: #754900;
@orangeLight: #ffb075;
@orangeDark: #ff9f53;
@greenBg: #8ac477;
@greenLight: #9fcf91;
@greenDark: #8ac477;
@lightBorder: #c2c9cf;
@turquoiseDark: #27b6ba;
@turquoiseLight: #3ec3c8;
@brownDark: #785251;
@brownLight: #84615F;


//======================= Main Graph Container ==============================*/

.graphs-box-container {
	.flexDisplay;
	margin-bottom: 2em;

	.main {
		overflow: hidden;
		position: relative;
	}

	@media all and (max-width: @viewPortMedWidth - 1px) {
		display: inline;
	}

	.graph-trends, .poll-trends {
		rect[height="1"] {
			display: block !important;
			fill: @lightBorder;
			stroke: @lightBorder;
			stroke-width: 1;
			&:first-child, &:last-child {
				display: none !important;
			}
		}
	}

//=============================== Graphs ====================================*/

	.graph-trends {
		background: @white;
		@media all and (max-width: 991px) {
			margin-bottom: @type16;
		}
	}

//================================ Polls ====================================*/

	.poll-trends {
		display: block;
	}

	.poll {
		padding: 0 1%;

		&-title {
			padding: 0 @type4;
		}

		&-container {
			border: 1px solid @lightBorder;
			.RoundedShape(5px);
			display: inline-block;
			.flexGrow(1);
			margin: .5%;
			width: calc(100% * (1/2) - 1%);
			max-width: calc(100% * (1/2) - 1%);

			@media all and (max-width: @viewPortMedWidth - 1px) {
				width: calc(100% - 1%);
				max-width: calc(100% - 1%);
			}

			header {
				min-height: @type68;
			}

			footer {
				min-height: @type64;
			}

			header, footer {
				background: @accentColor;

				h4 {
					color: @white;
					font-size: @type14;
				}
			}

			.results {
				&-container {
					padding: 1em;
					.poll-responses {
						border-bottom: 1px solid @lightBorder;
						.flexDisplay;
						margin-bottom: .75em;

						p {
							font-size: .9em;
							width: 95%;
						}
					}
				}
			}
			.footer-cell {
				.flexItem(0 1 50%);
				padding: 1em;
				text-align: center;

				small {
					color: @white;
					display: block;
					margin-bottom: .25em;
				}
				.footer-text-large {
					color: @white;
					font-size: 1.75em;
				}
			}
		}
	}

//============================ Shared Styles ================================*/

	.graph-trends, .poll-trends {
		border: 1px solid @lightBorder;
		
		&-inner {
			height: 365px;

			.nvd3 .nv-multibarHorizontal .nv-groups rect {
				fill-opacity: .2; /* can't get reports.less to capture this rule */
			}
		}
	}

	.graph-trends {
		display: inline-flex;
		footer {
			margin-top: auto;
		}
	}

	.graph-trends .glyphicons, .poll-trends .glyphicons {
		bottom: 0;
	}

	vb-data-indicator, .graph-trends, .poll-trends {
		.flexGrow(1);
		.flexShrink(1);
		.flexBasis(0);
		.flexDisplay;
		overflow: hidden;
		position: relative;

		&:not(:last-child) {
			margin-right: 20px;
		}

		@media all and (max-width: @viewPortMedWidth - 1px) {
			display: block;
			margin-bottom: 2em;
			width: 100%;
		}
	}

	.graph-trends, .poll-trends {
		
		footer {
			padding: .25em;
		}

		header {
			padding: 0;
			border-top-style: solid;
			border-top-width: 3px;
		}



		.main {
			max-width: 100%;
			width: 100%;

			.caption {
				color: @white;
				display: inline-flex;
				font-size: @type14;
				margin: @type8 0;
				padding: 0 0 0 1rem;
			}

			> .count-large {
				color: @white;
				font-size: @type52;
				font-weight: normal;
				float: right;
				line-height: 1.25;
				margin-right: 10px;

				&.middle {
					float: none;
					margin-right: 0;
					margin-top: @type12;
					text-align: center;
				}
			}

			.glyphicons {
				color: @white;
				font-size: @type72;
				line-height: 1;
				margin-left: 10px;
				opacity: .2;
				position: absolute;
				left: 5px;
				top: auto;
			}

			.facetime_video {
				.Transform(rotate(-20deg));
			}
		}

		footer {
			.RoundedShape(0 0 5px 5px);
			border-top: 1px solid @white;
			display: inline-flex;
			min-height: 50px;
			overflow: hidden;
			width: 100%;

			.left {
				border-right: 1px solid @white;
			}

			.left, .right {
				padding-bottom: 1em;
				text-align: center;
				width: 50%;

				.title {
					color: @white;
					font-size: @type14;
				}

				.count {
					color: @white;
					font-size: @type32;
					font-weight: bold;
				}
			}
		}
	}

//========================== Box Model ==================================*/

	header, footer {
		> .title {
			margin: @type8 0;
			padding-left: .75em;

			&-small {
				margin: @type8;
			}
		}
	}
	.line {
		&-top {
			&-none {
				border-top: none !important; // important needed during cleanup
			}
		}
	}
	.flex-column {
		.flexDirection(column);
	}
	.flex-row {
		.flexDirection(row);
		.flexDisplay;
		.flexWrap(wrap);
	}

//========================== Typography ================================*/

	.title {
		font-size: @type14;

		&-small {
			font-size: @type14;
		}
		&-light {
			color: @white;
		}

		&-dark {
			color: @blackFade75;
		}
	}

	.progress-wrapper {
		.progress {
			background: @white;
			border: 2px solid @white;
			opacity: .4;
		}
	}

}

//===================== Bootstrap Override =============================*/

[ui-view="reports"] {
	.uib-tab {
		margin-right: @type8;
	}
}

//========================== Graph Styling ================================*/

.graph {
	&-tooltip {
		&-lineplus {
			padding: 5px;
			color: @whiteFade75;

			&-xaxis {
				border-bottom: 1px solid @whiteFade75;
			}
			&-yaxis {
				float: left;
				padding-left: 10px;
				padding-bottom: 10px;

				&-indicator {
					width: 10px;
					height: 10px;
					border: 1px solid @gray;
					float: left;
					margin-top: 3px;
				}
				&-y1color {
					background-color: @orangeDark;
				}
				&-y2color {
					background-color: @cinnamonDark;
				}

			}
		}
	}
}
