.team-box .team-logo {
  -webkit-hyphens: auto;-moz-hyphens: auto;-ms-hyphens: auto;
}
.team-box .team-logo {
  word-break: break-all;word-break: break-word;overflow-wrap: break-word;
}
.team-container .team-total {
  font-size: 1rem;
  padding-top: 25px;
  text-align: right;
}
.team-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
.team-wrapper .team-not-available {
  text-align: center;
}
.team-wrapper .team-link-primary {
  color: #00a0f0;
  text-decoration: underline;
}
.team-heading {
  color: #898989;
  font-size: 1.313rem;
  font-weight: normal;
}
.team-box {
  border: 1px solid #cbcbcb;
  margin-bottom: 30px;
  cursor: pointer;
}
.team-box .team-logo {
  overflow: hidden;
  position: relative;
}
.team-box .team-name {
  left: 50%;
  position: absolute;
  top: 50%;
  line-height: 1.2;
  margin-right: -47%;
  max-height: 100px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  word-break: normal;
}
.team-box .team-name-wrapper {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% !important;
  opacity: 0.08;
  padding: 75px 0;
  text-align: center;
}
.team-box footer {
  background-color: #cbcbcb;
  padding: 5px;
}
.team-box .team-video-count {
  color: #005c8a;
  font-weight: bold;
  margin-left: 5px;
}
.team .team-name {
  line-height: 1.2;
  margin: 0 0 0.5% 0;
  word-break: break-all;
}
