@import (reference) '~rev-less/global/variables';

.resultsContainer {
	color: @color1;
 }

 .headerTitle {
	font-size: @type16;
	padding-left: 16px;
 }
