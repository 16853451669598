.root {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 16px;
}
.root h2 {
  margin: 4px 0;
}
.root h4 {
  margin: 10px 0;
  font-weight: bold;
}
.sectionWrapper {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.supplementalContentItem {
  padding: 10px 0;
}
.supplementalContentItem :global(.glyphicons) {
  display: block;
  margin-right: 8px;
  font-size: 1.5rem;
}
.fileIcon {
  margin-right: 8px;
}
.fileIcon::before {
  color: inherit;
}
