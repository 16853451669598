@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

search-header-dropdown {
	.search-cell {
		vb-ui-typeahead input,
		.main-nav-btn {
			border-top-width: 1px;
			border-top-style: solid;
			border-bottom-width: 1px;
			border-bottom-style: solid;
		}
		vb-ui-typeahead input, .main-nav-btn {
			border-left-width: 1px;
			border-left-style: solid;
		}
		.main-nav-btn {
			border-right-width: 1px;
			border-right-style: solid;
			.BoxShadow(0 0 10px 2px @blackFade25);

			@media all and (max-width: @viewPortMedWidth) {
				padding: 0 10px;
			}
		}
		vb-ui-typeahead input {
			.BoxShadow(-1px 0 10px 2px @blackFade25) !important;

			.Placeholder({
				color: @blackFade75 !important;
			});

			&:focus {
				.Placeholder({
					color: transparent !important;
				});
			}
		}

		@media all and (max-width: @viewPortMedWidth) {
			top: 75px !important;
		}

		@media all and (max-width: @viewPortSmallWidth) {
			top: 53px !important;
		}
	}
}
