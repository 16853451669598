@import  (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@import "../../ui/sidebarLayoutAngular/VbUiSidebar.Component.module.less";

@import "./startAndEndMessage/WebcastStartAndEndMessage.Component.module.less";

.spinnerWrap {
	height: 100px;
}

.presentationViewport {
	composes: presentation-viewport from global;

	position: relative;

	@media(min-width: 992px) {
		overflow: hidden;
		position: relative;
	}

	@media(min-width: 1200px) {
		:global(.webcast-toolbar) {
			div:first-child {
				padding-left: 16px !important;
			}
		}
	}

	:global(.vertical-layout) & {
		border-top-width: 1px;
		border-top-style: solid;

		@media(min-width: 1200px) {
			:global(.webcast-toolbar) {
				div:first-child {
					padding-left: 32px !important;
				}
			}
		}
	}

	@media(min-width: 992px) {
		:global(.no-video) & {
			.flexItem(1 1 100%);
		}
	}
}

.sidebarLayout {
	[slot="panelBody"] {
		height: 100%;
	}
}

.videoAndPresentationWrapper {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.videoAndPresentationWrapperWithSlides {
	bottom: 256px;
	@media(max-width: 991px) {
		bottom: 0;
	}
}

.border {
	composes: theme-primary-font-border-fade-50 from global;
	border-width: 1px;
	border-style: solid
}

.bottomBorder {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.fixedPosition {
	position: fixed !important;
	left: 0;
	right: 0;
	min-width: 0; // Firefox flex bug workaround
	width: 100%;
}

//will import this to vb-ui-sidebar in phase 2
.sidebarOpenedInMobile {
	vb-ui-sidebar {
		.fixedPosition;
		top: 0;
		bottom: 0;
		height: 100%;
		z-index: 1030;

		:global(.sidebarPanelWrapper) {
			.fixedPosition;
			top: 0;
			bottom: @sidebarButtonHeight !important;
		}

		:global(.sidebarButtonsWrapper) {
			.fixedPosition;
			bottom: 0;
		}
	}
}

