featured-video-thumbnail-grid {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
featured-video-thumbnail-grid .outer-layout {
  height: 100%;
}
featured-video-thumbnail-grid featured-video-thumbnail {
  height: auto;
}
featured-video-thumbnail-grid .quarter-grid featured-video-thumbnail:nth-child(2):last-child {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
