.Round {
  border-radius: 9999px;
}
.RoundedButton {
  border-radius: 3px;
}
.PointerCursor {
  cursor: pointer;
}
.TextClip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.whiteSpacePreWrap {
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
.HyphensAuto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.WordBreak {
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
.GlyphiconFont {
  font-family: 'Glyphicons Regular';
}
.ButtonGlyphicons {
  font-size: 1rem;
  top: 3px;
}
.clearFix {
  clear: both;
  content: "";
  display: table;
}
/**
 * Flex Layout Support
 * ----------------
 * Prefixed = IE10, Safari 8-, iOS 8.1-, Android 4.3-
 * Standard = IE11+, Chrome, Android 4.4+
 */
.flexDisplay {
  display: -webkit-flex;
  display: flex;
}
.flexContainerStatic {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
/*
 * IE9 not supported
 * IE10+ unprefixed
 * FF unprefixed
 * Safari 6.1+ unprefixed
 * Chrome 26+ unprefixed
 * Android 4.0 - 4.3 prefixed, 4.4+ unprefixed
 */
.AbsoluteFill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.FixedFill {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.BtnWhite {
  background: linear-gradient(to bottom, #fff, #f3f3f3);
  border-color: #b2b2b2;
  box-shadow: inset 0 1px 0 #fff;
  color: #303030;
}
.BtnWhiteActive {
  color: #005c8a;
  border-color: rgba(0, 92, 138, 0.25);
  background: #d7f2ff;
  box-shadow: none;
}
.focusedInput {
  border-color: #00a0f0;
  outline: 0;
  box-shadow: inset 0 0 0 1px #00a0f0, 0 0 10px rgba(0, 0, 0, 0.25);
}
.focusedItem {
  /* bootstrap */
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.AbsoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.VerticalCenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ScrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
.primary-color {
  color: #00a0f0;
}
vb-ui-typeahead {
  display: block;
}
.theme-Nettrix search-header-dropdown .search-cell {
  border-radius: 0;
  z-index: -1;
}
.theme-Nettrix search-header-dropdown .search-cell {
  border-top: 1px solid #909090;
  border-bottom: 1px solid #909090;
  position: fixed;
  top: 90px;
  width: 100%;
}
.theme-Nettrix search-header-dropdown .search-cell vb-ui-typeahead input[vb-text-input] {
  background: #dfdfdf;
  box-shadow: none;
  display: block;
  top: 5px;
}
@media all and (max-width: 992px) {
  .theme-Nettrix search-header-dropdown .search-cell vb-ui-typeahead input[vb-text-input] {
    top: 6px;
  }
}
.theme-Nettrix search-header-dropdown .search-cell vb-ui-typeahead input[vb-text-input]:focus {
  border: none;
  box-shadow: none;
}
.theme-Nettrix search-header-dropdown .search-cell [role="search"] {
  height: 68px;
  margin: 0 auto;
  padding: 0.75rem;
  width: 75%;
}
@media all and (max-width: 767px) {
  .theme-Nettrix search-header-dropdown .search-cell [role="search"] {
    padding: 15px;
    width: 100%;
  }
}
.theme-Nettrix search-header-dropdown .btn.main-nav-btn {
  margin-top: 0;
}
