@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

/*  These classes are confusingly named  */
/*  ASSIGNED and ALL-ELEMENTS are the outer containers */

.all-elements,
.assigned-elements {
	width: 50%;
	position: relative;
}

/*  AVAILABLE-ITEMS and SELECTED-ITEMS are the inner containers */

.available-items,
.selected-items {
	margin: 0;
	height: 250px;
}

.role-select {
	.available-items,
	.selected-items {
		height: 205px;
	}
}

@media(min-width: 992px) {
	.available-items,
	.selected-items {
		height: 350px;
	}
}

@media(min-width: 1400px) {
	.library-panel .available-items,
	.library-panel .selected-items
	{
		height: 500px;
	}
}

.assigned-elements .form-control-search,
.all-elements .form-control-search,
.all-elements .table-tools .btn,
.assigned-elements .table-tools .btn {
	border-color: fade(@black, 20%);
}

.admin .admin-multiselect {
	background: rgb(198, 200, 202);
}

.admin-multiselect {
	background: @blackFade10;
	.RoundedShape(0);
	border-bottom: 2px solid @blackFade25;

	.no-padding & {
		position: relative;
		top: -3px;
		margin-bottom: -6px;
	}

	.table-tools {
		background: @blackFade05;
		margin-top: 0 !important;
		padding-bottom: 5px;
		padding-top: 5px;
		border-bottom: 1px solid fade(@black, 20%);
	}

	.dynamic_ok_or_remove .glyphicons:before {
		content: "\E208";
	}

	.selected-items .dynamic_ok_or_remove .glyphicons:before {
		content: "\E207";
		color: rgb(0,177,60);
	}


	.combined-toolbar .active .input-group {
		border-color: #7d7d7d;
		background: @accentColor;
	}

	.panel-sub-heading {
		border-top: 1px solid @blackFade10;
		border-bottom: 1px solid @blackFade25;
		padding: 0 15px;
		font-size: 13px;
		text-shadow: none;
		font-weight: normal;
		vertical-align: middle;
		.RoundedShape(0);
		line-height: 36px;
		background: rgb(187,189,191) !important;
		color: @color1MedText;

		.filter-input {
			padding-top: 2px;
		}

		.combined-toolbar .input-group {
			border: none;
			.RoundedShape(10px);
			background-color: @whiteFade50;
			color: @whiteFade75;
		}
	}

	.table-tools .btn {
		background: none;
		border-color: @blackFade10;

		&:hover {
			background: @blackFade10;
		}
	}

	h4 {
		font-size: 13px;
		margin-top: 0;
		margin-bottom: 0;
		text-transform: uppercase;
		font-weight: bold;
		color: @color1MedText;
		line-height: 36px;
	}
}

.admin {
	.filters {
		background: fade(@black, 20%);
		margin: -15px -15px 15px -15px;
		padding: 15px 0;

		&:before {
			content: " ";
			border-bottom: 7px solid fade(@black, 20%);
			border-right: 7px solid transparent;
			border-left: 7px solid transparent;
			position: absolute;
			top: -8px;
			left: 30px;
		}
	}

	.admin-multiselect {
		.panel-sub-heading .btn {
			background: @whiteFade75;
			.RoundedShape(4px);
			border: 1px solid @blackFade25;
			.BoxShadow(inset 0 0 2px @white);
			height: 24px;
			padding-top: 0;
			padding-bottom: 0;

			&:hover {
				background: @whiteFade50;
			}
		}

		.combined-toolbar .filter-input .input-group .btn {
			padding: 6px 7px 6px 10px;
			border-left: 0;
			.BoxShadow(none);

			.glyphicons {
				font-size: 14px;
				top: -1px;
			}
		}
	}

	.panel-sub-heading .combined-toolbar .filter-input .input-group {
		width: 150px;
		line-height: 0;

		.input-group-btn {
			line-height: 0;
		}
	}
}

.admin-multiselect {
	.RoundedShape(4px);
}

.admin-movable-item .hover-wrap.write-access {
	padding-right: 4px;
}

.available-items .write-access {
	display: none;
}


.write-access .btn.btn-white {
	padding: 3px 5px;
	vertical-align: middle;
}

.selected-items .write-access {
	display: inline-block;
	text-transform: uppercase;
	font-size: 9px;
	font-weight: 500;
	color: #7d7d7d;

	> .btn-admin {
		margin-left: 5px;
	}
}

.panel-body.no-padding .admin-multiselect .table-tools,
.admin-multiselect .table-tools {
	background: none;
	border-bottom: none;
	padding: 8px 15px;
	margin-bottom: 0;
}

.admin-light .panel-sub-heading .combined-toolbar .search-query-field {
	.form-control,
	.btn {
		height: 24px;
	}

	.form-control {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.multi-select-error {
	position: relative;
	background: @white;
	padding: 15px;

	.alert {
		text-align: center;
		margin-bottom: 0;
	}
}

/*@media (max-width: 991px){
	.table-display.admin-multiselect {
		display: block;
	}

	.assigned-elements,
	.all-elements {
		display: block;
		width: 100%;
	}
}*/

@media(min-width: 992px) and (max-width: 1200px){
	.admin-multiselect .combined-toolbar .btn.btn-icon-left .glyphicons {
		margin-right: 0;
	}
}

@media(min-width: 768px) and (max-width: 1024px) {
	.admin .tab-content .table-cell {
		display: inline-block;
		width: 50%;

		&.search-query-field.filter-input {
			display: none !important;
		}
	}
}

@media(max-width: 1200px){
	.table-display.admin-multiselect .table-cell-fill {
		max-width: 300px;
	}
}

@media(max-width: 768px) {
	.assigned-elements,
	.all-elements {
		display: block !important;
		width: 100% !important;
	}

	.available-items,
	.selected-items {
		height: auto;
		padding: 10px 0;

		.repeater-container {
			overflow-y: visible !important;
		}
	}
}

@media(min-width: 768px) {
	.admin-multiselect h4 {
		line-height: 14px;
	}
}
