search-header-dropdown .search-cell vb-ui-typeahead input,
search-header-dropdown .search-cell .main-nav-btn {
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
search-header-dropdown .search-cell vb-ui-typeahead input,
search-header-dropdown .search-cell .main-nav-btn {
  border-left-width: 1px;
  border-left-style: solid;
}
search-header-dropdown .search-cell .main-nav-btn {
  border-right-width: 1px;
  border-right-style: solid;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.25);
}
@media all and (max-width: 992px) {
  search-header-dropdown .search-cell .main-nav-btn {
    padding: 0 10px;
  }
}
search-header-dropdown .search-cell vb-ui-typeahead input {
  box-shadow: -1px 0 10px 2px rgba(0, 0, 0, 0.25) !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.75) !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.75) !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.75) !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.75) !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input:focus:-moz-placeholder {
  color: transparent !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input:focus::-moz-placeholder {
  color: transparent !important;
}
search-header-dropdown .search-cell vb-ui-typeahead input:focus:-ms-input-placeholder {
  color: transparent !important;
}
@media all and (max-width: 992px) {
  search-header-dropdown .search-cell {
    top: 75px !important;
  }
}
@media all and (max-width: 768px) {
  search-header-dropdown .search-cell {
    top: 53px !important;
  }
}
