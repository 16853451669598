@import (reference) "~rev-less/global/variables";

.root {
	min-height: 800px;
	height: 100vh; // responsive once the minimum is met
}

.eventEnded {
	font-size: @type24;
}

.kpiContainer {
	padding: 1rem 1rem 0;
}

.tabPanel {
	padding: 0 1rem 1rem;
}