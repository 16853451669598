vb-search-filter-list {
  display: block;
  margin-bottom: 10px;
}
vb-search-filter-list .search-filter-item {
  display: inline-block;
  margin: 0 5px 4px 0;
  padding: 2px 10px 5px;
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.9);
  background: #004b71;
}
vb-search-filter-list .search-filter-item button.close {
  margin-left: 10px;
  position: relative;
  top: 1px;
  float: none;
  color: #fff;
  font-weight: normal;
}
@media (max-width: 767px) {
  vb-search-filter-list {
    display: none;
  }
}
