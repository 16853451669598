@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vb-webcast-question-card {
	display: flex;
	flex-flow: column;
	position: relative;
	padding: 1px;
	word-wrap: break-word;
	word-break: break-word;

	article {
		position: relative;
	}

	.new-message {
		background: @highlightLighten25;
		border: 1px solid @blackFade10;
		border-radius: 15px;
		height: 30px;
		max-height: 30px;
		max-width: 30px;
		padding: 0;
		position: absolute;
		right: -5px;
		width: 30px;

		@media all and (max-width: 768px) {
			height: 20px;
			width: 20px;
		}

		.bell-middle {
			color: @gray60;
			left: 8px;
			top: 7px;

			@media all and (max-width: 768px) {
				left: 3px;
				top: 3px;
			}
		}
	}

	.aside-icon {
		margin-left: 0;

		.group {
			font-size: @type12;
			padding: 5px 0 0 5px;
		}

		&.icon-queued {
			margin-top: -5px;
		}
	}

	.icon-block {
		display: block;
	}

	.question-info {
		p {
			margin-bottom: @typeUnit1-4x;
		}
	}

	.question-number {
		font-size: @type12;

		&:after {
			content: '.';
		}
	}

	.question-status {
		font-size: @typeUnit3-4x;
		width: @typeUnit1_5x;
		border-width: 1px;
		border-style: solid;

		&.open-question:before {
			content: '?';
		}

		&.circle {
			height: @typeUnit1_5x;
			line-height: @typeUnit1_5x;
		}

		&.ok_2 {
			font-weight: normal;
		}

		&.question-status-detail {
			&:extend(.TextClip);
			.RoundedShape(4px);
			font-size: @type10;
			text-align: center;
			width: 60px;

			&.reply {
				background-color: @accentColor;
				color: @white;
			}

			&.closed {
				background-color: @blackFade10;
				color: @color1LightText;
			}
		}
	}

	.question-qtext {
		font-size: @type14;
		font-weight: 700;
		white-space: pre-line;
		margin: 0 0 @typeUnit1-2x 0;
		width: 95%;
		max-width: 95%;
		padding-top: 4px;
	}
	.question-from,
	.question-when-asked {
		font-size: @type12;
		line-height: @type14;
		display: block;
	}

	.question-reply,
	.question-answered-by-speaker {
		display: block;
		padding: @typeUnit1-2x;
		margin-top: @typeUnit1-2x;
		margin-right: @typeUnit1x;
		line-height: 1;

		&:before {
			content: '>';
			margin-right: @typeUnit1-2x;
		}
	}

	.question-reply .comment-body {
		white-space: pre-line;
	}

	.question-reply-by {
		display: block;
		font-style: italic;
		margin-top: @typeUnit1-2x;
		margin-left: @typeUnit1x;

		&:before {
			content: '\2014';
			margin-right: @typeUnit1-4x;
		}
	}

	.question-mod-buttons {
		margin: 0;

		button {
			border: none;
			width: 100%;

			.label {
				&:extend(.TextClip);
				font-size: @type14;
				padding: @typeUnit1-4x 0;
				position: relative;
				top: -1px;

				@media all and (max-width: 480px) {
					display: block;
				}
			}
		}
	}

	.closedQueueStatusDropdown {
		position: absolute;
		top: 2px;
		right: 10px;

		.dropdown-toggle {
			padding: 2px 10px !important;

			.more-icon {
				width: auto;

				.circle {
					display: block;
					width: 3px;
					height: 3px;
					margin-bottom: 2px;
				}
			}
		}

		.dropdown-menu {
			.RoundedShape(@defaultBorderRadius) !important;
			top: 20px;
			left: auto;
			right: 0;

			li {

				border-bottom: none;

				> a {
					padding: @typeUnit1-2x @typeUnit1x @typeUnit1-2x @typeUnit1x ;
				}

				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}

			.glyphicon,
			.glyphicons {
				color: @black;
				margin-right: @typeUnit1-2x;
			}
		}
	}

	&.direct-reply-in-progress {
		> span:not(.question-status),
		.dropdown-toggle,
		.question-mod-buttons {
			opacity: .4;
		}

		.question-reply-inprogress {
			font-style: italic;
			font-weight: normal;
			white-space: normal;
		}
	}

	.options {
		min-height: 25px;
	}

	.question-mod-buttons {
		margin: 0;
		padding: 2px;
	}

	.webcast-manage-questions-full-screen & {
		.inbox-view {
			.question-qtext {
				font-size: @type14;
				margin-bottom: 0;
			}
		}

		.queue-view {
			.question-qtext {
				font-size: @type16;
			}
		}
	}

	.drag-handle {
		display: none;
	}

	.draggable & {
		.drag-handle {
			display: block;
			position: absolute;
			top: 5px;
			bottom: 0;
			left: 0;
			right: 5px;
			padding: 0;

			.CursorGrab();

			.glyphicons {
				position: absolute;
				top: 4px;
				right: 9px;
			}

			@media (max-width: @viewPortMedWidth) {
				left: auto;
				bottom: auto;
			}
		}
	}

	@media (max-width: @viewPortLargeWidth) {
		.question-number,
		.width-full h5.question-qtext{
			font-size: @type18;
		}
	}
}

[flex="webcast-qa-card-viewer"] {
	.flexItem(0 1 85%);
}
[flex="webcast-qa-card-moderator"] {
	.flexItem(0 1 90%);
}
