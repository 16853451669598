.webcast-presentation-msg-container {
  position: absolute;top: 50%;left: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);
}
.ppt-loading-wrapper {
  text-align: center;
  padding: 2em;
}
.presentation-slide {
  text-align: center;
}
.presentation-slide img {
  max-width: 100%;
  margin: 0 auto;
}
.presentation-is-full-screen .presentation-slide {
  text-align: center;
}
.presentation-controls .icon-slide-prev,
.presentation-controls .icon-slide-next {
  position: absolute;
  top: 50%;
  font-size: 3rem;
  color: #808080;
  text-shadow: 1px 1px 4px #000;
  opacity: 0.25;
}
.presentation-controls .icon-slide-prev {
  left: 0;
}
.presentation-controls .icon-slide-next {
  right: 0;
}
.webcast-presentation-msg-container {
  border-radius: 3px;
}
