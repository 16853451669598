@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.show-pointer {
	&:extend(.PointerCursor);
}

.available-items,
.selected-items {
	.repeater-container {
		height: 100%;
		overflow-y: auto;
		padding-right: 4px;
	}

	.vs-repeat-repeated-element {
		left: 15px;
		right: 15px;
	}
}
