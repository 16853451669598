@import (reference) '~rev-less/global/variables';

speech-search-result {
	display: block;
	margin-bottom: @typeUnit1x;
	position: relative;
	padding: 0 60px;
	word-wrap: break-word;

	.file-list-row.inactive & a {
		color: @color1LightText;
	}

	.speech-search-result-time {
		margin-right: @typeUnit1x;
		position: absolute;
		left: 0;
	}

	.speech-search-result-excerpt {
		em {
			font-weight: bold;
			font-style: normal;
		}
	}
}