@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vb-checkbox {
	display: inline-block;
	vertical-align: middle;

	.labelWrapper {
		word-break: break-word;
	}

	button,
	.vb-checkbox-label {
		font-size: @type14;

		&[disabled] {
			cursor: not-allowed;

		}
	}

	button {
		.RoundedShape(4px);
		border: 1px solid @lightBorder;
		font-size: @type12;
		margin-right: .25rem;
		overflow: hidden;
		padding: 2px;
		position: relative;
		height: 18px;
		width: 18px;

		&:not(.active) {
			background-color: @white;

			.glyphicons {
				visibility: hidden;
			}
		}

		.glyphicons {
			vertical-align: top !important;
		}

		&[disabled] {
			opacity: 0.5;
		}
	}

	&.large {
		button {
			font-size: @type16;
			width: 24px;
			height: 24px;
		}
	}
}
