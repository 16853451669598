playlist-detail {
    .media-layout {
        width: 100%;
    }

    .table-cell {
        video-360-indicator {
            display: inherit;
            margin: 0 .25rem;
        }
    }

    .playlist-nav-row {
        height: 42px;
    }
}