@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.listWrap {
	margin-bottom: 5px;
	min-height: 70px;
	padding-left: 10px;
}

.listItem {
	composes: theme-accent-link from global;
	border-bottom: 1px solid @color1Lighten65;
	list-style-position: inside;
	padding: 10px;
	.TextClip;
}

.listItemText {
	font-size: @type13;
}

.listItemText,
.listItemText:hover {
	color: @color0Fade95;
}

.listItemText:hover {
	border-bottom: 1px solid @color0Fade95;
	text-decoration: none;
}
