mobile-menu {
  align-self: center;
  order: 0;
}
@media (max-width: 992px) {
  mobile-menu {
    flex-grow: 1;
  }
}
mobile-menu a,
mobile-menu a:hover,
mobile-menu a:active,
mobile-menu a:focus {
  color: inherit;
}
mobile-menu .btn {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  color: rgba(255, 255, 255, 0.5);
  height: 36px;
  padding: 6px 9px 6px 12px;
}
mobile-menu .btn .glyphicons {
  font-size: 18px;
  vertical-align: middle;
  top: 0;
}
mobile-menu .btn-mobile {
  z-index: 10001;
}
mobile-menu .btn-mobile-open {
  left: 105px;
}
mobile-menu .mobile-menu {
  background-color: #141414;
  border-right-width: 1px;
  border-right-style: solid;
}
mobile-menu .mobile-menu-list-item {
  margin-bottom: 10px;
}
mobile-menu .mobile-menu-list-item a {
  display: block;
  font-size: 1.313rem !important;
  padding: 15px !important;
  text-align: center;
  text-transform: uppercase;
}
mobile-menu .mobile-menu-list-item a.media-video-item {
  font-size: 0.875rem;
  text-transform: none;
}
mobile-menu .mobile-menu-list-item.active {
  position: relative;
}
mobile-menu .mobile-menu-list-item.active a {
  position: relative;
  text-decoration: underline;
  z-index: 2;
}
mobile-menu .mobile-menu-list-item.active:after {
  content: '';
  height: 100%;
  width: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
mobile-menu .mobile-menu-list-item.active:hover:after {
  content: none;
}
mobile-menu .mobile-menu-list-item.active:hover a {
  text-decoration: none;
}
mobile-menu .mobile-menu-list my-videos-menu-items li {
  margin-bottom: 10px;
}
mobile-menu .mobile-menu-list my-videos-menu-items li.active {
  position: relative;
}
mobile-menu .mobile-menu-list my-videos-menu-items li.active a {
  position: relative;
  text-decoration: underline;
  z-index: 2;
}
mobile-menu .mobile-menu-list my-videos-menu-items li.active:after {
  content: '';
  height: 100%;
  width: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
mobile-menu .mobile-menu-list my-videos-menu-items li:hover:after {
  content: none;
}
mobile-menu .mobile-menu .media-video-item,
mobile-menu .mobile-menu .help-menu-item {
  font-size: 1rem !important;
  margin: 0;
  padding: 10px !important;
}
mobile-menu .mobile-menu .media-video-item:hover,
mobile-menu .mobile-menu .help-menu-item:hover {
  text-decoration: underline;
}
mobile-menu .clear-overlay:after {
  content: none;
}
mobile-menu .apply-overlay {
  position: relative;
}
mobile-menu .apply-overlay a {
  position: relative;
  text-decoration: underline;
  z-index: 2;
}
mobile-menu .apply-overlay:after {
  content: '';
  height: 100%;
  width: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
