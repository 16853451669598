.preview-active .insight .widget-options {
  right: 16px;
}
.preview-widget {
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fafafa;
  height: 445px;
  position: relative;
  overflow: hidden;
}
.preview-widget .btn-preview {
  background: none;
  border: none;
  color: rgba(48, 48, 48, 0.5);
  font-size: 24px;
  height: 48px;
  line-height: 24px;
  padding: 0 12px;
  margin-left: 16px;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1000;
}
.preview-widget .btn-preview.active {
  outline: none;
  box-shadow: none;
}
.preview-widget .btn-preview:active,
.preview-widget .btn-preview:active:focus {
  outline: none;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
}
.preview-widget .preview-spacer {
  width: 64px;
}
.preview-widget .assigned-items {
  padding: 0;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  z-index: 0;
}
.preview-widget .preview-column {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
}
.preview-widget .preview-column.animate-if.ng-enter,
.preview-widget .preview-column.animate-if.ng-leave {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
}
.preview-widget .preview-column.animate-if.ng-enter,
.preview-widget .preview-column.animate-if.ng-leave {
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  z-index: 0;
}
.preview-widget .preview-column.animate-if.ng-enter,
.preview-widget .preview-column.animate-if.ng-leave.ng-leave-active {
  opacity: 0;
  right: -41.66666667%;
}
.preview-widget .preview-column.animate-if.ng-leave,
.preview-widget .preview-column.animate-if.ng-enter.ng-enter-active {
  opacity: 1;
  right: 0;
}
.preview-widget .preview-column .btn-preview {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1000;
}
.preview-widget .preview-column.bold-theme {
  background: #00a0f0;
  color: white;
  border-left: none;
}
.preview-widget .preview-column.bold-theme .preview-item > h3 {
  color: white;
}
.preview-widget .preview-column .preview-item {
  position: relative;
}
.preview-widget .preview-column .preview-item > h3 {
  color: #00a0f0;
  text-align: center;
  border-bottom: 1px solid #cbcbcb;
  padding-bottom: 24px;
}
.preview-widget .preview-column h1 {
  text-align: center;
  margin-top: 0;
}
.preview-widget .preview-column .circle {
  height: 64px;
  width: 64px;
  border-radius: 9999px;
  background: #cbcbcb;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  text-align: center;
  line-height: 64px;
  color: rgba(48, 48, 48, 0.5);
}
.preview-widget .preview-column .scroll-message {
  height: 100%;
  width: 100%;
  text-align: center;
}
.preview-widget .preview-column .scroll-message h3 {
  margin: 0;
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  color: rgba(48, 48, 48, 0.5);
}
.preview-widget .scroll-container {
  position: absolute;
  top: 32px;
  right: 16px;
  left: 16px;
  bottom: 16px;
  overflow-y: scroll;
}
.preview-widget.show-preview .scroll-container {
  border-radius: 6px;
  background: white;
  z-index: 900;
}
.ui-match {
  background: hsl(60, 100%, 50%) !important;
  color: hsl(0, 0%, 8%) !important;
}
.insight-option-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  justify-content: space-between;
}
.insight-option-row .insight-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.insight-option-row .insight-option > span {
  line-height: 15px;
  font-size: 15px;
}
.insight-option-row .insight-option > span.insight-sub-display {
  line-height: 14px;
  font-size: 11px;
  text-transform: capitalize;
  color: #909090;
}
.insight-option-row .plus {
  color: rgba(48, 48, 48, 0.5);
}
.insight-option-row:active {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}
.insight-option-row:hover {
  background: rgba(0, 160, 240, 0.1);
  background: rgba(0, 0, 0, 0.05);
}
.insight-option-row:hover:focus,
.insight-option-row:hover:active,
.insight-option-row:hover:focus:active {
  background: rgba(0, 160, 240, 0.2);
  background: rgba(0, 0, 0, 0.1);
}
.assigned .insight-option-row,
.assigned .insight-option-row:hover {
  color: #00a0f0;
}
.assigned .insight-option-row .btn-assigned,
.assigned .insight-option-row:hover .btn-assigned {
  color: #00a0f0;
}
.assigned .insight-option-row span.insight-sub-display,
.assigned .insight-option-row:hover span.insight-sub-display {
  color: #fff;
}
.assigned .insight-option-row .circle,
.assigned .insight-option-row:hover .circle {
  background: rgba(0, 160, 240, 0.1);
  color: #00a0f0;
}
.insight-option-row .btn-link {
  color: #898989;
}
.insight-option-row .btn-link:hover {
  text-decoration: none;
}
.insight-option-row .btn-link.active,
.insight-option-row .btn-link:active,
.insight-option-row .btn-link:focus,
.insight-option-row .btn-link:active:focus {
  outline: none;
  box-shadow: none;
  text-decoration: none;
}
.insight-option-row .btn-link[disabled] {
  color: rgba(48, 48, 48, 0.5);
}
.insight-option-row .btn-link[disabled].btn-edit-access {
  background: rgba(48, 48, 48, 0.5);
}
.insight-option-row .btn-link.remove-assigned-item {
  color: rgba(48, 48, 48, 0.5);
  position: relative;
  top: 0;
  right: 0;
  border-radius: 0;
  height: 40px;
  width: 50px;
  z-index: 10;
}
.insight-option-row .btn-edit-access {
  background: none;
  color: #898989;
}
.insight-option-row .btn-edit-access.active {
  background: #00a0f0;
  color: white;
}
.insight-option-row > div {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0;
  cursor: pointer;
  position: relative;
  height: 45px;
  line-height: 36px;
}
.insight-option-row > div.flex-fill {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.insight-option-row > div .circle {
  height: 30px;
  width: 30px;
  border-radius: 9999px;
  background: #cbcbcb;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  text-align: center;
  line-height: 28px;
  color: rgba(48, 48, 48, 0.5);
  margin-top: 4px;
}
.insight-option-row > div .badge {
  margin-right: 8px;
  height: 18px;
  margin-top: 11px;
  font-size: 0.75rem;
  padding: 0 8px;
  border-radius: 16px;
  border: none;
}
.insight-option-row > div .badge.add-item {
  background: #00a0f0;
}
.insight-option-row.ng-move,
.insight-option-row.ng-enter,
.insight-option-row.ng-leave {
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
.insight-option-row.ng-leave.ng-leave-active,
.insight-option-row.ng-move,
.insight-option-row.ng-enter {
  opacity: 0;
  max-height: 0;
}
.insight-option-row.ng-leave,
.insight-option-row.ng-move.ng-move-active,
.insight-option-row.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 40px;
}
.no-flexbox .insight-option-row {
  display: table;
  width: 100%;
}
.no-flexbox .insight-option-row > div {
  display: table-cell;
  width: 1px;
  vertical-align: middle;
}
.no-flexbox .insight-option-row > div.flex-fill {
  width: auto;
}
.no-flexbox .insight-option-row > div .circle {
  top: -3px;
}
.insight {
  width: 100%;
  position: relative;
  height: 436px;
}
.insight:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}
.insight .overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.insight .search-field-wrapper {
  height: 64px;
  z-index: 20;
  position: absolute;
  top: 16px;
  left: 0;
  right: 0;
  padding: 0 16px;
  border-bottom: 1px solid #cbcbcb;
}
.insight .search-field-wrapper .search-field-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  justify-content: space-between;
}
.insight .search-field-wrapper .search-field-inner > div {
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
}
.insight .search-field-wrapper .search-field-inner > div.flex-fill {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.insight .search-field-wrapper .search-field-inner > div > .btn-group {
  padding-left: 15px;
}
.insight .search-field-wrapper .search-field-inner > .btn {
  padding-left: 15px;
}
.insight .search-field-wrapper .search-field {
  position: relative;
  top: 0;
  height: 48px;
  background: white;
  z-index: 1;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
}
.insight .search-field-wrapper .search-field .icon {
  position: absolute;
  left: 12px;
  top: 12px;
  color: rgba(0, 0, 0, 0.25);
  font-family: 'Glyphicons Regular';
  font-style: normal;
  font-size: 24px;
}
.insight .search-field-wrapper .search-field input {
  background: none;
  width: 100%;
  padding: 0 16px;
  line-height: 36px;
  height: 48px;
  text-align: center;
  color: #005c8a;
  border: 1px solid transparent;
}
.insight .search-field-wrapper .search-field input:focus {
  outline: none;
}
.insight .search-field-wrapper .search-field.focus {
  border: 1px solid rgba(0, 0, 0, 0.35);
}
.insight .search-field-wrapper .search-field.focus .icon {
  color: #00a0f0;
}
.insight .search-field-wrapper .search-field .btn-close {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 38px;
  z-index: 100;
  background: rgba(0, 0, 0, 0.35);
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: white;
}
.insight .search-field-wrapper .search-field .loader.accent {
  border-color: #565656;
  border-left-color: #00a0f0;
  position: absolute;
  top: 14px;
}
.insight .widget-options {
  position: absolute;
  top: 48px;
  left: 16px;
  right: 16px;
  z-index: 21;
  height: 375px;
}
.insight .widget-options .subhead {
  font-size: 10px;
  color: #aaa;
  padding: 0 16px;
  cursor: default;
  line-height: 32px;
  height: 32px;
  border-bottom: 0;
  margin-top: 8px;
  text-transform: uppercase;
}
.insight .widget-options .subhead:hover {
  background: none;
}
.insight .widget-options:before {
  content: '';
  position: absolute;
  left: 10px;
  top: -11px;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent rgba(0, 0, 0, 0.35) transparent;
}
.insight .widget-options:after {
  content: '';
  position: absolute;
  left: 12px;
  top: -9px;
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent white transparent;
}
.insight .widget-options.animate-if.ng-enter,
.insight .widget-options.animate-if.ng-leave {
  overflow: hidden;
}
.insight .widget-options.animate-if.ng-enter,
.insight .widget-options.animate-if.ng-leave,
.insight .widget-options.animate-if.ng-enter .scroll-container,
.insight .widget-options.animate-if.ng-leave .scroll-container {
  -webkit-transition: all linear 0.1s;
  transition: all linear 0.1s;
  z-index: 0;
}
.insight .widget-options.animate-if.ng-enter .scroll-container,
.insight .widget-options.animate-if.ng-leave.ng-leave-active .scroll-container {
  opacity: 0;
  top: -336px;
}
.insight .widget-options.animate-if.ng-leave .scroll-container,
.insight .widget-options.animate-if.ng-enter.ng-enter-active .scroll-container {
  top: 0;
  opacity: 1;
}
.insight .widget-options .scroll-container {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  overflow-y: scroll;
  height: 345px;
  margin-top: 2px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  background: #fcfcfc;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.insight .widget-options .scroll-container .btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100;
}
.insight .widget-members {
  position: relative;
  z-index: 0;
  height: 370px;
  top: 64px;
}
.insight .widget-members.focus .scroll-container > div.selected {
  background: rgba(0, 0, 0, 0.35);
  background: none;
}
.insight .widget-members .insight-option-row > div:first-child {
  padding-left: 16px;
}
.insight .widget-members .scroll-container {
  position: absolute;
  top: 16px;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  height: 365px;
}
.insight .widget-members .scroll-container .no-items {
  padding: 16px;
  color: #898989;
}
.insight .status-message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  text-align: center;
}
.insight .status-message div {
  line-height: 280px;
  color: #898989;
}
.insight .status-message.animate-show.ng-hide-add,
.insight .status-message.animate-show.ng-hide-remove {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
  display: block !important;
}
.insight .status-message.animate-show.ng-hide-add.ng-hide-add-active,
.insight .status-message.animate-show.ng-hide-remove {
  opacity: 0;
  top: -350px;
}
.insight .status-message.animate-show.ng-hide-add,
.insight .status-message.animate-show.ng-hide-remove.ng-hide-remove-active {
  opacity: 1;
  top: 0;
}
.message {
  background: #d7f2ff;
  border-radius: 0 0 9px 12px;
  border-top: 1px solid #bcbcbc;
  bottom: 0;
  padding: 0.5em;
  position: absolute;
  width: 100%;
}
.message-content {
  font-style: italic;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
}
.text-ellipsis {
  text-overflow: hidden;
  overflow: hidden;
}
