@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

.webcastRecordingBtn {
	composes: webcastNavBtn from '../WebcastNav.module.less';

	:global(.record) {
		color: @warningColor;
	}
}

.webcastRecordingBtnActive {
	composes: webcastRecordingBtn;

	color: @white !important;
	background: @warningColor !important;

	:global(.glyphicons) {
		color: @white;
	}
}

.deviceUnavailableTooltipHost {
	.AbsoluteFill;
}
