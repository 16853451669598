@import (reference) '~rev-less/global/variables';

.grid {
	min-height: 350px;
}

.searchWrapper {
	padding: 10px;
	margin-right: 48px;
	padding: 10px 0 10px 10px;
	display: flex;
	justify-content: flex-end;
}

.search {
	flex: 0 0 20%;

	@media screen and (max-width: @viewPortLargeWidth - 1px) {
		flex: 0 0 30%;
	}

	@media screen and (max-width: @viewPortSmallWidth - 1px) {
		flex: 1 1 100%;
	}
}