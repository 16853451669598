.root {
  min-height: 800px;
  height: 100vh;
}
.eventEnded {
  font-size: 1.5rem;
}
.kpiContainer {
  padding: 1rem 1rem 0;
}
.tabPanel {
  padding: 0 1rem 1rem;
}
