.collapsableSection {
  display: block;
  margin-bottom: 10px;
  background-color: #909090;
  border: 1px solid #909090;
  color: #898989;
  border-radius: 3px;
}
.collapsableSection .collapsableSectionWrap.collapsed {
  overflow: hidden;
  border-radius: 3px;
}
.collapsableSection .invalidIcon {
  display: none;
}
.collapsableSection:global(.ng-dirty.ng-invalid) .header {
  background-color: #c41616;
  color: #fff;
}
.collapsableSection:global(.ng-dirty.ng-invalid) .header .invalidIcon {
  display: inline;
  padding-left: 0.25rem;
}
.contentWrap {
  border-top: 1px solid #909090;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
}
.content {
  padding: 10px 10px;
}
.btnCircle {
  composes: vbBtnTransparent from '~rev-shared/ui/buttons/vb-btn.module.less';
  height: 38px;
}
.header {
  padding: 0 10px;
  min-height: 38px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  border-radius: 3px 3px 0 0;
}
.title {
  display: inline;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 2px 0;
}
