@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

[vb-toggle-button] {
	cursor: pointer;
	position: relative;

	&:hover:before {
		.AbsoluteFill;
		background: @blackFade50;
		content: '';
	}

	&:not(.active):hover {
		color: @white;
	}

	> [ng-transclude] {
		position: relative;
	}
}