.root {
  background: #000;
  color: #fff;
  display: block;
  height: 100%;
}
.msTeamsConnecting {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 220px;
}
