.listWrap {
  margin-bottom: 5px;
  min-height: 70px;
  padding-left: 10px;
}
.listItem {
  composes: theme-accent-link from global;
  border-bottom: 1px solid #d6d6d6;
  list-style-position: inside;
  padding: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.listItemText {
  font-size: 0.8125rem;
}
.listItemText,
.listItemText:hover {
  color: rgba(34, 41, 48, 0.95);
}
.listItemText:hover {
  border-bottom: 1px solid rgba(34, 41, 48, 0.95);
  text-decoration: none;
}
