@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

featured-video-thumbnail {
	display: block;
	height: 100%;
	position: relative;

	.thumbnail-bg {
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;

		.thumbnail-gradient {
			height: 100%;
		}
	}

	.thumbnail-content {
		position: absolute;
		width: 100%;
		color: @white;

		.btn {
			font-size: 1.5em;
			padding: 13px 25px;
			font-weight: 600;
			text-transform: uppercase;

			.circle {
				width: 1.5em;
				height: 1.5em;
				margin-left: 10px;

				border-width: 1px;
				border-style: solid;

				.glyphicons {
					top: 4px;
					color: inherit;
				}
			}
		}
	}

	h1 {
		margin-top: 0;

		.overflow-container {
			line-height: 1em;
			max-height: 2em;
			overflow: hidden;
			word-wrap: break-word;
			font-size: 2em;

			@media(max-width: (@viewPortMedWidth - 1px)) {
				max-height: 1em;
				font-size: 1em;
			}
		}
	}

	&[align-title-left]{
		.thumbnail-bg .thumbnail-gradient {
			.HorizontalGradient(@black, @blackFade00);
		}

		.thumbnail-content {
			@leftMargin: 80px;
			top: 50%;
			left: @leftMargin;
			.Transform(translateY(-50%));

			@media(min-width: @viewPortMedWidth) {
				width: ~'calc(50% - @{leftMargin})';
			}
		}
	}

	&[align-title-bottom]{

		.btn {
			font-size: 0.9em;
			padding: 8px 13px;
			.circle .glyphicons {
				top: 2px;
			}
		}


		.overflow-container {
			margin: 0 20px;
			font-size: 0.9em;
		}

		.thumbnail-bg .thumbnail-gradient {
			.VerticalGradient(@blackFade00, @black);
		}

		.thumbnail-content {
			bottom: 40px;
			text-align: center;
			.Transform(none);
		}
	}
}
