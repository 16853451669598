share-link label {
  color: #898989;
}
share-link textarea {
  display: block;width: 100%;resize: none;cursor: default;border: 1px solid #d8d8d8;border-bottom: none;border-radius: 4px 4px 0 0;padding: 0.5rem;
}
share-link textarea[readonly] {
  cursor: default;border: 1px solid #d8d8d8;background: #f8f8f8;
}
share-link .textarea-bottom-controls {
  border: 1px solid #d8d8d8;border-top: none;border-radius: 0 0 4px 4px;padding: 8px;background: #fff;
}
share-link input[type="text"] {
  display: inline-block;
}
share-link input[type="text"].ng-invalid {
  border-color: #c41616 !important;
}
share-link .video-share-link-start-at {
  width: 60px;
}
share-link .video-share-link-start-at.form-control[disabled] {
  background: none;
  border: none;
  box-shadow: none;
}
share-link .video-share-start-at-invalid {
  color: #c41616;
  margin: 16px;
}
share-link .btn.spark-button {
  padding: 16px 20px;
}
share-link .btn.spark-button .spark-icon {
  left: 25%;
  top: 6px;
}
