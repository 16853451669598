vb-media-table,
vb-media-table-column,
vb-media-table-column-header,
vb-media-table-select-column-header {
  display: block;
}
vb-media-table .table-cell,
vb-media-table-column .table-cell,
vb-media-table-column-header .table-cell,
vb-media-table-select-column-header .table-cell {
  display: block;
}
vb-media-table[flex="none"],
vb-media-table-column[flex="none"],
vb-media-table-column-header[flex="none"],
vb-media-table-select-column-header[flex="none"] {
  -webkit-flex: none !important;
  flex: none !important;
}
vb-media-table speech-search-results-list,
vb-media-table-column speech-search-results-list,
vb-media-table-column-header speech-search-results-list,
vb-media-table-select-column-header speech-search-results-list {
  order: 500;
}
vb-media-table.stop-3d-transform,
vb-media-table-column.stop-3d-transform,
vb-media-table-column-header.stop-3d-transform,
vb-media-table-select-column-header.stop-3d-transform {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
vb-media-table.file-list-wrap {
  overflow: visible !important;
}
vb-media-table-column .video-status-public-password {
  background: rgba(48, 48, 48, 0.75);
  color: #fff;
  font-size: 1.375;
  line-height: 1;
  padding: 0.25rem;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.5rem;
}
vb-media-table-column video-360-indicator {
  display: inherit;
  padding: 0 0.5rem;
}
vb-media-table-column unlisted-video-indicator {
  position: relative;
  top: 4px;
  left: 10px;
  display: inherit;
}
