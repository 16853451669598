@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.admin-multiselect-compact {
	border-spacing: 1rem 0;

	.all-elements,
	.assigned-elements {
		background: @blackFade10;
		.RoundedShape(4px);
		padding: 0 8px;
		width: 50%;
	}

	.assigned-elements {
		width: 50%;

		&:after {
			content: none;
		}

		h4:after {
			line-height: 0 !important;
		}
	}

	.available-items,
	.selected-items {
		margin: 0;
		padding: 0;
	}

	.table-cell-spacer {
		width: 15px;
	}

	h4 {
		font-size: 13px;
		margin-top: 0;
		text-transform: uppercase;
		font-weight: bold;
		color: #7d7d7d;
		border-bottom: 1px solid @blackFade10;
		padding-bottom: 8px;
	}

	.admin-movable-item .hover-wrap {
		.RoundedShape(3px);
		.BoxShadow(0 1px 0 fade(@black, 20%), 0 0 2px @blackFade10;);
	}

	.btn-movable-selector {
		padding: 3px 5px;
	}

	.panel-sub-heading {
		padding: 8px 0 0;
		background: none;

		.table-cell-fill {
			display: block;
		}
	}

	@media (max-width: 991px) {
		&.table-display {
			display: block;
		}

		.assigned-elements,
		.all-elements {
			display: block;
			width: 100%;
		}

		.assigned-elements {
			margin-bottom: 8px;
		}
	}
}
