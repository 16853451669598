.modal-body,
.reports-container {
  -webkit-animation: fadein 1s linear forwards;animation: fadein 1s linear forwards;
}
/* refactor to use this as the base class for all analytics */
.graphs-box-container {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 2em;
}
.graphs-box-container .main {
  overflow: hidden;
  position: relative;
}
@media all and (max-width: 991px) {
  .graphs-box-container {
    display: inline;
  }
}
.graphs-box-container .graph-trends rect[height="1"],
.graphs-box-container .poll-trends rect[height="1"] {
  display: block !important;
  fill: #c2c9cf;
  stroke: #c2c9cf;
  stroke-width: 1;
}
.graphs-box-container .graph-trends rect[height="1"]:first-child,
.graphs-box-container .poll-trends rect[height="1"]:first-child,
.graphs-box-container .graph-trends rect[height="1"]:last-child,
.graphs-box-container .poll-trends rect[height="1"]:last-child {
  display: none !important;
}
.graphs-box-container .graph-trends {
  background: #fff;
}
@media all and (max-width: 991px) {
  .graphs-box-container .graph-trends {
    margin-bottom: 1rem;
  }
}
.graphs-box-container .poll-trends {
  display: block;
}
.graphs-box-container .poll {
  padding: 0 1%;
}
.graphs-box-container .poll-title {
  padding: 0 0.25rem;
}
.graphs-box-container .poll-container {
  border: 1px solid #c2c9cf;
  border-radius: 5px;
  display: inline-block;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  margin: 0.5%;
  width: calc(100% * (1/2) - 1%);
  max-width: calc(100% * (1/2) - 1%);
}
@media all and (max-width: 991px) {
  .graphs-box-container .poll-container {
    width: calc(100% - 1%);
    max-width: calc(100% - 1%);
  }
}
.graphs-box-container .poll-container header {
  min-height: 4.25rem;
}
.graphs-box-container .poll-container footer {
  min-height: 4rem;
}
.graphs-box-container .poll-container header,
.graphs-box-container .poll-container footer {
  background: #00a0f0;
}
.graphs-box-container .poll-container header h4,
.graphs-box-container .poll-container footer h4 {
  color: #fff;
  font-size: 0.875rem;
}
.graphs-box-container .poll-container .results-container {
  padding: 1em;
}
.graphs-box-container .poll-container .results-container .poll-responses {
  border-bottom: 1px solid #c2c9cf;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.75em;
}
.graphs-box-container .poll-container .results-container .poll-responses p {
  font-size: 0.9em;
  width: 95%;
}
.graphs-box-container .poll-container .footer-cell {
  -webkit-flex: 0 1 50%;
  flex: 0 1 50%;
  padding: 1em;
  text-align: center;
}
.graphs-box-container .poll-container .footer-cell small {
  color: #fff;
  display: block;
  margin-bottom: 0.25em;
}
.graphs-box-container .poll-container .footer-cell .footer-text-large {
  color: #fff;
  font-size: 1.75em;
}
.graphs-box-container .graph-trends,
.graphs-box-container .poll-trends {
  border: 1px solid #c2c9cf;
}
.graphs-box-container .graph-trends-inner,
.graphs-box-container .poll-trends-inner {
  height: 365px;
}
.graphs-box-container .graph-trends-inner .nvd3 .nv-multibarHorizontal .nv-groups rect,
.graphs-box-container .poll-trends-inner .nvd3 .nv-multibarHorizontal .nv-groups rect {
  fill-opacity: 0.2;
  /* can't get reports.less to capture this rule */
}
.graphs-box-container .graph-trends {
  display: inline-flex;
}
.graphs-box-container .graph-trends footer {
  margin-top: auto;
}
.graphs-box-container .graph-trends .glyphicons,
.graphs-box-container .poll-trends .glyphicons {
  bottom: 0;
}
.graphs-box-container vb-data-indicator,
.graphs-box-container .graph-trends,
.graphs-box-container .poll-trends {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0;
  flex-basis: 0;
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  position: relative;
}
.graphs-box-container vb-data-indicator:not(:last-child),
.graphs-box-container .graph-trends:not(:last-child),
.graphs-box-container .poll-trends:not(:last-child) {
  margin-right: 20px;
}
@media all and (max-width: 991px) {
  .graphs-box-container vb-data-indicator,
  .graphs-box-container .graph-trends,
  .graphs-box-container .poll-trends {
    display: block;
    margin-bottom: 2em;
    width: 100%;
  }
}
.graphs-box-container .graph-trends footer,
.graphs-box-container .poll-trends footer {
  padding: 0.25em;
}
.graphs-box-container .graph-trends header,
.graphs-box-container .poll-trends header {
  padding: 0;
  border-top-style: solid;
  border-top-width: 3px;
}
.graphs-box-container .graph-trends .main,
.graphs-box-container .poll-trends .main {
  max-width: 100%;
  width: 100%;
}
.graphs-box-container .graph-trends .main .caption,
.graphs-box-container .poll-trends .main .caption {
  color: #fff;
  display: inline-flex;
  font-size: 0.875rem;
  margin: 0.5rem 0;
  padding: 0 0 0 1rem;
}
.graphs-box-container .graph-trends .main > .count-large,
.graphs-box-container .poll-trends .main > .count-large {
  color: #fff;
  font-size: 3.25rem;
  font-weight: normal;
  float: right;
  line-height: 1.25;
  margin-right: 10px;
}
.graphs-box-container .graph-trends .main > .count-large.middle,
.graphs-box-container .poll-trends .main > .count-large.middle {
  float: none;
  margin-right: 0;
  margin-top: 0.75rem;
  text-align: center;
}
.graphs-box-container .graph-trends .main .glyphicons,
.graphs-box-container .poll-trends .main .glyphicons {
  color: #fff;
  font-size: 4.5rem;
  line-height: 1;
  margin-left: 10px;
  opacity: 0.2;
  position: absolute;
  left: 5px;
  top: auto;
}
.graphs-box-container .graph-trends .main .facetime_video,
.graphs-box-container .poll-trends .main .facetime_video {
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}
.graphs-box-container .graph-trends footer,
.graphs-box-container .poll-trends footer {
  border-radius: 0 0 5px 5px;
  border-top: 1px solid #fff;
  display: inline-flex;
  min-height: 50px;
  overflow: hidden;
  width: 100%;
}
.graphs-box-container .graph-trends footer .left,
.graphs-box-container .poll-trends footer .left {
  border-right: 1px solid #fff;
}
.graphs-box-container .graph-trends footer .left,
.graphs-box-container .poll-trends footer .left,
.graphs-box-container .graph-trends footer .right,
.graphs-box-container .poll-trends footer .right {
  padding-bottom: 1em;
  text-align: center;
  width: 50%;
}
.graphs-box-container .graph-trends footer .left .title,
.graphs-box-container .poll-trends footer .left .title,
.graphs-box-container .graph-trends footer .right .title,
.graphs-box-container .poll-trends footer .right .title {
  color: #fff;
  font-size: 0.875rem;
}
.graphs-box-container .graph-trends footer .left .count,
.graphs-box-container .poll-trends footer .left .count,
.graphs-box-container .graph-trends footer .right .count,
.graphs-box-container .poll-trends footer .right .count {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.graphs-box-container header > .title,
.graphs-box-container footer > .title {
  margin: 0.5rem 0;
  padding-left: 0.75em;
}
.graphs-box-container header > .title-small,
.graphs-box-container footer > .title-small {
  margin: 0.5rem;
}
.graphs-box-container .line-top-none {
  border-top: none !important;
}
.graphs-box-container .flex-column {
  -webkit-flex-direction: column;
  flex-direction: column;
}
.graphs-box-container .flex-row {
  -webkit-flex-direction: row;
  flex-direction: row;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.graphs-box-container .title {
  font-size: 0.875rem;
}
.graphs-box-container .title-small {
  font-size: 0.875rem;
}
.graphs-box-container .title-light {
  color: #fff;
}
.graphs-box-container .title-dark {
  color: rgba(0, 0, 0, 0.75);
}
.graphs-box-container .progress-wrapper .progress {
  background: #fff;
  border: 2px solid #fff;
  opacity: 0.4;
}
[ui-view="reports"] .uib-tab {
  margin-right: 0.5rem;
}
.graph-tooltip-lineplus {
  padding: 5px;
  color: rgba(255, 255, 255, 0.75);
}
.graph-tooltip-lineplus-xaxis {
  border-bottom: 1px solid rgba(255, 255, 255, 0.75);
}
.graph-tooltip-lineplus-yaxis {
  float: left;
  padding-left: 10px;
  padding-bottom: 10px;
}
.graph-tooltip-lineplus-yaxis-indicator {
  width: 10px;
  height: 10px;
  border: 1px solid #999999;
  float: left;
  margin-top: 3px;
}
.graph-tooltip-lineplus-yaxis-y1color {
  background-color: #ff9f53;
}
.graph-tooltip-lineplus-yaxis-y2color {
  background-color: #754900;
}
