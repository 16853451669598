vb-webcast-questions-list {
  display: block;
}
vb-webcast-questions-list .webcast-questions-list .card-holder {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
vb-webcast-questions-list .webcast-questions-list .manage-card-holder {
  border-bottom-width: 0;
}
vb-webcast-questions-list .ui-sortable-placeholder {
  height: 100px;
}
vb-webcast-questions-list .noQuestions {
  text-align: center;
  margin-top: 20px;
}
