.dataAsOf {
  font-size: 0.625rem;
  padding: 0.5rem 0;
}
.header {
  font-size: 1.25rem;
}
.pauseBtn {
  background: #009b01;
}
.pauseResumeBtn {
  color: #fff;
  padding: 10px 12px;
}
.pauseResumeBtn:focus,
.pauseResumeBtn:hover {
  color: #fff;
}
.pauseResumeIcon {
  border-radius: 9999px;
  background: #fff;
  padding: 2px;
}
.pauseResumeIcon:before {
  color: #009b01;
}
.resumeBtn {
  background: #ff8015;
}
.resumeBtn .pauseResumeIcon:before {
  color: #ff8015;
}
.search {
  margin-right: 1rem;
}
