.tabWrapper {
  min-height: 62vh;
}
.tabPanel {
  padding: 1rem;
}
.runInfo {
  margin-left: auto;
  font-weight: bold;
  font-size: 1.125rem;
}
.menuDropDownContent {
  min-width: 80px !important;
}
.menuDropdown {
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
}
.menuItem {
  padding: 5px 10px;
}
.menuItem:hover {
  background: #f3f3f3;
}
.menuItemLink {
  color: #898989 !important;
  display: block;
  text-decoration: none;
  white-space: nowrap;
  text-align: left;
}
.menuItemLink:hover {
  text-decoration: none;
}
.userEngagedIcon:global(.vb-icon-user-engaged):before {
  font-size: 1rem;
}
@media screen and (max-width: 991px) {
  .runtime {
    display: block;
    padding-top: 3px;
  }
  .runInfo {
    font-size: 0.875rem;
  }
}
.mediumChartWrapper {
  -webkit-flex: 1 1 45%;
  flex: 1 1 45%;
}
@media screen and (max-width: 991px) {
  .mediumChartWrapper {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
  .mediumChartWrapper:not(:last-child) {
    margin-right: 0 !important;
  }
}
.mediumChartSize {
  width: 100%;
  height: 320px;
}
