.webcast-manage-questions-full-screen .question-list-layout {
  display: -webkit-flex;display: flex;
}
.questionsFullscreenHost {
  padding: 0;
}
.webcast-manage-questions-full-screen {
  height: 100vh;
}
.webcast-manage-questions-full-screen > header {
  padding: 0 16px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.webcast-manage-questions-full-screen > header h1 {
  display: inline-block;
  font-size: 1.313rem;
  line-height: 48px;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.webcast-manage-questions-full-screen > header .header-section {
  padding: 4px 10px 4px 0;
}
.webcast-manage-questions-full-screen > header form {
  position: relative;
  margin-right: 19px;
}
.webcast-manage-questions-full-screen > header form .qtext-filter {
  width: 300px;
}
.webcast-manage-questions-full-screen > header form .qtext-filter-reset {
  position: absolute;
  top: -2px;
  right: -24px;
  background-color: transparent;
  border: 0;
  line-height: 2.5em;
  height: 2em;
}
.webcast-manage-questions-full-screen > header .btn {
  height: 32px;
  line-height: 32px;
  padding: 0 8px;
}
.webcast-manage-questions-full-screen .question-list-layout > section {
  height: 100%;
  position: relative;
  -webkit-flex: 1 0;
  flex: 1 0;
  border-right-width: 1px;
  border-right-style: solid;
  padding-top: 40px;
}
.webcast-manage-questions-full-screen .question-list-layout > section:last-child {
  margin-right: 0;
  border-right: 0;
}
.webcast-manage-questions-full-screen .question-list-layout > section > header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.webcast-manage-questions-full-screen .question-list-layout > section > header h1 {
  font-size: 1.125rem;
  line-height: 40px;
  text-transform: uppercase;
  margin: 0;
}
.webcast-manage-questions-full-screen .question-list-layout > section > header .btn-hide-question-list {
  background: transparent;
  border: 0;
  padding: 8px;
}
.webcast-manage-questions-full-screen .question-list-layout > section > header .btn:not(.btn-primary) {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.webcast-manage-questions-full-screen .question-list-layout > section question-list-item .dropdown-toggle {
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.5);
}
.webcast-manage-questions-full-screen .question-list-layout > section.inbox-view vb-webcast-questions-list,
.webcast-manage-questions-full-screen .question-list-layout > section.queue-view vb-webcast-questions-list,
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view vb-webcast-questions-list {
  padding: 0 8px;
  margin-top: 0;
}
.webcast-manage-questions-full-screen .question-list-layout > section.inbox-view vb-webcast-questions-list .webcast-questions-list li,
.webcast-manage-questions-full-screen .question-list-layout > section.queue-view vb-webcast-questions-list .webcast-questions-list li,
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view vb-webcast-questions-list .webcast-questions-list li {
  border-bottom: none;
}
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0;
}
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item > div {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item .question-status-detail {
  top: 8px;
}
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item .question-number,
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item .question-qtext,
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item .question-from {
  color: #fff;
}
.webcast-manage-questions-full-screen .question-list-layout > section.closed-view question-list-item .dropdown-toggle .circle {
  background-color: #fff;
}
.webcast-manage-questions-full-screen .question-list-layout > section .noQuestions {
  text-align: center;
  padding-top: 50%;
  font-size: 1.125rem;
}
.webcast-manage-questions-full-screen .question-list-layout .can-manage question-list-item {
  padding: 0 8px;
  border-radius: 4px;
  background-color: #ebebeb;
}
@media (max-width: 992px) {
  .webcast-manage-questions-full-screen .question-list-layout {
    display: block;
  }
  .webcast-manage-questions-full-screen .question-list-layout > section {
    height: auto;
    min-height: 100px;
  }
  .webcast-manage-questions-full-screen .question-list-layout > section .no-asks {
    padding: 40px 0;
  }
}
