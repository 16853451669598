.show-pointer {
  cursor: pointer;
}
.available-items .repeater-container,
.selected-items .repeater-container {
  height: 100%;
  overflow-y: auto;
  padding-right: 4px;
}
.available-items .vs-repeat-repeated-element,
.selected-items .vs-repeat-repeated-element {
  left: 15px;
  right: 15px;
}
