featured-video-thumbnail-carousel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
}
featured-video-thumbnail-carousel vb-flickity-carousel,
featured-video-thumbnail-carousel .flickity-carousel-container {
  height: 100%;
}
featured-video-thumbnail-carousel vb-flickity-carousel .dot,
featured-video-thumbnail-carousel .flickity-carousel-container .dot {
  border-width: 1px;
  border-style: solid;
}
featured-video-thumbnail-carousel .carousel-cell {
  height: 100%;
  width: 100%;
  padding: 0;
}
featured-video-thumbnail-carousel .flickity-page-dots {
  bottom: 50px;
}
