.vbUiInsight {
  border-radius: 3px;
  border-width: 3px;
  border-color: #fff;
  border-style: solid;
}
:global(.ng-invalid):not(:global(.ng-pristine)) > .vbUiInsight:not(:global(.theme-accent-border)) {
  border: 3px solid #c41616;
}
.vbUiInsight :global(.insight) {
  background-color: #fff;
}
.vbUiInsight :global(.insight .search-field-wrapper .search-field) {
  border: 1px solid #cbcbcb;
  background-color: #fff;
}
.vbUiInsight :global(.insight .search-field-wrapper .search-field) input {
  color: #141414;
}
.vbUiInsight :global(.insight .search-field-wrapper .search-field) input:placeholder {
  color: #898989;
}
.vbUiInsight :global(.insight .search-field-wrapper .icon) {
  padding: 3px;
  border-radius: 4px;
  top: 9px;
}
.vbUiInsight :global(.insight-option-row) {
  background-color: #fff;
  color: #141414;
  border-bottom: 1px solid rgba(48, 48, 48, 0.1);
}
.vbUiInsight :global(.insight-option-row):hover {
  background: rgba(0, 0, 0, 0.05);
}
.vbUiInsight :global(.insight-option-row) :global(.circle) {
  margin-top: 0;
}
.vbUiInsight :global(.insight-option-row):global(.selected) :global(.btn-edit-access),
.vbUiInsight :global(.insight-option-row):global(.selected) :global(.btn-edit-access .ok_2) {
  color: #ebebeb !important;
  background: #303030 !important;
}
.vbUiInsight :global(.insight-option-row) :global(.btn-edit-access) {
  border: 1px solid #909090;
}
.vbUiInsight :global(.insight-option-row) :global(.access-assignment-popup) {
  border: 1px solid #909090;
  position: absolute;
  top: 2.8em;
  right: 2em;
  left: -7em;
  background-color: #b0b0b0;
  z-index: 100;
  border-radius: 5px;
  font-size: 0.875rem;
}
.vbUiInsight :global(.insight-option-row) :global(.access-assignment-popup):hover {
  font-size: 0.875rem !important;
}
.vbUiInsight :global(.insight-option-row) :global(.access-assignment-popup):before {
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #b0b0b0 transparent;
  right: 10%;
  margin-left: -14px;
  top: -8px;
  z-index: 200;
}
.vbUiInsight :global(.insight-option-row) :global(.access-assignment-popup) :global(.access-wrapper) {
  list-style: none;
  padding: 10px;
  margin-bottom: 0 !important;
}
.vbUiInsight :global(.insight-option-row) :global(.access-assignment-popup) :global(.access-wrapper) :global(.access-item) {
  border-radius: 5px;
  background-color: #fff;
  color: #b0b0b0;
  line-height: 1.1;
  padding: 5px;
  margin-bottom: 10px;
}
.vbUiInsight :global(.insight-option-row) :global(.access-assignment-popup) :global(.access-wrapper) :global(.access-item):last-child {
  margin-bottom: 0;
}
.vbUiInsight :global(.insight-option-row.assigned:not(.selected)):hover {
  font-size: 1rem;
}
.vbUiInsight :global(.insight-option-row.assigned:not(.selected)) :global(.circle) {
  background: #cbcbcb;
  color: rgba(48, 48, 48, 0.5);
}
.vbUiInsight :global(.insight-option-row.selected):hover {
  font-size: 1rem;
}
