@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@headerHeight: 38px;
@horizontalPadding: 10px;

.collapsableSection {
	display: block;
	margin-bottom: 10px;
	background-color: @lightBorder;
	border: 1px solid @lightBorder;
	color: @color1LightText;
	.RoundedShape();

	.collapsableSectionWrap.collapsed {
		overflow: hidden;
		.RoundedShape();
	}

	.invalidIcon {
		display: none;
	}

	&:global(.ng-dirty.ng-invalid) {
		.header {
			background-color: @warningColor;
			color: @white;

			.invalidIcon {
				display: inline;
				padding-left: .25rem;
			}
		}
	}
}

.contentWrap {
	border-top: 1px solid @lightBorder;
	background-color: @white;
	.RoundedBottomShape();

}

.content {
	padding: 10px @horizontalPadding;
}

.btnCircle{
	composes: vbBtnTransparent from '~rev-shared/ui/buttons/vb-btn.module.less';
	height: @headerHeight;
}

.header {
	padding: 0 @horizontalPadding;
	min-height: @headerHeight;
	cursor: pointer;
	font-size: @type16;
	font-weight: 700;
	line-height: @type16;
	.RoundedTopShape();
}

.title {
	display: inline;
	font-size: inherit;
	line-height: inherit;
	margin: 0;
	padding: 2px 0;
}
