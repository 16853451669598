@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.webcastBackground {
	&.fixed {
		.FixedFill;
	}

	&:before {
		.AbsoluteFill;
		content: ' ';
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
		.Brightness(0.8);
	}


	&.webcastBackgroundFill {
		&:before {
			background-size: cover;
		}
	}
}
