.bulk-edit .help-block {
  color: #fff;
}
.bulk-edit video-expiration .col-lg-9 {
  width: 100%;
}
.bulk-edit tags-input {
  color: #000;
}
.bulk-edit-sidebar .control-label {
  color: #fff;
}
@media all and (min-width: 992px) {
  .bulk-edit-sidebar .bulk-edit-settings {
    border-top: 1px solid #fff;
    margin-top: 0.5rem;
  }
  .bulk-edit-sidebar .bulk-edit-cancel {
    border-bottom: 1px solid #fff;
  }
}
