.webex-import .input-fields {
  padding: 32px 32px 16px !important;
}
.webex-import .username,
.webex-import .password {
  position: relative;
}
.webex-import .glyphicons.user,
.webex-import .glyphicons.lock,
.webex-import .glyphicon.glyphicon-lock {
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: 16px;
  color: #898989;
}
.webex-import input.input-with-icon {
  padding: 0 15px 0 40px;
  font-size: 1rem;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  color: #303030;
  margin-bottom: 16px;
}
.webex-import input.form-control:focus {
  background: #fff;
}
.webex-import input.ng-dirty.ng-invalid {
  border-color: #b2b2b2 !important;
}
@media (min-width: 1200px) {
  .webex-import .modal-lg {
    width: 80%;
  }
}
.webex-import .modal-body {
  min-height: 158px;
  padding: 0;
}
.webex-import .modal-footer {
  margin-top: 0;
}
.webex-import .system-msg {
  font-size: 1rem;
  margin-bottom: 0;
  line-height: 1.313rem;
  padding: 16px;
}
.webex-import .system-msg .glyphicons {
  margin-right: 5px;
  top: 2px;
}
.import-webex-grid-list > ul {
  height: 60vh;
}
.import-webex-grid-list > ul > li .grid-list-cell {
  font-size: 0.75rem;
}
.import-webex-grid-list > ul > li .grid-list-cell:nth-child(2) {
  font-weight: bold;
  font-size: 0.875rem;
}
