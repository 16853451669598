@import  (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.presentationViewport {
	composes: presentation-viewport from global;

	position: relative;

	@media(min-width: 992px) {
		overflow: hidden;
		position: relative;
	}

	@media(min-width: 1200px) {
		:global(.webcast-toolbar) {
			div:first-child {
				padding-left: 16px !important;
			}
		}
	}

	:global(.vertical-layout) & {
		border-top-width: 1px;
		border-top-style: solid;

		@media(min-width: 1200px) {
			:global(.webcast-toolbar) {
				div:first-child {
					padding-left: 32px !important;
				}
			}
		}
	}

	@media(min-width: 992px) {
		:global(.no-video) & {
			.flexItem(1 1 100%);
		}
	}
}
