notifications-menu-content {
  display: block;
}
notifications-menu-content .scrollable-area {
  height: 300px;
}
notifications-menu-content .menu-footer {
  font-size: 13px;
  position: relative;
}
notifications-menu-content .menu-footer a:hover {
  color: initial;
  text-decoration: underline;
}
notifications-menu-content .no-notifications-msg {
  margin: 15px;
  height: calc(100% - 30px);
}
