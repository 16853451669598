.root {
  padding: 1rem;
}
.logo {
  margin-right: 1rem;
}
.realTimeDashboard {
  border-style: solid;
  border-width: 1px;
  line-height: 1.25;
  padding: 0.25rem 0.5rem;
  text-transform: uppercase;
}
.webcastSchedule {
  font-size: 0.75rem;
}
.webcastTitle {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5;
}
.webcastTitleScheduleContainer {
  flex-shrink: 1;
  margin: 0 1rem;
  overflow: hidden;
}
