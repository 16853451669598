@import (reference) "~rev-less/global/variables";

playlist-sharing {
	display: block;
	padding: 60px 1rem;

	h4 {
		display: inline;
	}

	share-link,
	share-embed {
		color: @black;

		label,
		.btn-white {
			color: @black;
		}

		.textarea-bottom-controls {
			background-color: @backgroundFade50;
		}
	}

	share-embed {
		.embed-width-cell {
			width: 50%;
		}
	}
}