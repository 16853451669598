video-playlist-carousel {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #d8d8d8;
  box-sizing: content-box;
  display: block;
  height: 48px;
}
video-playlist-carousel > .carousel-container {
  padding: 0 50px;
  position: relative;
  width: 100%;
  height: 100%;
}
video-playlist-carousel .carousel-cell {
  color: rgba(255, 255, 255, 0.75);
  padding: 3px 0.5rem;
  border-right: 1px solid #d8d8d8;
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
  display: inline-block;
  width: 200px;
  position: absolute;
  word-wrap: break-word;
}
video-playlist-carousel .carousel-cell:hover {
  background: rgba(0, 160, 240, 0.5);
  color: #fff;
}
video-playlist-carousel .carousel-cell.selected {
  background: #00a0f0;
  border-color: #00a0f0;
  color: #fff;
}
video-playlist-carousel .carousel-cell.selected img {
  border: 1px solid #fff;
}
video-playlist-carousel img {
  width: 65px;
  height: 40px;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  float: left;
}
video-playlist-carousel .btn-carousel {
  width: 50px;
  color: #fff;
  background-color: #666666;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}
video-playlist-carousel .btn-carousel[disabled] {
  opacity: 0.25;
}
video-playlist-carousel .btn-carousel-prev {
  left: 0;
}
video-playlist-carousel .btn-carousel-next {
  right: 0;
}
