@import "~rev-less/global/variables";

.rev-create-download-dialog {
	.modal-body {
		position: relative;
		padding-left: 80px;

		&.upload-media .upload-import-button {
			margin: 0;
			position: absolute;
			left: @typeUnit1x;

			.upload-import-button-logo {
				margin: 0;
			}
		}

		.rev-create-download-dialog-body-text {
			h2 {
				margin-top: 0;
				text-transform: uppercase;
			}

			.not-supported& {
				color: @blackFade50;
			}
		}
	}
}