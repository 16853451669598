@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.questionsFullscreenHost {
	padding: 0;
}

.webcast-manage-questions-full-screen { //full screen view overrides
	height: 100vh;

	> header {
		padding: 0 @typeUnit1x;
		border-bottom-width: 1px;
		border-bottom-style: solid;

		h1 {
			display: inline-block;
			font-size: @type21;
			line-height: @largeRow;
			margin: 0;
			.TextClip();
		}

		.header-section {
			padding: 4px 10px 4px 0;
		}

		form {
			position: relative;
			margin-right: 19px;

			.qtext-filter {
				width: 300px;
			}

			.qtext-filter-reset {
				position: absolute;
				top: -2px;
				right: -24px;
				background-color: transparent;
				border: 0;
				line-height: 2.5em;
				height: 2em;
			}
		}

		.btn {
			height: 32px;
			line-height: 32px;
			padding: 0 @typeUnit1-2x;
		}
	}

	@question-list-layout-header-height: @mediumRow;
	.question-list-layout {
		&:extend(.flexDisplay);

		> section {
			height: 100%;
			position: relative;
			.flex(1 0);
			border-right-width: 1px;
			border-right-style: solid;
			padding-top: @question-list-layout-header-height;

			&:last-child {
				margin-right: 0;
				border-right: 0;
			}

			> header {
				position: absolute;
				top: 0;
				width: 100%;
				height: @question-list-layout-header-height;
				border-bottom-width: 1px;
				border-bottom-style: solid;

				h1 {
					font-size: @type18;
					line-height: @mediumRow;
					text-transform: uppercase;
					margin: 0;
				}

				.btn-hide-question-list {
					background: transparent;
					border: 0;
					padding: @typeUnit1-2x;
				}

				.btn:not(.btn-primary) {
					border: 1px solid @whiteFade10 !important;
				}
			}

			question-list-item {
				.dropdown-toggle {
					border: 1px solid @whiteFade10 !important;
					color: @whiteFade50;
				}
			}

			&.inbox-view,
			&.queue-view,
			&.closed-view {
				vb-webcast-questions-list {
					padding: 0 @typeUnit1-2x;
					margin-top: 0;

					.webcast-questions-list li {
						border-bottom: none;
					}
				}
			}

			&.closed-view {
				question-list-item {
					background-color: transparent;
					border-bottom: 1px solid @whiteFade10;
					.RoundedShape(0);

					> div {
						padding-top: @typeUnit1-4x !important;
						padding-bottom: @typeUnit1-4x !important;
					}

					.question-status-detail {
						top: @typeUnit1-2x;
					}

					.question-number,
					.question-qtext,
					.question-from {
						color: @white;
					}

					.dropdown-toggle .circle {
						background-color: @white;
					}
				}
			}

			.noQuestions {
				text-align: center;
				padding-top: 50%;
				font-size: @type18;
			}
		}

		.can-manage question-list-item {
			padding: 0 @typeUnit1-2x;
			.RoundedShape(4px);
			background-color: @background;
		}

		@media (max-width: @viewPortMedWidth) {
			display: block;
			> section {
				height: auto;
				min-height: 100px;
				.no-asks{
					padding: 40px 0;
				}
			}
		}
	}
}
