@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root {
	display: flex;
}

.openQuestionWrapper {
	cursor: pointer;
}

.input {
	.BorderTopRightRadius(0);
	.BorderBottomRightRadius(0);
	background-color: @white;
	width: 75%;
	display: inline-block;
	flex: 1 !important;
}

.submitBtn {
	.BorderTopLeftRadius(0);
	.BorderBottomLeftRadius(0);
}

.form {
	width: 100%;
	padding: 10px;
	border-width: 2px;
	border-style: solid;
	.TransitionAll(display linear 0.5s);

	.textArea:focus {
    	border: 3px solid @blackFade75;
	}
}

.textArea {
	border: 0;
}

.anonymousChkBox {
	display: inline-block;
	margin-top: 8px;
}

