/*  These classes are confusingly named  */
/*  ASSIGNED and ALL-ELEMENTS are the outer containers */
.all-elements,
.assigned-elements {
  width: 50%;
  position: relative;
}
/*  AVAILABLE-ITEMS and SELECTED-ITEMS are the inner containers */
.available-items,
.selected-items {
  margin: 0;
  height: 250px;
}
.role-select .available-items,
.role-select .selected-items {
  height: 205px;
}
@media (min-width: 992px) {
  .available-items,
  .selected-items {
    height: 350px;
  }
}
@media (min-width: 1400px) {
  .library-panel .available-items,
  .library-panel .selected-items {
    height: 500px;
  }
}
.assigned-elements .form-control-search,
.all-elements .form-control-search,
.all-elements .table-tools .btn,
.assigned-elements .table-tools .btn {
  border-color: rgba(0, 0, 0, 0.2);
}
.admin .admin-multiselect {
  background: #c6c8ca;
}
.admin-multiselect {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.25);
}
.no-padding .admin-multiselect {
  position: relative;
  top: -3px;
  margin-bottom: -6px;
}
.admin-multiselect .table-tools {
  background: rgba(0, 0, 0, 0.05);
  margin-top: 0 !important;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.admin-multiselect .dynamic_ok_or_remove .glyphicons:before {
  content: "\E208";
}
.admin-multiselect .selected-items .dynamic_ok_or_remove .glyphicons:before {
  content: "\E207";
  color: #00b13c;
}
.admin-multiselect .combined-toolbar .active .input-group {
  border-color: #7d7d7d;
  background: #00a0f0;
}
.admin-multiselect .panel-sub-heading {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  padding: 0 15px;
  font-size: 13px;
  text-shadow: none;
  font-weight: normal;
  vertical-align: middle;
  border-radius: 0;
  line-height: 36px;
  background: #bbbdbf !important;
  color: #565656;
}
.admin-multiselect .panel-sub-heading .filter-input {
  padding-top: 2px;
}
.admin-multiselect .panel-sub-heading .combined-toolbar .input-group {
  border: none;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.75);
}
.admin-multiselect .table-tools .btn {
  background: none;
  border-color: rgba(0, 0, 0, 0.1);
}
.admin-multiselect .table-tools .btn:hover {
  background: rgba(0, 0, 0, 0.1);
}
.admin-multiselect h4 {
  font-size: 13px;
  margin-top: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #565656;
  line-height: 36px;
}
.admin .filters {
  background: rgba(0, 0, 0, 0.2);
  margin: -15px -15px 15px -15px;
  padding: 15px 0;
}
.admin .filters:before {
  content: " ";
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  position: absolute;
  top: -8px;
  left: 30px;
}
.admin .admin-multiselect .panel-sub-heading .btn {
  background: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 2px #fff;
  height: 24px;
  padding-top: 0;
  padding-bottom: 0;
}
.admin .admin-multiselect .panel-sub-heading .btn:hover {
  background: rgba(255, 255, 255, 0.5);
}
.admin .admin-multiselect .combined-toolbar .filter-input .input-group .btn {
  padding: 6px 7px 6px 10px;
  border-left: 0;
  box-shadow: none;
}
.admin .admin-multiselect .combined-toolbar .filter-input .input-group .btn .glyphicons {
  font-size: 14px;
  top: -1px;
}
.admin .panel-sub-heading .combined-toolbar .filter-input .input-group {
  width: 150px;
  line-height: 0;
}
.admin .panel-sub-heading .combined-toolbar .filter-input .input-group .input-group-btn {
  line-height: 0;
}
.admin-multiselect {
  border-radius: 4px;
}
.admin-movable-item .hover-wrap.write-access {
  padding-right: 4px;
}
.available-items .write-access {
  display: none;
}
.write-access .btn.btn-white {
  padding: 3px 5px;
  vertical-align: middle;
}
.selected-items .write-access {
  display: inline-block;
  text-transform: uppercase;
  font-size: 9px;
  font-weight: 500;
  color: #7d7d7d;
}
.selected-items .write-access > .btn-admin {
  margin-left: 5px;
}
.panel-body.no-padding .admin-multiselect .table-tools,
.admin-multiselect .table-tools {
  background: none;
  border-bottom: none;
  padding: 8px 15px;
  margin-bottom: 0;
}
.admin-light .panel-sub-heading .combined-toolbar .search-query-field .form-control,
.admin-light .panel-sub-heading .combined-toolbar .search-query-field .btn {
  height: 24px;
}
.admin-light .panel-sub-heading .combined-toolbar .search-query-field .form-control {
  padding-top: 0;
  padding-bottom: 0;
}
.multi-select-error {
  position: relative;
  background: #fff;
  padding: 15px;
}
.multi-select-error .alert {
  text-align: center;
  margin-bottom: 0;
}
/*@media (max-width: 991px){
	.table-display.admin-multiselect {
		display: block;
	}

	.assigned-elements,
	.all-elements {
		display: block;
		width: 100%;
	}
}*/
@media (min-width: 992px) and (max-width: 1200px) {
  .admin-multiselect .combined-toolbar .btn.btn-icon-left .glyphicons {
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .admin .tab-content .table-cell {
    display: inline-block;
    width: 50%;
  }
  .admin .tab-content .table-cell.search-query-field.filter-input {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .table-display.admin-multiselect .table-cell-fill {
    max-width: 300px;
  }
}
@media (max-width: 768px) {
  .assigned-elements,
  .all-elements {
    display: block !important;
    width: 100% !important;
  }
  .available-items,
  .selected-items {
    height: auto;
    padding: 10px 0;
  }
  .available-items .repeater-container,
  .selected-items .repeater-container {
    overflow-y: visible !important;
  }
}
@media (min-width: 768px) {
  .admin-multiselect h4 {
    line-height: 14px;
  }
}
