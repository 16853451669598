speech-search-result {
  display: block;
  margin-bottom: 16px;
  position: relative;
  padding: 0 60px;
  word-wrap: break-word;
}
.file-list-row.inactive speech-search-result a {
  color: #898989;
}
speech-search-result .speech-search-result-time {
  margin-right: 16px;
  position: absolute;
  left: 0;
}
speech-search-result .speech-search-result-excerpt em {
  font-weight: bold;
  font-style: normal;
}
