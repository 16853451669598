playlist-detail .media-layout {
  width: 100%;
}
playlist-detail .table-cell video-360-indicator {
  display: inherit;
  margin: 0 0.25rem;
}
playlist-detail .playlist-nav-row {
  height: 42px;
}
