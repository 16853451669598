@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

legal-hold-sidebar {
	.dropdown-menu {
		border: transparent;
		.BoxShadow(none);
	}
	.legalhold {
		.sidebar-dropdown {
			right: 51px;
			top: 10px;
			&:before, &:after {
				@media all and (max-width: @viewPortMedWidth) {
					margin-top: -9px;
					margin-left: 0;
				}
			}
		}
	}
	.sidebar {
		&-dropdown {
			background-color: @white;
			border: 1px solid @color1;
			border-radius: 4px;
			padding: 10px 35px;
			position: absolute;
			right: 51px;
			top: 12px;
			width: auto;

			@media all and (max-width: @viewPortMedWidth) {
				right: 8px !important;
				top: -54px !important;
			}

			&:after, &:before {
				left: 100%;
				top: 25%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;

				@media all and (max-width: @viewPortMedWidth) {
					left: 80%;
					top: 113%;
				}
			}

			&:before {
				border-left-color: @color1;
				border-width: 13px;
				margin-top: -1px;
				margin-left: 0;

				@media all and (max-width: @viewPortMedWidth) {
					border-left-color: transparent;
					border-top-color: @color1;
					margin-top: -8px;
				}
			}

			&:after {
				border-color: rgba(248, 248, 248, 0);
				border-left-color: @white;
				border-width: 12px;
				margin-top: -1px;
				margin-left: -1px;

				@media all and (max-width: @viewPortMedWidth) {
					border-top-color: @white;
					border-left-color: transparent;
					margin-top: -8px;
					margin-left: 0;
				}

			}
		}

		&-menu {
			top: -12px;
		}
	}

	.menu-link {
		color: @color0 !important;
		font-size: @type14 !important;
		line-height: 1.4 !important;
		padding: 5px !important;
		text-align: left;
	}

	.dropdown-menu {
		background-color: transparent;
	}
}
