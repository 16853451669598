@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.vbUiInsight {
	.RoundedShape(@defaultBorderRadius);
	border-width: 3px;
	border-color: @white;
	border-style: solid;

	:global(.ng-invalid):not(:global(.ng-pristine)) > &:not(:global(.theme-accent-border)) {
		border: @invalidFieldBorder;
	}

	:global(.insight) {
		background-color: @white;
	}
	:global(.insight .search-field-wrapper .search-field) {
		border: 1px solid @borderColor;
		background-color: @white;

		input {
			color: @primaryDark;

			&:placeholder {
				color: @color1LightText;
			}
		}

	}
	:global(.insight .search-field-wrapper .icon) {
		padding: 3px;
		.RoundedShape(4px);
		top: 9px;
	}

	:global(.insight-option-row) {
		background-color: @white;
		color: @primaryDark;
		border-bottom: 1px solid @color1Fade10;

		&:hover {
			background: @blackFade05;
		}

		:global(.circle) {
			margin-top: 0;
		}

		&:global(.selected) {
			:global(.btn-edit-access), :global(.btn-edit-access .ok_2) {
				color: @color2 !important;
				background: @color1 !important;
			}
		}
		:global(.btn-edit-access) {
			border: 1px solid @lightBorder;
		}
		:global(.access-assignment-popup) {
			border: 1px solid @lightBorder;
			position: absolute;
			top: 2.8em;
			right: 2em;
			left: -7em;
			background-color: @color1Lighten50;
			z-index: 100;
			.RoundedShape(5px);
			font-size: @type14;

			&:hover {
				font-size: @type14 !important;
			}

			&:before {
				content: " ";
				position: absolute;
				border-style: solid;
				border-width: 0 8px 8px 8px;
				border-color: transparent transparent #b0b0b0 transparent;
				right: 10%;
				margin-left: -14px;
				top: -8px;
				z-index: 200;
			}

			:global(.access-wrapper) {
				list-style: none;
				padding: 10px;
				margin-bottom: 0 !important;

				:global(.access-item) {
					.RoundedShape(5px);
					background-color: @white;
					color: @color1Lighten50;
					line-height: 1.1;
					padding: 5px;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}

			}
		}
	}

	:global(.insight-option-row.assigned:not(.selected)) {
		&:hover {
			font-size: @type16;
		}
		:global(.circle) {
			background: @borderColor;
			color: @color1Fade50;
		}
	}

	:global(.insight-option-row.selected) {
		&:hover {
			font-size: @type16;
		}
	}
}
