vb-webcast-polls-view h4 {
  font-size: 1rem;
  font-weight: 700;
  margin: 10px 0 10px 0;
  text-align: left;
  padding: 0 16px;
}
vb-webcast-polls-view h4 .btn-toolbar {
  font-size: 0.75rem;
  font-weight: normal;
  margin-top: 4px;
}
vb-webcast-polls-view .host-view .poll-responses label {
  display: block;
}
vb-webcast-polls-view.result-view .scroll-container {
  bottom: 84px;
}
vb-webcast-polls-view .btn:hover .show-on-hover {
  display: inline;
}
vb-webcast-polls-view .show-on-hover,
vb-webcast-polls-view .btn:hover .hide-on-hover {
  display: none;
}
vb-webcast-polls-view .poll-question-submitted {
  clear: right;
  padding-top: 5px;
  font-size: 0.8125rem;
}
vb-webcast-polls-view .poll-responses-wrapper {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
vb-webcast-polls-view .poll-responses {
  margin: 16px 0;
  text-align: left;
  padding: 0 32px;
}
vb-webcast-polls-view .poll-responses p {
  margin-bottom: 0;
}
vb-webcast-polls-view .poll-responses .vote-bar {
  height: 16px;
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
  display: inline-block;
  vertical-align: middle;
}
vb-webcast-polls-view .poll-responses .vote-bar.hasVoteCount {
  border-width: 1px;
  border-style: solid;
}
vb-webcast-polls-view .poll-responses .vote-bar + span {
  display: inline-block;
  height: 16px;
  line-height: 16px;
}
vb-webcast-polls-view .poll-sub-footer {
  bottom: 55px;
}
vb-webcast-polls-view .poll-footer {
  border-top: 1px solid #ddd;
  width: 100%;
  padding: 0 16px;
  font-size: 0.75rem;
}
vb-webcast-polls-view .poll-edit-tools {
  padding-top: 8px;
}
vb-webcast-polls-view .viewport-pane {
  padding: 0 16px;
}
vb-webcast-polls-view .viewport-pane.host-view .poll-sub-footer {
  bottom: 0;
}
vb-webcast-polls-view .viewport-pane .poll-sub-footer {
  bottom: auto;
}
vb-webcast-polls-view .total {
  border-top: 1px solid #d8d8d8;
  margin-top: 32px;
  padding-top: 8px;
}
vb-webcast-polls-view .edit-webcast-poll {
  padding: 16px;
  position: relative;
  height: 375px;
}
vb-webcast-polls-view .edit-webcast-poll h5 {
  margin-top: 16px;
}
vb-webcast-polls-view .edit-webcast-poll h5 .pull-right {
  margin-top: -3px;
}
vb-webcast-polls-view .edit-webcast-poll h5 .pull-right .btn {
  padding: 2px 6px;
}
