vb-html-editor {
  display: block;
  position: relative;
  min-height: 260px;
}
vb-html-editor .cke_combo_button {
  margin-bottom: 3px;
}
vb-html-editor .editor-loading {
  height: 260px;
  width: 100%;
  position: absolute;
}
vb-html-editor .cke_wysiwyg_frame {
  border-style: solid;
  box-sizing: border-box;
}
vb-html-editor .cke_wysiwyg_frame:not(.has-focus) {
  border-color: #909090;
  border-width: 1px;
}
vb-html-editor .cke_wysiwyg_frame.has-focus {
  border-width: 3px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
.user-content a {
  color: #007ebd;
}
.user-content a:hover {
  color: #00a0f0;
}
.vb-emoji {
  font-family: theme-font, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1.5em;
}
