.media-layout .right-sidebar.is-open .right-sidebar-content {
  padding: 0;
  background-color: #666666;
  border-right: 1px solid rgba(255, 255, 255, 0.75);
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid {
  padding: 0 16px;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid > header {
  position: static;
  margin: 0 -16px;
  padding: 1px 16px;
  border-width: 0;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .sidebar-form {
  margin: 0 -16px;
  padding: 18px 16px;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .uib-datepicker-popup {
  left: initial !important;
  right: 0;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .required.has-error .error-field {
  display: block;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .required-field:before {
  left: auto;
  right: -16px;
  top: 25px;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .error-field {
  padding-top: 5px;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid label.control-label {
  font-size: 0.85em;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .acl-op {
  padding-bottom: 5px;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid .acl {
  padding-top: 0;
  color: #000;
}
.media-layout .right-sidebar.is-open .right-sidebar-content > .container-fluid select {
  padding: 0;
}
.media-layout .right-sidebar.is-open .right-sidebar-content vb-toolbar .btn.btn-cancel:not(.active) {
  background: #f3f3f3;
}
.media-layout .sidebar-buttons li a,
.media-layout .sidebar-buttons li button {
  color: inherit;
}
.media-layout .sidebar-buttons li a:hover,
.media-layout .sidebar-buttons li button:hover {
  color: inherit;
}
.media-layout .sidebar-buttons li span.glyphicons {
  position: static;
  vertical-align: middle;
}
.media-layout .sidebar-buttons .download-inventory-reports .filetype {
  color: inherit;
  padding: 0;
}
.media-layout .sidebar-buttons .download-inventory-reports .filetype:before {
  position: initial;
  font-size: 16px;
  color: inherit;
}
@media (min-width: 992px) {
  .media-layout .right-sidebar {
    /* prevents flicker when opening sidebar */
    width: 48px;
  }
  .media-layout .right-sidebar.is-open:not(.force-closed) {
    width: 480px;
  }
  .media-layout .right-sidebar.is-open:not(.force-closed) .right-sidebar-content {
    width: calc(480px - 48px);
  }
  .media-layout .right-sidebar .sidebar-buttons {
    top: 0;
    bottom: 0;
    padding-top: 16px;
  }
}
@media (max-width: 991px) {
  .media-layout .right-sidebar-content {
    overflow-y: scroll;
  }
}
