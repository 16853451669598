.media-list-results {
  overflow-x: hidden;
  min-height: 80vh;
}
.media-list-results .right-sidebar {
  z-index: 9;
  border-left: 1px solid #161616;
  background-color: inherit;
}
.media-list-results .right-sidebar,
.media-list-results .right-sidebar.is-open {
  height: auto;
}
.media-layout {
  position: relative;
}
.media-layout-content {
  position: relative;
  min-height: 90vh;
  width: 100%;
  margin-top: 16px;
}
@media (min-width: 992px) {
  .media-layout-content {
    padding-bottom: 63px;
  }
}
@media (max-width: 991px) {
  .media-list-results .right-sidebar {
    z-index: 1035;
  }
}
