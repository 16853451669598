.dashboard-carousel .carousel-cell {
  display: block;
  position: relative !important;
}
@media all and (min-width: 992px) {
  .dashboard-carousel .carousel-cell {
    min-width: 16.65%;
    position: absolute !important;
    width: 16.65%;
  }
}
.dashboard-carousel .flickity-button:focus {
  box-shadow: none;
  outline: none;
}
.dashboard-carousel .flickity-prev-next-button {
  display: none;
}
@media all and (min-width: 992px) {
  .dashboard-carousel .flickity-prev-next-button {
    display: block;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all linear 0.25s;
    transition: all linear 0.25s;
    width: 30px;
  }
}
.dashboard-carousel .flickity-prev-next-button[disabled] {
  display: none;
}
.dashboard-carousel .flickity-prev-next-button.next,
.dashboard-carousel .flickity-prev-next-button.previous {
  background: transparent;
}
.dashboard-carousel .flickity-prev-next-button.previous {
  left: -40px;
}
@media all and (max-width: 1200px) {
  .dashboard-carousel .flickity-prev-next-button.previous {
    left: -35px;
  }
}
.dashboard-carousel .flickity-prev-next-button.next {
  right: -40px;
}
@media all and (max-width: 1200px) {
  .dashboard-carousel .flickity-prev-next-button.next {
    right: -35px;
  }
}
.dashboard-carousel .flickity-resize .carousel-cell {
  min-height: 100%;
}
.dashboard-carousel video-360-indicator {
  position: absolute;
  top: 1.5rem;
  right: 4px;
}
@media all and (max-width: 991px) {
  .dashboard-carousel .flickity-slider {
    position: relative;
    transform: none !important;
  }
  .dashboard-carousel .flickity-viewport {
    height: auto !important;
  }
}
.borderSolidBottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.header {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.headerPrimaryWrapper {
  max-width: 85%;
}
.title {
  color: #fff;
  margin: 0;
  padding: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  outline: 1px solid rgba(0, 0, 0, 0);
  width: 30rem;
  max-width: 30rem;
}
.title,
.titleLink {
  font-size: 1.125rem;
}
.marginBottom {
  margin-bottom: 30px;
}
@media all and (max-width: 992px) {
  .marginBottom {
    margin-bottom: 10px;
  }
}
.browseAll {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex: 1;
  flex: 1;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.browseAll > a:hover {
  text-decoration: underline;
}
