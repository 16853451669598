speech-search-results-list {
  display: block;
  padding-left: 80px;
  margin-top: 8px;
  width: 100%;
}
speech-search-results-list .speech-search-results-list-expander {
  color: #303030;
  margin-left: 60px;
  display: inline-block;
  margin-bottom: 8px;
}
speech-search-results-list .speech-search-results-list-expander .glyphicons {
  font-size: 0.625rem;
}
