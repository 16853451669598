@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

vb-html-editor {
	display: block;
	position: relative;

	.cke_combo_button {
		margin-bottom: 3px;
	}

	@height: 260px;
	min-height: @height;

	.editor-loading {
		height: @height;
		width: 100%;
		position: absolute;

	}

	.cke_wysiwyg_frame {
		border-style: solid;
		box-sizing: border-box;

		&:not(.has-focus) {
			border-color: @lightBorder;
			border-width: 1px;
		}

		&.has-focus {
			border-width: 3px;
			.BoxShadow(inset 0px 0px 5px 0px @blackFade25);
		}
	}
}

.user-content {
	a {
		color: @accentDarken10;
		&:hover {
			color: @accentColor;
		}
	}
}

.vb-emoji {
	font-family: theme-font, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	font-size: 1.5em;
}
