@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root {
	.displayFlex();
	padding-top: 10px;
	height: 100%;

	vb-ui-tab-panel {
		//figure out better way later...
		[role="tabpanel"] {
			border-left: 0;
			border-right: 0;
			border-bottom: 0;
		}
		button[role="tab"] {
			&:first-child {
				border-left: 0;
			}
		}
	}
}

.noQuestionAsked {
	display: flex;
	justify-content: center;
	padding: 16px;
}
