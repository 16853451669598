@import (reference) '~rev-less/global/variables';

speech-search-results-list {
	display: block;
	padding-left: 80px;
	margin-top: @typeUnit1-2x;
	width: 100%;

	.speech-search-results-list-expander {
		color: @color1;
		margin-left: 60px;
		display: inline-block;
		margin-bottom: @typeUnit1-2x;

		.glyphicons {
			font-size: @type10;
		}
	}
}