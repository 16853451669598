[flex="logo"] {
  -webkit-flex: 1 1 75%;
  flex: 1 1 75%;
}
.theme-Nettrix {
  /* This will change once there is actual data integrated from Lalit backend work */
}
.theme-Nettrix branding-logo {
  display: flex;
}
.theme-Nettrix branding-logo img {
  height: 75px;
}
@media all and (max-width: 992px) {
  .theme-Nettrix branding-logo img {
    height: 49px;
  }
}
@media all and (max-width: 768px) {
  .theme-Nettrix branding-logo img {
    height: 40px;
  }
}
.theme-Nettrix .main-nav {
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
.theme-Nettrix .main-nav-btn {
  box-shadow: none;
  font-size: 0.875rem;
  height: 36px;
  width: 36px;
}
.theme-Nettrix .main-nav-btn.dropdown-toggle:not(.btn-mobile) {
  font-size: 1.5rem;
  padding: 0;
  margin-left: 2rem;
  padding-bottom: 4px;
}
.theme-Nettrix .main-nav-btn.dropdown-toggle:not(.btn-mobile):not(.open):after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: '';
  border-radius: 100%;
  transform: scale(1.25);
  opacity: 0.9;
  z-index: -1;
  margin: 1px;
}
@media all and (max-width: 768px) {
  .theme-Nettrix .main-nav-btn.dropdown-toggle:not(.btn-mobile) {
    margin-left: 0;
  }
}
.theme-Nettrix .main-nav-btn:hover .glyphicon,
.theme-Nettrix .main-nav-btn:hover .glyphicons {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}
.theme-Nettrix .main-nav-btn span + span {
  margin-left: 0;
}
.theme-Nettrix .main-nav .btn.upload {
  background: transparent;
}
.theme-Nettrix .main-nav .dropdown.open .btn .notifications,
.theme-Nettrix .main-nav .dropdown.open .btn .upload {
  border: 1px solid #909090;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.theme-Nettrix .main-nav .dropdown.open.search-icon:after {
  border-right: 1px solid #909090;
  border-left: 1px solid #909090;
  bottom: -30px;
  content: " ";
  height: 30px;
  left: 2rem;
  position: absolute;
  width: calc(100% - 2rem);
}
@media all and (max-width: 992px) {
  .theme-Nettrix .main-nav .dropdown.open.search-icon:after {
    bottom: -17px;
    height: 20px;
  }
}
@media all and (max-width: 768px) {
  .theme-Nettrix .main-nav .dropdown.open.search-icon:after {
    bottom: -11px;
    left: 0;
    width: 100%;
  }
}
.theme-Nettrix .main-nav .dropdown.open.search-icon .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 1px solid #909090;
  border-left: 1px solid #909090;
  border-right: 1px solid #909090;
}
.theme-Nettrix .main-nav .dropdown.open.search-icon .dropdown-toggle:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
}
.theme-Nettrix .navbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 3rem;
  padding-left: 80px;
  z-index: 1;
}
@media all and (max-width: 768px) {
  .theme-Nettrix .navbar {
    padding: 1rem;
  }
}
.theme-Nettrix .navbar-brand {
  display: flex;
  float: none;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  width: 143px;
}
.theme-Nettrix .btn-mobile {
  padding: 0 8px;
}
.theme-Nettrix .btn-mobile .icon-bar {
  margin-bottom: 4px;
}
.theme-Nettrix .btn-mobile .icon-bar:first-of-type {
  margin-top: 4px;
}
@media all and (max-width: 768px) {
  .theme-Nettrix .btn.show-nav.btn-mobile-open {
    left: 165px;
  }
}
.theme-Nettrix #mobile-menu {
  display: block;
}
.theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu {
  display: block;
  top: 0;
  height: 100%;
  left: -250px;
  position: fixed;
  border-top: transparent;
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
  width: 250px !important;
}
@media all and (max-width: 992px) {
  .theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu {
    width: 100%;
  }
}
.theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu .accent-border {
  border-right-style: solid;
  border-right-width: 1px;
}
.theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu .mobile-menu-list {
  padding-left: 15px;
  margin-top: 91px;
  overflow-y: auto;
  height: calc(100% - 91px);
}
@media all and (max-width: 992px) {
  .theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu .mobile-menu-list {
    margin-top: 86px;
    height: calc(100% - 86px);
  }
}
@media all and (max-width: 768px) {
  .theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu .mobile-menu-list {
    margin-top: 55px;
    height: calc(100% - 55px);
  }
}
.theme-Nettrix .dropdown-menu.navdrop-menu.mobile-menu .mobile-menu-list .menu-item-icon {
  top: -2px;
}
.theme-Nettrix .open > .mobile-menu {
  left: 0 !important;
}
.theme-Nettrix .dropdown.netrix-dropdown.open:before,
.theme-Nettrix .dropdown.netrix-dropdown.open:after {
  border-right: 1px solid #909090;
  border-left: 1px solid #909090;
  bottom: -11px;
  content: " ";
  height: 20px;
  left: 2rem;
  position: absolute;
  width: calc(100% - 2rem);
  z-index: 50000;
}
@media all and (max-width: 768px) {
  .theme-Nettrix .dropdown.netrix-dropdown.open:before,
  .theme-Nettrix .dropdown.netrix-dropdown.open:after {
    height: 8px;
    left: 0;
    top: 36px;
    width: 100%;
  }
}
.theme-Nettrix .dropdown.netrix-dropdown.open > .btn:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: " ";
}
.theme-Nettrix .dropdown.netrix-dropdown.open .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 1px solid #909090;
  border-left: 1px solid #909090;
  border-right: 1px solid #909090;
}
.theme-Nettrix .dropdown.netrix-dropdown.open .dropdown-menu {
  top: 52px;
  padding: 0;
  margin-top: 0 !important;
}
.theme-Nettrix .dropdown.netrix-dropdown.open .dropdown-menu.notification-menu {
  border: 1px solid #909090;
  right: -30px;
}
@media (min-width: 769px) {
  .theme-Nettrix .dropdown.netrix-dropdown.open .dropdown-menu {
    left: auto;
    padding: 0;
    right: -50px;
    top: 48px;
  }
}
.theme-Nettrix search-header-dropdown .main-nav-btn {
  width: auto;
}
.theme-Nettrix search-header-dropdown .search-icon-btn {
  width: 36px;
}
.theme-Classic {
  font-size: 0.875rem;
  /* needed to offset a negative margin of 4px added in upload-menu.less */
}
@media all and (max-width: 991px) {
  .theme-Classic nav {
    min-height: 53px;
    position: relative;
  }
}
.theme-Classic .brand-cell {
  text-align: center;
}
@media all and (max-width: 991px) {
  .theme-Classic .brand-cell {
    position: absolute;
    left: 0;
    right: 0;
  }
}
.theme-Classic .brand-cell .navbar-brand {
  display: inline-block;
  height: auto;
  float: none;
  margin-top: 0;
  padding: 0;
}
.theme-Classic .brand-cell .navbar-brand img {
  height: 40px;
}
@media all and (max-width: 991px) {
  .theme-Classic .brand-cell .navbar-brand img {
    height: 36px;
  }
}
.theme-Classic .main-nav-btn {
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
  font-size: 0.875rem;
  height: 36px;
  min-width: 40px !important;
}
.theme-Classic .main-nav-btn:not(.theme-accent-bg) {
  background: hsla(0, 0%, 100%, 0.1);
}
.theme-Classic .main-nav-btn .glyphicons {
  font-size: 1.125rem;
  top: 0;
  vertical-align: middle;
}
.theme-Classic .main-nav-links {
  position: relative;
}
.theme-Classic .main-nav-links > a {
  display: inline-block;
  margin-right: 30px;
  height: 57px;
  line-height: 57px;
  text-shadow: none;
}
.theme-Classic .main-nav-links > a:hover {
  text-decoration: none;
}
.theme-Classic .main-nav-links > .pip {
  height: 7px;
  width: 65%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.theme-Classic .main-nav-links .glyphicons:not(vb-caret) {
  font-size: 1.125rem;
  top: 0;
  vertical-align: middle;
}
.theme-Classic .main-nav-links:not(.active):not(:hover) > .pip {
  background-color: transparent !important;
}
.theme-Classic .search-cell vb-ui-typeahead input {
  background-color: hsla(0, 0%, 100%, 0.3);
  border: none;
  top: 1px;
  position: relative;
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
}
.theme-Classic .search-cell vb-ui-typeahead input:hover,
.theme-Classic .search-cell vb-ui-typeahead input:focus {
  background-color: #fff;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
}
.theme-Classic .search-cell vb-ui-typeahead input:hover::-webkit-input-placeholder {
  color: #999999 !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:hover:-moz-placeholder {
  color: #999999 !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:hover::-moz-placeholder {
  color: #999999 !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:hover:-ms-input-placeholder {
  color: #999999 !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:focus:-moz-placeholder {
  color: transparent !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:focus::-moz-placeholder {
  color: transparent !important;
}
.theme-Classic .search-cell vb-ui-typeahead input:focus:-ms-input-placeholder {
  color: transparent !important;
}
.theme-Classic .search-cell vb-ui-typeahead input [role="search"] {
  width: 100%;
}
.theme-Classic .utility-section {
  margin: 0 2px;
}
.theme-Classic notifications-menu-button .main-nav-btn.notifications {
  font-size: 0.875rem;
}
.theme-Classic .admin-cell {
  margin-top: 2px;
}
.theme-Classic .admin-cell .glyphicons {
  line-height: 2;
}
.theme-Classic .upload {
  position: relative;
}
.theme-Classic .upload .glyphicons {
  z-index: 3;
}
@media all and (max-width: 768px) {
  .theme-Classic .upload-cell .dropdown-menu {
    top: 70px !important;
  }
}
.theme-Classic .open .dropdown-toggle:before {
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #000 transparent;
  left: 50%;
  margin-left: -14px;
  bottom: -17px;
  z-index: 5001;
}
@media all and (max-width: 767px) {
  .theme-Classic .open .dropdown-toggle:before {
    bottom: -22px;
  }
}
.theme-Classic .dropdown-menu {
  line-height: 1;
  left: auto;
  top: 54px;
  margin: 0;
  padding: 0;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 0;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  z-index: 5000;
}
.modal-open .theme-Classic .dropdown-menu {
  z-index: 910;
}
.theme-Classic .dropdown-menu:not(.upload-media) :not(.uib-typeahead-match) a,
.theme-Classic .dropdown-menu:not(.upload-media) :not(.uib-typeahead-match) .list-group-item {
  background: none;
  color: #565656;
  border: none;
  white-space: inherit;
}
.theme-Classic .dropdown-menu:not(.upload-media) :not(.uib-typeahead-match) a .glyphicons,
.theme-Classic .dropdown-menu:not(.upload-media) :not(.uib-typeahead-match) .list-group-item .glyphicons {
  color: #898989;
  font-size: 1rem;
}
.theme-Classic .dropdown-align-left .dropdown-menu {
  left: 8px;
  right: auto;
}
.theme-Classic .dropdown-align-left.open .dropdown-toggle:before,
.theme-Classic .dropdown-align-left.open .dropdown-toggle:after {
  left: 23px;
}
.theme-Classic .align-right .dropdown-menu {
  right: 0;
}
@media all and (max-width: 75em) {
  .theme-Classic media-menu.dropdown-menu {
    left: -32px !important;
  }
  .theme-Classic media-menu .media-arrow-bg {
    left: 90px;
  }
  .theme-Classic media-menu .media-arrow-fg {
    left: 92px;
  }
}
.theme-Classic media-menu-dropdown .open .dropdown-toggle:before {
  display: none;
}
.theme-Classic vb-caret {
  font-size: 0.75rem;
}
.theme-Classic help-menu .help-nav {
  top: 60px;
}
.theme-Classic .user-profile-menu {
  min-width: 0;
}
@media (max-width: 768px) {
  .theme-Classic .notification-menu,
  .theme-Classic .upload-media {
    top: 65px !important;
  }
}
@media all and (min-width: 769px) and (max-width: 992px) {
  .theme-Classic .upload-media {
    top: 58px !important;
  }
}
.theme-TwoTier {
  /* will have to think about how to manage utilty names better for these situation */
  /* Mobile menu */
}
.theme-TwoTier .navbar {
  align-items: center;
  justify-content: center;
  min-height: 75px;
}
.theme-TwoTier .navbar-wrapper {
  padding: 1rem 2rem;
}
@media all and (max-width: 768px) {
  .theme-TwoTier .navbar-wrapper {
    padding: 0.5rem;
  }
}
@media all and (max-width: 992px) {
  .theme-TwoTier .navbar .brand-cell {
    margin-left: 25px;
    max-width: 100%;
    text-align: center;
  }
}
.theme-TwoTier .navbar [flex="branding"] {
  -webkit-flex: 0 1 40%;
  flex: 0 1 40%;
}
@media all and (max-width: 992px) {
  .theme-TwoTier .navbar [flex="branding"] {
    align-self: center;
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
.theme-TwoTier .navbar branding-logo img {
  height: 75px;
}
@media all and (max-width: 768px) {
  .theme-TwoTier .navbar branding-logo img {
    height: 49px;
  }
}
@media all and (max-width: 505px) {
  .theme-TwoTier .navbar branding-logo img {
    height: 35px;
  }
}
.theme-TwoTier .navbar [flex="search"] {
  -webkit-flex: 1 1 10%;
  flex: 1 1 10%;
}
.theme-TwoTier .navbar-tier-two {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-top: 1px solid #2f4f4f;
  justify-content: center;
  padding: 0.5rem;
}
@media all and (max-width: 992px) {
  .theme-TwoTier .navbar-tier-two {
    display: none !important;
  }
}
.theme-TwoTier .navbar-tier-two .menu-list-tight {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 50px;
}
.theme-TwoTier .navbar-tier-two .pip {
  display: none;
}
.theme-TwoTier .navbar-tier-two help-menu {
  display: inline;
}
.theme-TwoTier .navbar-tier-two help-menu .dropdown-toggle {
  white-space: nowrap;
  margin-right: -4px;
}
.theme-TwoTier .navbar-tier-two help-menu .help-tray {
  padding: 0 !important;
}
.theme-TwoTier .navbar-tier-two help-menu .dropdown-menu {
  top: 34px;
  text-transform: none !important;
}
.theme-TwoTier .navbar-tier-two .event-title-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
}
.theme-TwoTier .navbar-tier-two .event-display {
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.theme-TwoTier .navbar-tier-two .event-display-link {
  max-width: 230px;
  text-align: left;
  width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.theme-TwoTier .navbar-tier-two .event-display-text {
  margin: 0;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead input {
  background: transparent;
  border: 1px solid transparent;
  padding-left: 8px;
  box-shadow: none;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead input:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead input:focus::-webkit-input-placeholder {
  color: transparent !important;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead input:focus:-moz-placeholder {
  color: transparent !important;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead input:focus::-moz-placeholder {
  color: transparent !important;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead input:focus:-ms-input-placeholder {
  color: transparent !important;
}
@media all and (max-width: 991px) {
  .theme-TwoTier div.search-cell search-header vb-ui-typeahead {
    display: none;
  }
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead .dropdown-menu {
  top: 52px !important;
  right: -48px;
}
.theme-TwoTier div.search-cell search-header vb-ui-typeahead .dropdown-menu li > a {
  padding: 8px;
}
.theme-TwoTier div.search-cell search-header .btn.theme-accent-btn:hover {
  background: transparent !important;
}
.theme-TwoTier .main-nav-btn {
  box-shadow: none;
  font-size: 1.5rem;
  border-radius: 0;
}
.theme-TwoTier .main-nav-btn:not(.theme-accent-bg) {
  background: transparent;
  min-width: 40px !important;
}
.theme-TwoTier .open .dropdown-toggle:before {
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent #000 transparent;
  left: 50%;
  margin-left: -14px;
  bottom: -18px;
  z-index: 900;
}
.theme-TwoTier .dropdown-menu {
  line-height: 1;
  left: auto;
  top: 54px;
  margin: 0;
  padding: 0;
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-top: 0;
  border-radius: 3px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  z-index: 5000;
}
@media all and (max-width: 768px) {
  .theme-TwoTier .dropdown-menu {
    top: 77px !important;
  }
}
@media all and (min-width: 769px) and (max-width: 991px) {
  .theme-TwoTier .dropdown-menu {
    top: 47px !important;
  }
}
.modal-open .theme-TwoTier .dropdown-menu {
  z-index: 910;
}
.theme-TwoTier .dropdown-menu:not(.notification-menu):not(.upload-media) :not(.uib-typeahead-match) a,
.theme-TwoTier .dropdown-menu:not(.notification-menu):not(.upload-media) :not(.uib-typeahead-match) .list-group-item {
  background: none;
  color: #565656;
  border: none;
  white-space: inherit;
}
.theme-TwoTier .dropdown-menu:not(.notification-menu):not(.upload-media) :not(.uib-typeahead-match) a .glyphicons,
.theme-TwoTier .dropdown-menu:not(.notification-menu):not(.upload-media) :not(.uib-typeahead-match) .list-group-item .glyphicons {
  color: #898989;
  font-size: 1rem;
}
.theme-TwoTier .align-right .dropdown-menu {
  right: 0;
}
.theme-TwoTier .media-menu-list-item {
  position: relative;
}
.theme-TwoTier .media-menu-list-item .dropdown-menu {
  left: 0 !important;
  position: absolute;
  top: 28px !important;
}
.theme-TwoTier .media-menu-list-item .dropdown-menu .media-arrow-bg,
.theme-TwoTier .media-menu-list-item .dropdown-menu .media-arrow-fg {
  left: auto;
  right: 225px;
}
.theme-TwoTier .media-menu-list-item .dropdown-menu .media-arrow-bg {
  left: auto;
  right: 223px;
}
.theme-TwoTier .media-menu-list-item .dropdown-toggle:before,
.theme-TwoTier .media-menu-list-item .dropdown-toggle:after {
  opacity: 0;
}
.theme-TwoTier media-menu .arrow-container {
  left: 215px;
  top: -37px;
}
@media all and (max-width: 320px) {
  .theme-TwoTier .xs-mbl-left-30 {
    margin-left: 30px !important;
    margin-right: 0 !important;
  }
}
.theme-TwoTier notifications-menu-button .notifications.theme-accent-btn:hover {
  background: transparent !important;
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input {
  background-color: hsla(0, 0%, 100%, 0.3);
  border: none;
  top: 1px;
  position: relative;
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input:hover,
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input:focus {
  background-color: #fff;
  border: none;
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input:hover::-webkit-input-placeholder {
  color: #999999 !important;
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input:hover:-moz-placeholder {
  color: #999999 !important;
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input:hover::-moz-placeholder {
  color: #999999 !important;
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input:hover:-ms-input-placeholder {
  color: #999999 !important;
}
.theme-TwoTier mobile-menu .mobile-menu .search-cell vb-ui-typeahead input [role="search"] {
  width: 100%;
  color: #999999 !important;
}
.theme-TwoTier mobile-menu .mobile-menu .main-nav-btn:hover .glyphicons.search {
  color: #8ad8ff !important;
}
.theme-TwoTier mobile-menu .mobile-menu .main-nav-btn .glyphicons.search {
  color: rgba(255, 255, 255, 0.5) !important;
}
.theme-TwoTier mobile-menu .mobile-menu .input-group {
  display: inline-table;
  border-bottom: none;
}
.theme-TwoTier mobile-menu .dropdown-menu.mobile-menu,
.theme-Classic mobile-menu .dropdown-menu.mobile-menu {
  background: #303030;
  max-height: 90%;
  left: 0;
  margin: 0;
  overflow-y: auto;
  padding-bottom: 50px;
  position: fixed;
  right: 0;
}
.theme-TwoTier mobile-menu .dropdown-menu.mobile-menu-list,
.theme-Classic mobile-menu .dropdown-menu.mobile-menu-list {
  margin-top: 0;
}
.theme-TwoTier mobile-menu .dropdown-menu.mobile-menu .main-nav-btn,
.theme-Classic mobile-menu .dropdown-menu.mobile-menu .main-nav-btn {
  background: rgba(255, 255, 255, 0.1) !important;
  color: rgba(255, 255, 255, 0.5);
}
.theme-TwoTier mobile-menu .main-nav-btn,
.theme-Classic mobile-menu .main-nav-btn {
  border-radius: 3px;
  height: 36px;
  width: 36px;
}
.theme-TwoTier mobile-menu .main-nav-btn .glyphicons,
.theme-Classic mobile-menu .main-nav-btn .glyphicons {
  font-size: 24px;
  vertical-align: middle;
  top: 0;
}
.theme-TwoTier mobile-menu .btn-mobile-open,
.theme-Classic mobile-menu .btn-mobile-open {
  left: 0;
  margin-left: 0;
}
.theme-TwoTier mobile-menu .theme-header,
.theme-Classic mobile-menu .theme-header {
  background-color: #303030 !important;
}
.theme-TwoTier mobile-menu .open .dropdown-toggle:after,
.theme-Classic mobile-menu .open .dropdown-toggle:after,
.theme-TwoTier mobile-menu .open .dropdown-toggle:before,
.theme-Classic mobile-menu .open .dropdown-toggle:before {
  display: none;
}
.theme-TwoTier mobile-menu .mobile-menu-list li.active,
.theme-Classic mobile-menu .mobile-menu-list li.active {
  background-color: transparent;
}
.theme-TwoTier mobile-menu .mobile-menu-list li.active:after,
.theme-Classic mobile-menu .mobile-menu-list li.active:after {
  background-color: initial;
  width: 0;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item.isOpen,
.theme-Classic mobile-menu .mobile-menu-list-item.isOpen,
.theme-TwoTier mobile-menu .mobile-menu-list-item:hover,
.theme-Classic mobile-menu .mobile-menu-list-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.theme-TwoTier mobile-menu .mobile-menu-list-item .help-menu-list,
.theme-Classic mobile-menu .mobile-menu-list-item .help-menu-list,
.theme-TwoTier mobile-menu .mobile-menu-list-item .media-box-list,
.theme-Classic mobile-menu .mobile-menu-list-item .media-box-list {
  list-style-type: none;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item .help-menu-list a,
.theme-Classic mobile-menu .mobile-menu-list-item .help-menu-list a,
.theme-TwoTier mobile-menu .mobile-menu-list-item .media-box-list a,
.theme-Classic mobile-menu .mobile-menu-list-item .media-box-list a {
  padding-left: 25px !important;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item a,
.theme-Classic mobile-menu .mobile-menu-list-item a {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 1rem;
  margin-left: 0 !important;
  text-align: left;
  text-decoration: none;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item a.active,
.theme-Classic mobile-menu .mobile-menu-list-item a.active {
  background: rgba(0, 0, 0, 0.25);
  color: #fff;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item a:hover,
.theme-Classic mobile-menu .mobile-menu-list-item a:hover,
.theme-TwoTier mobile-menu .mobile-menu-list-item a:focus,
.theme-Classic mobile-menu .mobile-menu-list-item a:focus {
  text-decoration: none !important;
  background: rgba(0, 0, 0, 0.1) !important;
  color: #8ad8ff !important;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item.active:after,
.theme-Classic mobile-menu .mobile-menu-list-item.active:after {
  background-color: initial;
  width: 0;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item.theme-accent-btn:hover,
.theme-Classic mobile-menu .mobile-menu-list-item.theme-accent-btn:hover {
  background-color: #00a0f0 !important;
}
.theme-TwoTier mobile-menu .mobile-menu-list-item .glyphicon,
.theme-Classic mobile-menu .mobile-menu-list-item .glyphicon {
  color: #fff;
}
.theme-TwoTier mobile-menu search-header .form-control:not(:hover):not(:focus),
.theme-Classic mobile-menu search-header .form-control:not(:hover):not(:focus) {
  color: rgba(255, 255, 255, 0.5) !important;
}
.theme-TwoTier mobile-menu search-header .form-control::-webkit-input-placeholder,
.theme-Classic mobile-menu search-header .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.theme-TwoTier mobile-menu search-header .form-control:-moz-placeholder,
.theme-Classic mobile-menu search-header .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.theme-TwoTier mobile-menu search-header .form-control::-moz-placeholder,
.theme-Classic mobile-menu search-header .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.theme-TwoTier mobile-menu search-header .form-control:-ms-input-placeholder,
.theme-Classic mobile-menu search-header .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
/* these are meant to be used generically across all themes */
main-header .navbar {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}
main-header nav.theme-accent-border {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}
main-header .theme-logo:focus {
  outline: none;
}
@media all and (max-width: 320px) {
  main-header branding-logo img {
    height: 30px !important;
  }
}
@media all and (max-width: 320px) {
  main-header .btn.main-nav-btn {
    height: 28px;
    min-width: 28px !important;
  }
}
@media all and (max-width: 320px) {
  main-header .dropdown-menu.navdrop-menu.mobile-menu {
    top: 40px;
  }
}
