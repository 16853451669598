@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

webcast-attendees-list {
	.attendee-box {
		height: 100%;
		position: relative;

		.searchGroup {
			display: flex;
			.input-group {
				flex: 1 1 85%;
				max-width: 85%;
			}
		}

		input, .search-btn {
			height: 35px;
		}
		.search-btn {
			border-bottom-left-radius: 0;
    		border-top-left-radius: 0;
		}

		.input-group-btn > .btn {
			padding: 8px 12px;
		}

		.list-container {
			position: absolute;
			top: 40px;
			bottom: 0;
			width: 100%;
			padding: 0 !important;
		}
		vb-ui-infinite-scroll-grid {
			.ag-body-horizontal-scroll {
				display: none !important; //turning off grid horizontal scroll. revisit later.
			}
		}
	}
}
