@import (reference)'~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

vb-search-filter-list {
	display: block;
	margin-bottom: 10px;

	.search-filter-item {
		display: inline-block;
		margin: 0 5px 4px 0;
		padding: 2px 10px 5px;
		.RoundedShape(4px);
		color: @whiteFade90;
		background: @accentDarken25;

		button.close{
			margin-left: 10px;
			position: relative;
			top: 1px;
			float: none;
			color: @white;
			font-weight: normal;
		}
	}
}

@media (max-width: (@viewPortSmallWidth - 1)) {
	vb-search-filter-list{
		display: none;
	}
}
