@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@webcastNavHeight: 47px;
@webcastStatusBarHeight: 29px;
@webcastBackgroundColor: @backgroundLighten03;
@webcastSidebarMobileHeight: 48px;

.webcast {
	.modal.in {
		z-index: 10001 !important;
	}
	&-manage-questions-form-wrapper {
			vb-toolbar:nth-child(2) {
			border-bottom: 1px solid rgba(162, 163, 164, 1);

			.btn-group > .btn {
				padding: 8px;

				&:not(.active) {
					background: rgba(222, 223, 223, 1);
				}
			}

			.btn {
				&-radius0 {
					.RoundedShape(0);
				}
				&-inbox-show-new-questions {
					font-size: @type12;
					padding: 5px;
				}
			}

			[flex] {
				padding: 0;
			}
		}
	}

	.webcast-status-msg {
		position: relative;
		top: auto;
	}

	.webcast-body {
		position: relative;
		overflow: hidden;
	}
}
.webcast-wrap-fs {
	&:extend(.AbsoluteFill);
	background: @black;
}

.webcast-header {
	&:extend(.TextClip);
	padding-right: 15px;

	h2 {
		margin: 0;
		color: @color2;
		&:extend(.TextClip);
	}
}

.webcast-toolbar {
	border-bottom-width: 1px;
	border-bottom-style: solid;
	line-height: 47px;
	font-weight: bold;
	font-size: @type14;
	.presentation-slides & {
		border-top-width: 1px;
		border-top-style: solid;
	}
}

.resize-fill {
	&:extend(.AbsoluteFill);
	height: 100%;
	.flexItem(none) !important;
}

.webcast-main-content {
	position: relative;
	width: 100%;

	@media (max-width: (@viewPortMedWidth - 1px)) {
		padding-bottom: @webcastSidebarMobileHeight;
	}
}

.main-webcast-region { //todo: remove?
	max-height: 100%;
	padding-bottom: 40px;
	overflow: hidden;
	position: absolute;
	top: @webcastNavHeight + @webcastStatusBarHeight;
	left: 0;
	right: 0;
	bottom: 0;

	&.no-status-bar {
		top: @webcastNavHeight;
	}

	@media(min-width: @viewPortMedWidth) {
		height: auto;
		padding: 0;
	}

	@media (max-width: @viewPortMobileDeviceWidth) {
		&.presenter {
			top: 96px + @webcastStatusBarHeight;
		}
	}
}

.vertical-layout {
	.viewer-content:not(.ng-hide):not([hidden]) {
		.flexContainer(nowrap; stretch; stretch; column) !important;
	}
}

.webcast-wrap {
	position: relative;

	.webcast-main-content {
		.TransitionAll(all linear 0.125s);
	}

	.presentation-column {
		border-left-width: 1px;
		border-left-style: solid;
	}

	.vertical-layout {
		.presentation-column {
			border-left: 0;
		}
	}

	.webcast-player-presentation {

		position: absolute;
		top: 0 ;
		left: 0;
		right: 0;
		bottom: 256px;

		> div {
			.alignSelf(stretch);
		}
	}

	&-fs {
		padding-top: 2px;
	}

	.right-sidebar {
		background-color: @webcastBackgroundColor;
		@media (min-width: @viewPortMedWidth) {
			height: auto;
		}

		.right-sidebar-content {
			height: 100%;
			min-height: 0 !important;
		}
	}


	@media(max-width: (@viewPortMedWidth - 1px)){
		.webcast-main-content:not(.no-video) .presentation-column {
			border-left: 0;
			border-top-width: 1px;
			border-top-style: solid;
		}
	}

	.btn-open-in-new-window {

		@media(max-width: (@viewPortMedWidth - 1px)){
			padding-right: 50px;
		}
	}
}

.event {
	&-not-started {
		&:extend(.AbsoluteCenter);
		background-color: @webcastBackgroundColor;
		.RoundedShape(4px);
		max-height: 60vh;
		margin-top: 50px;
		max-width: 50%;
		padding: @typeUnit1x;
		position: absolute;
		left: 50%;
		overflow-y: auto;
		text-align: center;
		top: 50%;
		width: 50%;

		p {
			margin-top: 20px;
		}

		h2 {
			margin-top: 0;
		}
	}

	.attendee-box { //attendee pop up
		max-height: 80vh;
		overflow-y: auto;
		overflow-x: hidden;
	}

	form {
		margin-bottom: 1em;

		.btn-white {
			height: 31px;
		}
	}
}

.event-message { //not exclusive to the header
	color: @whiteFade75;
	text-align: center;
	line-height: 1;
	display: block;
	padding: 8px 16px;

	&.error,
	&.recording {
		background: @warningColor;
	}

	&.pre-production {
		padding: 13px 0;
		background: #6f7377;
		color: @white;
		font-size: @type16;
	}

	&.warning {
		background: #ffcb00;
		border-bottom: 1px solid #e8af04;
		color: @color1;
	}

	&.processing {
		background: @color1Fade50;
	}

	&.broadcasting {
		background: @accentColor;
	}
}

@media(min-width: @viewPortMedWidth) {
	.webcast-wrap {
		height: 100vh;
	}
}

@media all and (max-width: 900px) and (min-width: 525px) {
	.well { //only used in webcast and its report (TODO: remove Bootstrap well usages)
		padding: 8px;

	}
}
