@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vb-webcast-polls-view {
	h4 {
		font-size: @type16;
		font-weight: 700;
		margin: 10px 0 10px 0;
		text-align: left;
		padding: 0 16px;

		.btn-toolbar {
			font-size: @type12;
			font-weight: normal;
			margin-top: 4px;
		}
	}

	.host-view .poll-responses label {
		display: block;
	}

	&.result-view .scroll-container {
		bottom: 84px;
	}

	.btn:hover .show-on-hover {
		display: inline;
	}

	.show-on-hover,
	.btn:hover .hide-on-hover {
		display: none;
	}

	.poll {
		@type13: .8125rem;

		&-question-submitted {
			clear: right;
			padding-top: 5px;
			font-size: @type13;
		}

		&-responses-wrapper {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		&-responses {
			margin: 16px 0;
			text-align: left;
			padding: 0 32px;

			p {
				margin-bottom: 0;
			}

			.vote-bar {
				height: 16px;
				.TransitionAll();
				display: inline-block;
				vertical-align: middle;

				&.hasVoteCount {
					border-width: 1px;
					border-style: solid;
				}
			}

			.vote-bar + span {
				display: inline-block;
				height: 16px;
				line-height: 16px;
			}
		}


		&-sub-footer {
			bottom: 55px;
		}


		&-footer {
			border-top: 1px solid #ddd;
			width: 100%;
			padding: 0 16px;
			font-size: @type12;
		}


		&-edit-tools {
			padding-top: 8px;
		}
	}

	.viewport-pane {
		padding: 0 16px;

		&.host-view .poll-sub-footer {
			bottom: 0;
		}

		.poll-sub-footer {
			bottom: auto;
		}
	}

	.total {
		border-top: 1px solid @borderColorLight;
		margin-top: 32px;
		padding-top: 8px;
	}

	.edit {

		&-webcast-poll {
			padding: 16px;
			position: relative;
			height: 375px;

			h5 {
				margin-top: 16px;

				.pull-right {
					margin-top: -3px;
					.btn {
						padding: 2px 6px;
					}
				}
			}
		}
	}
}
