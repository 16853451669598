@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

@teamLinkPrimary: #00a0f0;

.team {
	&-container {
		.team-total {
			font-size: @type16;
			padding-top: 25px;
			text-align: right;
		}
	}
	&-wrapper {
		margin-top: 20px;
		margin-bottom: 20px;

		.team-not-available {
			text-align: center;
		}

		.team-link-primary {
			color: @teamLinkPrimary;
			text-decoration: underline;
		}
	}
	&-heading {
		color: @color1LightText;
		font-size: @type21;
		font-weight: normal;
	}
	&-box {
		border: 1px solid @borderColor;
		margin-bottom: 30px;

		cursor: pointer;

		.team-logo {
			&:extend(.WordBreak);
			&:extend(.HyphensAuto);
			overflow: hidden;
			position: relative;
		}

		.team-name {
			left: 50%;
			position: absolute;
			top: 50%;

			line-height: 1.2;

			margin-right: -47%;
			max-height: 100px;

			.Transform(translate(-50%, -50%));

			word-break: normal;
		}

		.team-name-wrapper {
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100% !important;

			opacity: .08;

			padding: 75px 0;

			text-align: center;
		}

		footer {
			background-color: @borderColor;
			padding: 5px;
		}

		.team-video-count {
			color: @darkBlue;
			font-weight: bold;
			margin-left: 5px;
		}
	}

	.team-name {
		line-height: 1.2;
		margin: 0 0 .5% 0;
		word-break: break-all;
	}

}

