@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@import "~rev-less/themes/Nettrix";
@import '~rev-less/themes/Classic';
@import '~rev-less/themes/TwoTier';
@import '~rev-portal/navigation/mobileMenu/current-menu';

/* these are meant to be used generically across all themes */
main-header {
	.navbar {
		border: none;
		.RoundedShape(0);
		margin-bottom: 0;
	}

	nav.theme-accent-border {
		border-bottom-style: solid;
		border-bottom-width: 2px;
	}

	.theme-logo {
		&:focus {
			outline: none;
		}
	}

	branding-logo {
		img {
			@media all and (max-width: @viewPortVerySmallWidth) {
				height: 30px !important;
			}
		}
	}

	.btn.main-nav-btn {
		@media all and (max-width: @viewPortVerySmallWidth) {
			height: 28px;
			min-width: 28px !important;
		}
	}

	.dropdown-menu.navdrop-menu.mobile-menu {
		@media all and (max-width: @viewPortVerySmallWidth) {
			top: 40px;
		}
	}
}
