.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 20px;
  background-color: #000;
}
.main {
  background-color: #f3f3f3;
  height: 90vh;
}
.downloadWrapper {
  padding: 30px;
}
.downloadIcon {
  padding: 5px;
  border-radius: 50%;
  color: #fff;
  background-color: #00a0f0;
}
.downloadLink:hover {
  text-decoration: none;
}
