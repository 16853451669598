@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

.dataAsOf {
	font-size: @type10;
	padding: .5rem 0;
}

.header {
	font-size: @type20;
}

.pauseBtn {
	background: @green;
}

.pauseResumeBtn {
	color: @white;
	padding: 10px 12px;

	&:focus,
	&:hover {
		color: @white;
	}
}

.pauseResumeIcon {
	.Round;
	background: @white;
	padding: 2px;

	&:before {
		color: @green;
	}
}

.resumeBtn {
	background: @alertColor;

	.pauseResumeIcon:before {
		color: @alertColor;
	}
}

.search {
	margin-right: 1rem;
}