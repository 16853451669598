@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

@iconSize: .75rem;

.statusIconOffline {
	.Round;
	background: @gray50;
	display: inline-block;
	height: @iconSize;
	width: @iconSize;
}

.statusIconOnline {
	composes: statusIconOffline;
	background: @green;
}