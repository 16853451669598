.webexLinkIcon {
  font-size: 1.5rem;
  margin-left: 16px;
  vertical-align: middle;
}
.linkText {
  vertical-align: middle;
  font-size: 1.125rem;
  margin-left: 5px;
}
.linkText :global(.loader) {
  margin-right: 0;
}
manage-webex-auto-ingest :global(.loader) {
  margin-left: 0;
}
.videoSettingsControl {
  position: relative;
  top: -15px;
}
.tagControl {
  composes: videoSettingsControl;
  top: -25px;
}
.logGrid {
  height: 550px;
}
webex-sites-list {
  display: block;
  padding: 0 12px;
}
.webexMeetingsListWrapper {
  border: 1px solid #909090;
  height: 400px;
}
.actionMenuGridRow:global(.ag-row.ag-row-focus) {
  z-index: 1;
}
.ciLinkCellRenderer {
  position: relative;
  top: -2px;
}
.warningIcon {
  color: #ff8015;
}
.saveWebexSiteSpinner :global(.loading-container) {
  z-index: 1;
}
.rightIcon {
  top: -4px;
}
.ssoCheckBox {
  position: relative;
  top: -2px;
}
.webexLiveStream :global(.error-field) {
  top: -5px !important;
}
.actionMenuWrapper {
  width: 11vw;
  left: -8vw;
}
