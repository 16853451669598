tags-input {
  position: relative;
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
tags-input .tags-icon-plus {
  border-radius: 10px;
  font-size: 14px;
  pointer-events: none;
  position: absolute;
  right: 5px;
  top: 6px;
}
tags-input .tags {
  background-color: #fff;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 24px;
  box-shadow: none;
}
tags-input .tags .input {
  background-color: transparent;
  color: #303030;
  font: 0.875rem 'theme-font', Helvetica Neue, Arial, sans-serif;
  height: 32px;
  max-width: 100% !important;
  padding-left: 0;
  width: 100% !important;
}
tags-input .tags .input::-webkit-input-placeholder {
  color: #b0b0b0;
}
tags-input .tags .input:-moz-placeholder {
  color: #b0b0b0;
}
tags-input .tags .input::-moz-placeholder {
  color: #b0b0b0;
}
tags-input .tags .input:-ms-input-placeholder {
  color: #b0b0b0;
}
tags-input .tags:not(.focused) {
  border-color: #909090;
}
tags-input .tags.focused {
  border-width: 3px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
}
tags-input.ng-invalid .tags {
  box-shadow: none;
}
tags-input.ng-invalid:not(.ng-untouched) .tags {
  border: 3px solid #c41616;
}
tags-input.tags-input-has-items .tags:not(.focused) .input.ng-untouched {
  opacity: 0;
}
tags-input.tags-input-has-items .input::-webkit-input-placeholder {
  color: transparent !important;
}
tags-input.tags-input-has-items .input::-moz-placeholder {
  color: transparent;
}
tags-input.tags-input-has-items .input:-ms-input-placeholder {
  color: transparent;
}
tags-input .autocomplete {
  box-shadow: none;
  padding: 0;
}
tags-input .autocomplete .suggestion-item {
  color: #141414;
  font-family: inherit;
  padding: 5px 16px;
}
tags-input .category-item,
tags-input .category-autocomplete-item {
  font-size: 13px;
}
tags-input .category-item > span,
tags-input .category-autocomplete-item > span,
tags-input .category-item > small,
tags-input .category-autocomplete-item > small {
  display: block;
  padding-right: 0.5rem;
}
tags-input .category-item > span,
tags-input .category-autocomplete-item > span {
  font-weight: bold;
  line-height: 1.4;
  margin-bottom: 2px;
}
tags-input .category-item > small,
tags-input .category-autocomplete-item > small {
  width: 100%;
  line-height: 12px;
}
tags-input .tags .tag-item {
  background: transparent;
  border: 1px solid #909090;
  font-family: 'theme-font';
  height: auto !important;
  position: relative;
  padding: 0.5rem 2.5rem 0.5rem 0.75rem;
}
tags-input .tags .tag-item.selected {
  box-shadow: 0 0 10px rgba(48, 48, 48, 0.75);
}
tags-input .tags .tag-item .remove-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  color: inherit;
  text-align: center;
  height: 100%;
  border-left: 1px solid rgba(0, 90, 130, 0.1);
  font-size: 11px;
  padding-top: 12px;
}
tags-input .tags .tag-item .remove-button:hover {
  text-decoration: none;
}
