.carouselCell {
  background: #fff;
  border: 1px solid #303030;
  display: block;
  padding: 4px 8px 4px 4px;
  border-radius: 2px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}
@media all and (max-width: 991px) {
  .carouselCell {
    background: #fff;
    border: none;
    border-radius: 0;
    padding: 2px 8px 2px 2px;
  }
}
@media all and (min-width: 992px) {
  .carouselCell {
    background: #000;
    display: inline-block;
    padding: 0;
    width: 95%;
  }
}
@media all and (max-width: 991px) {
  .carouselCellItem {
    border-top: transparent;
    left: 0 !important;
  }
  .carouselCellItem:first-child {
    border-top: 1px solid #d8d8d8;
  }
}
.imageWrapper {
  height: 40px;
  margin-right: 10px;
  width: 100%;
}
@media all and (max-width: 991px) {
  .imageWrapper {
    display: inline-block;
  }
}
@media all and (min-width: 992px) {
  .imageWrapper {
    height: 100%;
  }
}
.infoContainer {
  padding: 8px;
  text-align: center;
  vertical-align: top;
  height: 56px;
}
@media all and (max-width: 991px) {
  .infoContainer {
    display: inline-block;
    height: auto;
    margin-top: 10px;
    max-width: 65%;
    padding-top: 2px;
    text-align: left;
  }
}
.infoContainer h4 {
  color: #b0b0b0;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.infoContainer h5 {
  display: none;
}
@media all and (min-width: 992px) {
  .infoContainer h5 {
    color: #636363;
    display: block;
    font-size: 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
  }
}
.infoContainer a {
  color: #b0b0b0;
  font-size: 1rem;
}
@media all and (max-width: 320px) {
  .fixedRatioWrapper {
    margin-right: 0;
  }
}
@media all and (min-width: 321px) and (max-width: 992px) {
  .fixedRatioWrapper {
    margin-right: 15px;
  }
}
@media all and (max-width: 992px) {
  .fixedRatioWrapper {
    display: inline-block;
    height: 45px;
    width: 75px;
  }
}
.borderSolidBottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.header {
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.headerPrimaryWrapper {
  max-width: 85%;
}
.title {
  color: #fff;
  margin: 0;
  padding: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  outline: 1px solid rgba(0, 0, 0, 0);
  width: 30rem;
  max-width: 30rem;
}
.title,
.titleLink {
  font-size: 1.125rem;
}
.marginBottom {
  margin-bottom: 30px;
}
@media all and (max-width: 992px) {
  .marginBottom {
    margin-bottom: 10px;
  }
}
.browseAll {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex: 1;
  flex: 1;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.browseAll > a:hover {
  text-decoration: underline;
}
