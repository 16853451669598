@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root {
	.displayFlex();
	padding: 16px;
	h2 {
		margin: 4px 0;
	}

	h4 {
		margin: 10px 0;
		font-weight: bold;
	}
}

.sectionWrapper {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom-width: 1px;
	border-bottom-style: solid;
}

.supplementalContentItem {
	padding: 10px 0;

	:global(.glyphicons) {
		display: block;
		margin-right: @typeUnit1-2x;
		font-size: @type24;
	}
}

.fileIcon {
	margin-right: 8px;
	&::before {
		color: inherit;
	}
}
