@import (reference) '~rev-less/global/variables';

.iconBtn {
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
}
.zoneList {
	margin-top: 10px;
	background: @white;
	max-height: 300px;
	overflow-y: scroll;
}

.zone {
	padding: 10px;

	border: solid @borderColor;
	border-width: 0 1px 1px;
	&:first-child {
		border-top-width: 1px;
	}
}

.highlight {
	background: @highlight;
}
