@import (reference) '~rev-less/global/variables';

.root {
	background: @black;
	color: @white;
	display: block;
	height: 100%;
}

.msTeamsConnecting {
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 220px;
}