@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

share-embed {
	form > div {
		margin-bottom: @typeUnit1x;
	}

    label {
		color: @color1LightText;

        &[for="videoShareEmbedSizeSelect"] {
            display: block;
        }
	}

	.video-share-embed-invalid {
		color: @warningColor;
	}

	.embed-options {
		border-top: 1px solid @borderColorLight;
		margin-top: @typeUnit1x;
		display: block;

		@media(max-width: 767px) {
			font-size: @type12;

			select,
            input,
            label {
				font-size: @type14;
			}
		}

		input {
			width: 60px; // max of 4 digits
			display: inline-block;
			text-align: center;
			padding: 2px;

			&.form-control[disabled] {
				background: none;
				border: 1px solid @borderColorLighter;
				.BoxShadow(none);
			}
		}

		input[type=number]::-webkit-inner-spin-button,
		input[type=number]::-webkit-outer-spin-button {
			opacity: 1;
		}

		.select-cell {
			padding-right: @typeUnit2x;
		}

		.label-cell {
			padding-right: @typeUnit1x;
		}
	}

	textarea {
		display: block;
		width: 100%;
		resize: none;
		cursor: default;
		border: 1px solid @borderColorLight;
		border-bottom: none;
		.RoundedTopShape(4px);
		padding: .5rem;

		&[readonly] {
			cursor: default;
			border: 1px solid @borderColorLight;
			background: @color2Lighten05;
		}
	}

    .textarea-bottom-controls {
        border: 1px solid @borderColorLight;
        border-top: none;
        .RoundedBottomShape(4px);
        padding: @typeUnit1-2x;
        background: @white;
    }

	input[type="text"],
	input[type="number"] {
		display: inline-block;

		&.ng-invalid {
			border-color: @warningColor !important;
		}
	}
}