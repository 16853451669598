.layout {
  border-radius: 3px;
  border: 1px solid #909090;
  background-color: #fff;
  padding: 20px;
  position: relative;
  max-height: 650px;
  min-height: 180px;
}
.header {
  margin-top: -10px;
}
.headerText {
  composes: theme-accent-border from global;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  text-transform: uppercase;
  color: rgba(34, 41, 48, 0.95);
}
.headerLink:hover {
  color: inherit;
  text-decoration: none;
}
