.rev-create-download-dialog .modal-body {
  position: relative;
  padding-left: 80px;
}
.rev-create-download-dialog .modal-body.upload-media .upload-import-button {
  margin: 0;
  position: absolute;
  left: 16px;
}
.rev-create-download-dialog .modal-body.upload-media .upload-import-button .upload-import-button-logo {
  margin: 0;
}
.rev-create-download-dialog .modal-body .rev-create-download-dialog-body-text h2 {
  margin-top: 0;
  text-transform: uppercase;
}
.not-supported.rev-create-download-dialog .modal-body .rev-create-download-dialog-body-text {
  color: rgba(0, 0, 0, 0.5);
}
