.title {
  text-overflow: ellipsis;white-space: nowrap;overflow: hidden;-webkit-hyphens: auto;-moz-hyphens: auto;-ms-hyphens: auto;
}
.root {
  composes: container-fluid from global;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: block;
  padding-bottom: 4px;
  padding-top: 4px;
}
.wrapper {
  min-height: 38px;
}
.title {
  font-weight: 700;
  margin: 0;
}
.webcastButtons {
  max-width: 100%;
}
.webcastButtons > * {
  margin-left: 8px;
}
.webcastNavBtn {
  composes: vbIconBtn from '~rev-shared/ui/buttons/vb-btn.module.less';
  line-height: 36px;
  padding: 0 12px;
}
.webcastNavBtn:focus {
  color: inherit !important;
}
.vcRecordingStatus {
  color: #c41616;
  font-size: 0.875rem;
}
@media (max-width: 991px) {
  .webcastNavToggleViewBtn {
    display: none;
  }
}
@media (max-width: 505px) {
  .webcastButtons {
    text-align: right;
  }
  .webcastRegionVisibilityToggle {
    float: left;
    min-width: 116px;
  }
  .webcastStateControls {
    margin-top: 0.25rem;
  }
}
