@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

webcast-comments {
		&, &>[layout] {
		height: 100%;

        @media all and (max-width: (@viewPortMedWidth - 1px)) {
            /* take into consideration that the sidebar icons are on bottom in mobile */
            height: ~"calc(100% - 24px)";
        }

	}
}

.comment {
	&-scroll-box {
		overflow-y: auto;
		padding: 8px;
		padding-right: 18px;
		border: 1px solid #e3e3e3;
		background-color: @white;
		margin-bottom: 10px;
		font-size: 12px;
		.RoundedShape(4px);

		.comment {
			padding-bottom: 1px;
			border: 0 none;
		}

		p {
			color: #1e1e1e;
		}

		.chat {
			&-username {
				color: #00a1f0;
				font-weight: bold;
			}

			&-time-stamp {
				color: #999;
				font-style: italic;
				font-size: 11px;
				font-weight: normal;
			}
		}
	}

	&-input-box {
		font-size: 12px;
		overflow-y: hidden;

		.btn {
			padding-left: 10px;
			padding-right: 10px;
		}

		.input-group {
			input {
				height: 35px;
				.BoxShadow(inset 0 1px 1px @borderColor, 0 1px 0 @blackFade25;);
			}

			.btn {
				padding: 8px 12px;
			}
		}
	}
}

@media all and (max-width: 900px) and (min-width: 525px) {
	.comment-scroll-box {
		margin-bottom: 4px;
	}
}
