@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.sectionWrapper {
	display: block;
	justify-content: space-around;
	position: relative
}

.tileWrapper {
	display: inline-block;
	width: ~'calc(100% / 6 - 1%)';
	margin-right: 1%;
	@media all and (max-width: @viewPortSmallWidth) {
		width: 100%;
		&:not(:first-child) {
			display: none;
		}
	}
}

.tileSmall {
	background: @white;
	cursor: pointer;
	margin-bottom: 15px;
	position: relative;
	width: 100%;
	.RoundedShape(2px);
	.BoxShadow(0 0 1px @blackFade50);
}

.infoContainer {
	padding: 8px;

	@media all and (max-width: @viewPortSmallWidth) {
		display: none;
	}

	h4 {
		margin: 0;
		padding: 0;
	}
}

.greekedText {
	background: @blackFade05;
	.RoundedShape(2px);
	display: inline-block;
	height: 10px;
	width: 100%;

}

.noItems {
	position: absolute;
	z-index: 2;
	top: 40%;
	margin-top: -16px;
	width: 95%;
	text-align: center;

	@media all and (max-width: @viewPortSmallWidth) {
		left: 75px;
		text-align: left;
		top: 50%;
		width: auto;
	}

	h4 {
		border: 1px solid @color1Fade10;
		display: inline;
		color: @color1LightText;
		padding: 8px 20px;
		.RoundedShape(8px);
		background: @white;

		@media all and (max-width: @viewPortSmallWidth) {
			border: transparent;
			.RoundedShape(0);
		}

		@media all and (max-width: @viewPortMedWidth) {
			color: @color1Lighten50;
			font-size: @type16;
		}
	}
}

.hideBox {
	@media all and (max-width: @viewPortLargeWidth) {
		display: none;
	}
}


.fixedRatio {
	@media all and (max-width: @viewPortSmallWidth) {
		display: inline-block;
		height: 40px;
		width: 75px;
	}
}

.greekedImage {
	@media all and (max-width: @viewPortSmallWidth) {
		margin: 2px 0 0 2px !important;
	}
}
