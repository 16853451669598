@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@mobileMaxWidth: @viewPortMedWidth - 1px;

.root {
	display: flex;
	flex-flow: column;
	height: 100%;

	vb-ui-html-editor {
		margin-top: 1px;

		:global(.cke) {
			border-radius: 0;
		}

		.legacyStyle& {
			margin-top: 10px;

			:global(.cke) {
				.RoundedShape();
			}
		}
	}
}

.commentScroll {
	font-size: 12px;

	.legacyStyle & {
		background-color: @white;
		margin-bottom: 10px;
		border: 1px solid #e3e3e3;
		.RoundedShape();
	}

	:global(.cdk-virtual-scroll-content-wrapper) {
		padding: 8px 18px 8px 8px;
	}
}

.chatForm {
	border-top-width: 1px;
	border-top-style: solid;
}

.chatSubmitBtn {
	height: 40px;
	margin-top: 10px;
	margin-right: 5px;
	float: right;
}
