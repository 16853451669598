@import (reference) '~rev-less/global/variables';

.eventIcon {
	composes: theme-accent from global;
	border: 1px solid transparent;
    border-radius: 30px;
    padding: 10px;
    font-size: @type32;
}

.eventTitle {
	padding: 10px;
	margin: 0 auto;
}

.eventTitleLink,
.eventTitleLink:hover {
	color: @color0Fade75;
}

.eventTitleLink {
	composes: TextClip from global;
	font-size: @type20;
	font-weight: 500;
	display: block;
	line-height: 1.2;

	@media all and (min-width: @viewPortLargeWidth) {
		min-width: 180px;
	}
}

.eventDate {
	font-size: @type14;
	padding: 10px;
	padding-top: 0;
	color: @color1Fade50;
	margin: 0 auto;
}

.eventDescription {
	margin: 0 auto;
	color:  @color1Fade50;
	max-height: 45px;
	word-break: break-word;
	overflow: hidden;
}
