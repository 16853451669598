@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.layout {
	.RoundedShape();
	border: 1px solid @lightBorder;
	background-color: @white;
	padding: 20px;
	position: relative;
	max-height: 650px;
	min-height: 180px;
}

.header {
	margin-top: -10px;
}

.headerText {
	composes: theme-accent-border from global;
	border-bottom-width: 3px;
	border-bottom-style: solid;
	text-transform: uppercase;
	color: @color0Fade95;
}

.headerLink:hover {
	color: inherit;
	text-decoration: none;
}
