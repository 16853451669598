.gridList header,
.gridList vb-grid-row-collection > .gridRow {
  display: -webkit-flex;display: flex;
}
.gridList:global(.white-items) .toolbarColumn .btn {
  background: linear-gradient(to bottom, #fff, #f3f3f3);border-color: #b2b2b2;box-shadow: inset 0 1px 0 #fff;color: #303030;
}
.gridList {
  display: block;
}
.gridList header,
.gridList vb-grid-row-collection > .gridRow {
  -webkit-align-items: center;
  align-items: center;
}
.gridList header > div,
.gridList vb-grid-row-collection > .gridRow > div,
.gridList header > .gridColumn,
.gridList vb-grid-row-collection > .gridRow > .gridColumn {
  vertical-align: middle;
}
.gridList header > div:not([flex]),
.gridList vb-grid-row-collection > .gridRow > div:not([flex]),
.gridList header > .gridColumn:not([flex]),
.gridList vb-grid-row-collection > .gridRow > .gridColumn:not([flex]) {
  -webkit-flex: 1;
  flex: 1;
}
.gridList header > div[align=right],
.gridList vb-grid-row-collection > .gridRow > div[align=right],
.gridList header > .gridColumn[align=right],
.gridList vb-grid-row-collection > .gridRow > .gridColumn[align=right] {
  text-align: right;
}
.gridList header > div[align=center],
.gridList vb-grid-row-collection > .gridRow > div[align=center],
.gridList header > .gridColumn[align=center],
.gridList vb-grid-row-collection > .gridRow > .gridColumn[align=center] {
  text-align: center;
}
.gridList header {
  font-weight: bold;
  padding: 0 16px 0 0;
}
.gridList vb-grid-row-collection {
  display: block;
  padding: 0;
  overflow-y: scroll;
}
.gridList vb-grid-row-collection .gridRow {
  margin-top: 1em;
}
.gridList vb-grid-row-collection .gridRow:global(.selectable-item):hover {
  cursor: pointer;
}
.gridList [flex="fill"] {
  -webkit-flex: 2 1;
  flex: 2 1;
}
.gridList .gridRow:global(.ng-move),
.gridList .gridRow:global(.ng-enter),
.gridList .gridRow:global(.ng-leave) {
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
.gridList .gridRow:global(.ng-enter) {
  opacity: 0;
}
.gridList .gridRow:global(.ng-enter):global(.ng-enter-active) {
  opacity: 1;
}
.gridList .gridRow:global(.ng-leave) {
  opacity: 1;
}
.gridList .gridRow:global(.ng-leave):global(.ng-leave-active) {
  opacity: 0;
}
.gridList .gridRow:global(.ng-move) {
  opacity: 0;
  max-height: 0;
}
.gridList .gridRow:global(.ng-move):global(.ng-move-active) {
  opacity: 1;
  max-height: 48px;
}
.gridList .selectionColumn {
  -webkit-flex: none !important;
  flex: none !important;
  width: 32px;
  padding: 8px 4px 0;
}
.gridList .selectionColumn input {
  margin: 0;
}
.gridList .reorderRow :global(.btn.btn-svg-caret) {
  padding: 0;
  display: block;
  margin: 0 auto;
}
.gridList .reorderRow :global(.btn.btn-svg-caret) svg {
  width: 12px;
  height: 12px;
  fill: #898989;
}
.gridList .reorderRow :global(.btn.btn-svg-caret):hover svg {
  fill: #303030;
}
.gridList .reorderRow :global(.vb-draggable) {
  width: 14px;
  margin: 0 auto;
}
.gridList .toolbarColumn {
  text-align: right;
}
.gridList .toolbarColumn button,
.gridList .toolbarColumn a {
  margin-left: 2px;
}
.gridList .emptyMsg {
  font-size: 1.25em;
  text-align: center;
  padding: 60px 0;
}
.gridList:global(.white-items) vb-grid-row-collection {
  margin-right: 0;
}
.gridList:global(.white-items) vb-grid-row-collection > .gridRow {
  background: #fff;
  border-bottom: 1px solid #d8d8d8;
  margin: 0;
  padding: 6px 0;
}
.gridList:global(.white-items) vb-grid-row-collection > .gridRow:global(.selected) {
  background: #f2fafe;
  color: #007ebd;
}
.gridList:global(.white-items) vb-grid-row-collection > .gridRow > .gridColumn {
  padding: 0 4px;
}
.gridList:global(.white-items) vb-grid-row-collection > .gridRow:last-child {
  border-bottom: none;
}
.gridList:global(.white-items) header {
  color: #898989;
}
.gridList:global(.white-items) header > div {
  padding: 8px 4px;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 0.75rem;
}
