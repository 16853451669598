@import (reference) "~rev-less/global/variables";

.root {
	padding: 1rem;
}

.logo {
	margin-right: 1rem;
}

.realTimeDashboard {
	border-style: solid;
	border-width: 1px;
	line-height: 1.25;
	padding: .25rem .5rem;
	text-transform: uppercase;
}

.webcastSchedule {
	font-size: @type12;
}

.webcastTitle {
	font-size: @type20;
	font-weight: bold;
	line-height: 1.5;
}

.webcastTitleScheduleContainer {
	flex-shrink: 1;
	margin: 0 1rem;
	overflow: hidden;
}