@import (reference) '~rev-less/global/variables';

.contentWrapper {
	display: block;
}
.toggleBtnWapper {
	margin-top: 15px;
}

.toggleBtn {
	text-transform: uppercase;
	padding: 2px;
	font-size: @type11;
}
