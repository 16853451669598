.logo {
  position: absolute;
  top: 10px;
  left: 30px;
}
.formContainer {
  position: relative;
}
.isPreview .formContainer {
  padding-top: 0;
}
@media (min-width: 992px) {
  .formContainer {
    padding-top: 100px;
  }
}
.form {
  margin: 0 auto;
  padding: 30px;
  width: 100%;
  border-radius: 0px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(to bottom, #f7f7f7, #e9e9e9);
  color: rgba(0, 0, 0, 0.5);
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.75);
  line-height: normal;
  min-height: 100vh;
}
@media (min-width: 992px) {
  .form {
    min-height: auto;
    width: 992px;
    border-radius: 3px;
  }
  .isPreview .form {
    margin: 0;
    width: auto;
  }
}
.form h1 {
  font-size: 1.625rem;
  font-weight: normal;
}
.form h2 {
  font-size: 1.5rem;
  font-weight: normal;
}
.form header {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.main {
  display: flex;
  flex-direction: row;
}
@media (max-width: 991px) {
  .main {
    display: block;
  }
}
.left {
  flex: 0.33;
}
.right {
  flex: 0.66;
  margin-top: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .right {
    margin-top: 0;
    border-top-width: 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 25px;
  }
}
.small {
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .previewWindow {
    width: 900px;
  }
}
.previewBanner {
  composes: theme-accent from global;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  text-transform: uppercase;
}
.closeBtn {
  color: #fff !important;
  opacity: 1 !important;
}
