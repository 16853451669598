@import  (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@mobileMaxWidth: @viewPortMedWidth - 1px;

.eventNotStarted {
	width: 40vw;
	text-align: center;
	top: 30%;
	max-height: 55vh;
	padding: 0 16px 16px 16px;
	overflow-y: auto;
	border-radius: @defaultBorderRadius;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;


	@media (max-width: @mobileMaxWidth) {
		width: 90%;
		top: 15%;
		padding-left: 16px;
		padding-right: 16px;
	}
}

.eventDescriptionWrapper {
	border-top-width: 1px;
	border-top-style: solid;
	margin-top: 15px;
	padding-top: 15px;
}
