@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

video-playlist-carousel {
	@video-playlist-text-color: @whiteFade75;

	background-color: @blackFade50;
	border: 1px solid @borderColorLight;
	box-sizing: content-box;
	display: block;
	height: 48px;

	> .carousel-container {
		padding: 0 50px;
		position: relative;
		width: 100%;
		height: 100%;
	}

	.carousel-cell {
		color: @video-playlist-text-color;
		padding: 3px .5rem;
		border-right: 1px solid @borderColorLight;
		font-size: 13px;
		line-height: 15px;
		font-weight: bold;
		display: inline-block; 
		width: 200px;
		position: absolute;
		word-wrap: break-word;

		&:hover {
			background: @accentFade50;
			color: @white;
		}

		&.selected {
			background: @accentColor;
			border-color: @accentColor;
			color: @white;

			img {
				border: 1px solid @white;
			}
		}
	}

	img {
		width: 65px;
		height: 40px;
		margin-bottom: 1rem;
		margin-right: .5rem;
		float: left;
	}

	.btn-carousel {
		width: 50px;
		color: @white;
		background-color: @gray60;
		border: 0;
		position: absolute;
		top: 0;
		bottom: 0;

		&[disabled] {
			opacity: .25;
		}
	}

	.btn-carousel-prev {
		left: 0;
	}

	.btn-carousel-next {
		right: 0;
	}
}