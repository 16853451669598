.root {
  display: flex;
  flex-flow: column;
  height: 100%;
}
.root vb-ui-html-editor {
  margin-top: 1px;
}
.root vb-ui-html-editor :global(.cke) {
  border-radius: 0;
}
.legacyStyle.root vb-ui-html-editor {
  margin-top: 10px;
}
.legacyStyle.root vb-ui-html-editor :global(.cke) {
  border-radius: 3px;
}
.commentScroll {
  font-size: 12px;
}
.legacyStyle .commentScroll {
  background-color: #fff;
  margin-bottom: 10px;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
}
.commentScroll :global(.cdk-virtual-scroll-content-wrapper) {
  padding: 8px 18px 8px 8px;
}
.chatForm {
  border-top-width: 1px;
  border-top-style: solid;
}
.chatSubmitBtn {
  height: 40px;
  margin-top: 10px;
  margin-right: 5px;
  float: right;
}
