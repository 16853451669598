@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

mobile-menu {
	align-self: center;
	order: 0;

	@media (max-width: @viewPortMedWidth) {
		flex-grow: 1;
	}

	a,
	a:hover,
	a:active,
	a:focus {
		color: inherit;
	}

	.btn {
		border-left: 1px solid fade(@black, 15%);
		.BoxShadow(0 1px 0 @blackFade50);
		color: @whiteFade50;
		height: 36px;
		padding: 6px 9px 6px 12px;

		.glyphicons {
			font-size: 18px;
			vertical-align: middle;
			top: 0;
		}
	}

	.btn-mobile {
		z-index: 10001;
		&-open {
			left: 105px;
		}
	}

	.mobile-menu {
		background-color: @primaryDark;
		border-right-width: 1px;
		border-right-style: solid;

		&-list {
			
			&-item {
				margin-bottom: 10px;
				a {
					display: block;
					font-size: @type21 !important;
					padding: 15px !important;
					text-align: center;
					text-transform: uppercase;

					&.media-video-item {
						font-size: @type14;
						text-transform: none;
					}
				}

				&.active {
					.apply-overlay;
					
					&:hover {
						.clear-overlay;						
						
						a {
							text-decoration: none;
						}
					}
				}
			}

			my-videos-menu-items {
				li {
					margin-bottom: 10px;
				
					&.active {
						.apply-overlay;
					}
					&:hover {
						.clear-overlay;
					}
				}
			}
		}

		.media-video-item, .help-menu-item {
			font-size: @type16 !important;
			margin: 0;
			padding: 10px !important;
				
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.overlay(@overlayBGColor) {
		content: '';
		height: 100%;
		width: 10px;
		background-color: @overlayBGColor;
		.AbsoluteFill;
		z-index: 1;
	}

	.clear-overlay {
		&:after {
			content: none;
		}
	}

	.apply-overlay {
		position: relative;
		a {
			position: relative;
			text-decoration: underline;
			z-index: 2;
		}

		&:after {
			.overlay(@blackFade50)
		}
	}
}