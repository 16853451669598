playlist-sharing {
  display: block;
  padding: 60px 1rem;
}
playlist-sharing h4 {
  display: inline;
}
playlist-sharing share-link,
playlist-sharing share-embed {
  color: #000;
}
playlist-sharing share-link label,
playlist-sharing share-embed label,
playlist-sharing share-link .btn-white,
playlist-sharing share-embed .btn-white {
  color: #000;
}
playlist-sharing share-link .textarea-bottom-controls,
playlist-sharing share-embed .textarea-bottom-controls {
  background-color: rgba(235, 235, 235, 0.5);
}
playlist-sharing share-embed .embed-width-cell {
  width: 50%;
}
