vb-webcast-question-card .question-status.question-status-detail,
vb-webcast-question-card .question-mod-buttons button .label {
  text-overflow: ellipsis;white-space: nowrap;overflow: hidden;-webkit-hyphens: auto;-moz-hyphens: auto;-ms-hyphens: auto;
}
vb-webcast-question-card {
  display: flex;
  flex-flow: column;
  position: relative;
  padding: 1px;
  word-wrap: break-word;
  word-break: break-word;
}
vb-webcast-question-card article {
  position: relative;
}
vb-webcast-question-card .new-message {
  background: #ffff80;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  height: 30px;
  max-height: 30px;
  max-width: 30px;
  padding: 0;
  position: absolute;
  right: -5px;
  width: 30px;
}
@media all and (max-width: 768px) {
  vb-webcast-question-card .new-message {
    height: 20px;
    width: 20px;
  }
}
vb-webcast-question-card .new-message .bell-middle {
  color: #666666;
  left: 8px;
  top: 7px;
}
@media all and (max-width: 768px) {
  vb-webcast-question-card .new-message .bell-middle {
    left: 3px;
    top: 3px;
  }
}
vb-webcast-question-card .aside-icon {
  margin-left: 0;
}
vb-webcast-question-card .aside-icon .group {
  font-size: 0.75rem;
  padding: 5px 0 0 5px;
}
vb-webcast-question-card .aside-icon.icon-queued {
  margin-top: -5px;
}
vb-webcast-question-card .icon-block {
  display: block;
}
vb-webcast-question-card .question-info p {
  margin-bottom: 4px;
}
vb-webcast-question-card .question-number {
  font-size: 0.75rem;
}
vb-webcast-question-card .question-number:after {
  content: '.';
}
vb-webcast-question-card .question-status {
  font-size: 12px;
  width: 24px;
  border-width: 1px;
  border-style: solid;
}
vb-webcast-question-card .question-status.open-question:before {
  content: '?';
}
vb-webcast-question-card .question-status.circle {
  height: 24px;
  line-height: 24px;
}
vb-webcast-question-card .question-status.ok_2 {
  font-weight: normal;
}
vb-webcast-question-card .question-status.question-status-detail {
  border-radius: 4px;
  font-size: 0.625rem;
  text-align: center;
  width: 60px;
}
vb-webcast-question-card .question-status.question-status-detail.reply {
  background-color: #00a0f0;
  color: #fff;
}
vb-webcast-question-card .question-status.question-status-detail.closed {
  background-color: rgba(0, 0, 0, 0.1);
  color: #898989;
}
vb-webcast-question-card .question-qtext {
  font-size: 0.875rem;
  font-weight: 700;
  white-space: pre-line;
  margin: 0 0 8px 0;
  width: 95%;
  max-width: 95%;
  padding-top: 4px;
}
vb-webcast-question-card .question-from,
vb-webcast-question-card .question-when-asked {
  font-size: 0.75rem;
  line-height: 0.875rem;
  display: block;
}
vb-webcast-question-card .question-reply,
vb-webcast-question-card .question-answered-by-speaker {
  display: block;
  padding: 8px;
  margin-top: 8px;
  margin-right: 16px;
  line-height: 1;
}
vb-webcast-question-card .question-reply:before,
vb-webcast-question-card .question-answered-by-speaker:before {
  content: '>';
  margin-right: 8px;
}
vb-webcast-question-card .question-reply .comment-body {
  white-space: pre-line;
}
vb-webcast-question-card .question-reply-by {
  display: block;
  font-style: italic;
  margin-top: 8px;
  margin-left: 16px;
}
vb-webcast-question-card .question-reply-by:before {
  content: '\2014';
  margin-right: 4px;
}
vb-webcast-question-card .question-mod-buttons {
  margin: 0;
}
vb-webcast-question-card .question-mod-buttons button {
  border: none;
  width: 100%;
}
vb-webcast-question-card .question-mod-buttons button .label {
  font-size: 0.875rem;
  padding: 4px 0;
  position: relative;
  top: -1px;
}
@media all and (max-width: 480px) {
  vb-webcast-question-card .question-mod-buttons button .label {
    display: block;
  }
}
vb-webcast-question-card .closedQueueStatusDropdown {
  position: absolute;
  top: 2px;
  right: 10px;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-toggle {
  padding: 2px 10px !important;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-toggle .more-icon {
  width: auto;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-toggle .more-icon .circle {
  display: block;
  width: 3px;
  height: 3px;
  margin-bottom: 2px;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-menu {
  border-radius: 3px !important;
  top: 20px;
  left: auto;
  right: 0;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-menu li {
  border-bottom: none;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-menu li > a {
  padding: 8px 16px 8px 16px;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-menu li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-menu .glyphicon,
vb-webcast-question-card .closedQueueStatusDropdown .dropdown-menu .glyphicons {
  color: #000;
  margin-right: 8px;
}
vb-webcast-question-card.direct-reply-in-progress > span:not(.question-status),
vb-webcast-question-card.direct-reply-in-progress .dropdown-toggle,
vb-webcast-question-card.direct-reply-in-progress .question-mod-buttons {
  opacity: 0.4;
}
vb-webcast-question-card.direct-reply-in-progress .question-reply-inprogress {
  font-style: italic;
  font-weight: normal;
  white-space: normal;
}
vb-webcast-question-card .options {
  min-height: 25px;
}
vb-webcast-question-card .question-mod-buttons {
  margin: 0;
  padding: 2px;
}
.webcast-manage-questions-full-screen vb-webcast-question-card .inbox-view .question-qtext {
  font-size: 0.875rem;
  margin-bottom: 0;
}
.webcast-manage-questions-full-screen vb-webcast-question-card .queue-view .question-qtext {
  font-size: 1rem;
}
vb-webcast-question-card .drag-handle {
  display: none;
}
.draggable vb-webcast-question-card .drag-handle {
  display: block;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 0;
  right: 5px;
  padding: 0;
  cursor: default;
  cursor: hand;
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
.draggable vb-webcast-question-card .drag-handle .glyphicons {
  position: absolute;
  top: 4px;
  right: 9px;
}
@media (max-width: 992px) {
  .draggable vb-webcast-question-card .drag-handle {
    left: auto;
    bottom: auto;
  }
}
@media (max-width: 1200px) {
  vb-webcast-question-card .question-number,
  vb-webcast-question-card .width-full h5.question-qtext {
    font-size: 1.125rem;
  }
}
[flex="webcast-qa-card-viewer"] {
  -webkit-flex: 0 1 85%;
  flex: 0 1 85%;
}
[flex="webcast-qa-card-moderator"] {
  -webkit-flex: 0 1 90%;
  flex: 0 1 90%;
}
