@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

tags-input {
	position: relative;
	.WordBreak;

	.tags-icon-plus {
		.RoundedShape(10px);
		font-size: 14px;
		pointer-events: none;
		position: absolute;
		right: 5px;
		top: 6px;
	}

	.tags {
		background-color: @white;
		border-style: solid;
		border-width: 1px;
		.RoundedShape(4px);
		padding-left: 8px;
		padding-right: 24px;
		.BoxShadow(none);

		.input {
			background-color: transparent;
			color: @color1;
			font: @type14 'theme-font', Helvetica Neue, Arial, sans-serif;
			height: 32px;
			max-width: 100% !important;
			padding-left: 0;
			width: 100% !important;

			.Placeholder({
				color: @color1Lighten50;
			});
		}

		&:not(.focused) {
			border-color: @lightBorder;
		}

		&.focused {
			border-width: 3px;
			.BoxShadow(inset 0px 0px 5px 0px @blackFade25);
		}
	}

	&.ng-invalid .tags {
		.BoxShadow(none);
	}

	&.ng-invalid:not(.ng-untouched) .tags {
		border: @invalidFieldBorder;
	}

	&.tags-input-has-items {
		.tags:not(.focused) .input.ng-untouched {
			opacity: 0; //workaround for placeholder styling below being ignored initially in Chrome
		}

		.input { //hide the placeholder when it's populated with a tag selection
			&::-webkit-input-placeholder {
				color: transparent !important;
			}

			&::-moz-placeholder {
				color: transparent;
			}

			&:-ms-input-placeholder {
				color: transparent;
			}
		}
	}

	.autocomplete {
		.BoxShadow(none);
		padding: 0;

		.suggestion-item {
			color: @primaryDark;
			font-family: inherit;
			padding: 5px 16px;
		}
	}

	.category-item,
	.category-autocomplete-item {
		font-size: 13px;

		> span,
		> small {
			display: block;
			padding-right: .5rem;
		}

		> span {
			font-weight: bold;
			line-height: 1.4;
			margin-bottom: 2px;
		}

		> small {
			width: 100%;
			line-height: 12px;
		}
	}

	.tags .tag-item {
		background: transparent;
		border: 1px solid @lightBorder;
		font-family: 'theme-font';
		height: auto !important;
		position: relative;
		padding: .5rem 2.5rem .5rem .75rem;

		&.selected {
			.BoxShadow(0 0 10px @color1Fade75);
		}

		.remove-button {
			position: absolute;
			top: 0;
			right: 0;
			width: 30px;
			color: inherit;
			text-align: center;
			height: 100%;
			border-left: 1px solid rgba(0,90,130,0.1);
			font-size: 11px;
			padding-top: 12px;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
