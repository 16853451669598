.error {
  color: #c41616;
}
.unavailable {
  color: #999999;
}
.caution {
  color: #ff8015;
}
.normal {
  color: #009b01;
}
.vbIcon {
  composes: vb-icon from global;
  line-height: inherit !important;
}
.iconPosition {
  margin-right: 2px;
  top: 2px;
}
.highlight {
  background-color: #ffff00;
  color: #000;
}
.statusDropdown {
  composes: dropdown-menu from global;
  position: absolute;
  left: -5vw;
  padding: 0;
  margin-top: 8px;
  background: #fff;
  min-width: 225px;
  border: 1px solid #cbcbcb;
  border-radius: 3px;
  font-size: 0.875rem;
}
.statusDropdown:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #cbcbcb transparent;
  position: absolute;
  left: 18px;
  top: -10px;
}
.statusDropdown:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  left: 20px;
  top: -8px;
}
.statusMetric {
  composes: dropdown-item from global;
  display: block;
  padding: 10px 5px;
  line-height: 1;
}
.statusMetric:not(:first-child) {
  border-top: 1px solid #f1f1f1;
}
.openChevron {
  transform: rotate(90deg);
}
