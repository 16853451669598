@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@borderStyle: 1px solid @blackFade10;
@width: (@viewPortMedWidth);

.logo {
	position: absolute;
	top: 10px;
	left: 30px;
}

.formContainer {
	position: relative;
	.isPreview & {
		padding-top: 0;
	}
	@media (min-width: @width) {
		padding-top: 100px;
	}
}

.form {
	margin: 0 auto;
	padding: 30px;
	width: 100%;
	.RoundedShape(0px);
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
	background: linear-gradient(to bottom, #f7f7f7, #e9e9e9);
	color: @blackFade50;
	text-shadow: 0 1px 0 @whiteFade75;
	line-height: normal;
	min-height: 100vh;

	@media (min-width: @width) {
		min-height: auto;
		width: @width;
		.RoundedShape();

		.isPreview & {
			margin: 0;
			width: auto;
		}
	}

	h1 {
		font-size: @type26;
		font-weight: normal;
	}
	h2 {
		font-size: @type24;
		font-weight: normal;
	}
	header {
		padding-bottom:10px;
		border-bottom: @borderStyle;
		margin-bottom: 10px;
	}
}

.main {
	display: flex;
	flex-direction: row;

	@media (max-width: (@width - 1px)) {
		display: block;
	}
}

.left {
	flex: 0.33;
}
.right {
	flex: 0.66;
	margin-top: 10px;
	border-top: @borderStyle;

	@media (min-width: @width) {
		margin-top: 0;
		border-top-width: 0;
		border-left: @borderStyle;
		padding-left: 25px;
	}
}

.small {
	font-size: @type14;
}

.previewWindow {
	@media (min-width: @width) {
		width: 900px;
	}
}

.previewBanner {
	composes: theme-accent from global;

	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px;
	text-transform: uppercase;
}

.closeBtn {
	color: @white !important;
	opacity: 1 !important;
}
