@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.editWebcastFormSidebar {
	padding-right: 0 !important;
	padding-left: 0 !important;

	[class^="col-"],
	[class^="control-"] {
		width: 100% !important;
	}
	[ng-transclude-slot=control] {
		width: 75% !important;
	}
	[validation="required"],
	label[validation] {
		width: 90% !important;
	}
	:global(.required > .control-label:after) {
		content: "*";
		color: #af0000;
		position: absolute;
		right: auto;
		top: 10px;
		display: inline-block;
		margin: 0 0 0 5px;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		vertical-align: middle;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
	}
	:global(.required-field:before) {
		display: none;
	}
}

.editWebcastForm {
	display: block;
	padding: 15px 15px 0 15px;
	[insight] {
		border: 1px solid @borderColor;
		.RoundedShape(5px);
	}

	select[disabled] {
		margin-left: -8px;
		background: none;
		border: none;
		.BoxShadow(none);
	}

	vb-ui-toolbar {
		padding-right: 15px;
	}

	//TODO: should be handled at vb-ui-form-group-level..
	//Need more design discussion here..
	vb-ui-category-settings, vb-ui-tags-settings {
		vb-ui-form-group {
			.displayFlex();

			@media only screen and (max-width: @viewPortMobileDeviceWidth) {
				.flexWrap(wrap);
			}

			@media all and (max-width: @viewPortSmallWidth) {
				.flexWrap(wrap);
				padding-left: 16px;
				padding-right: 16px;
				margin: 10px 0;
			}

			div[ng-transclude-slot='label'] {
				width: 25.5%;
				.alignSelf(center);
				color: @color1LightText;
				font-weight: normal;

				@media only screen and (max-width: @viewPortMobileDeviceWidth) {
					width: 100%;
				}

				@media all and (max-width: @viewPortSmallWidth) {
					> label {
						margin-bottom: 0 !important;
					}
				}
			}

			div[ng-transclude-slot='control'] {
				width: 36%;

				@media only screen and (max-width: @viewPortMobileDeviceWidth) {
					width: 100%;
				}

				@media all and (max-width: @viewPortSmallWidth) {
					display: block !important;
					width: 100% !important;
				}
			}
		}
	}

	.presentationStatus {
		position: absolute;
		top: 0;
		right: 36px;
		bottom: 0;
		left: 0;
		z-index: 1;
		margin-bottom: 0;
		.RoundedLeftShape(3px);
		padding-bottom: 0;
		padding-top: 0;
		line-height: 36px;

		&.fullWidth {
			right: 0;
		}

	}

	.selectedTeam {
		color: @color1LightText;
		font-size: @type14;
	}

	.radioGroup {
		line-height: @type20;
		padding-left: 20px;
	}
	.radioGroup label {
		padding-left: 0;
	}


	.checkLabel,
	.radioGroup label,
	:global(.selected-ppt-file){
		color: @color1;
	}

	.presentationDownloadWrapper {
		margin-top: -@type32;

		>input {
			margin-right: 0;
		}

		.editWebcastFormSidebar& {
			margin-top: 0;
		}

		@media(max-width: @viewPortSmallWidth) {
			margin-top: 0;
		}
	}

	.presentationFileWrapper {
		margin-right: @type8;
		margin-top: 10px;
	}
}

edit-webcast {
	display: block;
	position: relative;
	min-height: 100vh;

	overflow: auto;
	// hide scrollbars for overflow issues for the color-selector pop-ups
	-ms-overflow-style: none; // ei11
	scrollbar-width: none; // firefox

	image-selector input {
		width: 100% !important
	}

	&.sidebarLayout {
		padding: 1px;
	}
}

// chrome overflow
edit-webcast::-webkit-scrollbar {
	display: none;
}

.toolbarWrap {
	.displayFlex();
}

.shortcutCalendar {
	position: absolute;
	top: 0;
	right: -13px;
}

.helpBlock {
	composes: help-block from global;
	margin-top: 10px;
}

.errorBlock {
	composes: helpBlock;
	color: @warningColor !important;
}

.noZIndex {
	&:global(.vb-input-field) {
		:global(.input-group .form-control),
		:global(.input-group .btn) {
			z-index: 0;
		}
	}
}