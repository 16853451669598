@import (reference) "~rev-less/global/variables";

.menuWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menuItemWrapper {
    &:hover {
        background-color: @color2Lighten04;
    }
}

.menuItem {
    display: block;
    padding: 3px 20px;
    line-height: 1.4;
    color: @color1;
    white-space: nowrap;

    &:hover {
        color: @color1;
        text-decoration: none;
    }
}