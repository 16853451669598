.importWebexGridlist vb-grid-row-collection {
  height: 60vh;
}
.importWebexGridlist vb-grid-row-collection vb-grid-list-row vb-grid-list-column {
  font-size: 0.75rem;
}
.importWebexGridlist vb-grid-row-collection vb-grid-list-row vb-grid-list-column:nth-child(2) {
  font-weight: bold;
  font-size: 0.875rem;
}
