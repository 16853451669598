@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/events/webcast";

@rootVerticalPadding: 4px;
.root {
	composes: container-fluid from global;

	border-bottom-width: 1px;
	border-bottom-style: solid;
	display: block;
	padding-bottom: @rootVerticalPadding;
	padding-top: @rootVerticalPadding;
}

.wrapper {
	min-height: 38px;
}

.title {
	&:extend(.TextClip);
	font-weight: 700;
	margin: 0;
}

.webcastButtons {
	max-width: 100%;

	> * {
		margin-left: 8px;
	}
}

.webcastNavBtn {
	composes: vbIconBtn from '~rev-shared/ui/buttons/vb-btn.module.less';
	line-height: 36px;
	padding: 0 12px;
	&:focus {
		color: inherit !important;
	}
}

.vcRecordingStatus {
	color: @warningColor;
	font-size: @type14;
}

@media(max-width: (@viewPortMedWidth - 1px)) {
	.webcastNavToggleViewBtn {
		display: none;
	}
}

@media (max-width: @viewPortMobileDeviceWidth) {
	.webcastButtons {
		text-align: right;
	}

	.webcastRegionVisibilityToggle {
		float: left;
		min-width: 116px;
	}

	.webcastStateControls {
		margin-top: .25rem;
	}
}
