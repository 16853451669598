@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.tabWrapper {
	min-height: 62vh;
}

.tabPanel {
	padding: 1rem;
}

.runInfo {
	margin-left: auto;
	font-weight: bold;
	font-size: @type18
}

.menuDropDownContent {
	min-width: 80px !important;
}

.menuDropdown {
	background: @white;
	list-style: none;
	padding: 0;
	margin: 0;
}

.menuItem {
	padding: 5px 10px;

	&:hover {
		background: @backgroundLighten03;
	}
}

.menuItemLink {
	color: @color1LightText !important;
	display: block;
	text-decoration: none;
	white-space: nowrap;
	text-align: left;

	&:hover {
		text-decoration: none;
	}
}

.userEngagedIcon {
	&:global(.vb-icon-user-engaged):before {
		font-size: @type16;
	}
}

@media screen and (max-width: @viewPortMedWidth - 1px) {
	.runtime {
		display: block;
		padding-top: 3px;
	}
	.runInfo {
		font-size: @type14;
	}
}

.mediumChartWrapper {
	.flexItem(1 1 45%);

	@media screen and (max-width: @viewPortMedWidth - 1px) {
		.flexItem(1 1 100%);

		&:not(:last-child) {
			margin-right: 0 !important;
		}

	}
}

.mediumChartSize {
	width: 100%;
	height: 320px;
}

