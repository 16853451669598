help-menu,
help-menu-mobile {
  display: block;
}
help-menu .help-tray,
help-menu-mobile .help-tray {
  padding: 5px 0 !important;
}
help-menu .help-nav,
help-menu-mobile .help-nav {
  margin: 0;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
}
help-menu .help-nav-link,
help-menu-mobile .help-nav-link {
  margin: 0;
  /* override specificity from .navbar */
  padding: 10px 12px !important;
}
help-menu .help-nav-link:hover,
help-menu-mobile .help-nav-link:hover {
  text-decoration: underline !important;
}
help-menu .help-nav .divider,
help-menu-mobile .help-nav .divider {
  margin: 0;
}
