portal-dashboard {
  display: block;
  height: 100%;
}
portal-dashboard .dashboard.feature {
  padding-top: 8px;
}
portal-dashboard .dashboard .greeked-text {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  min-width: 150px;
  display: inline-block;
  height: 10px;
}
h3 portal-dashboard .dashboard .greeked-text {
  height: 13px;
  width: 75%;
}
.table-item h3 portal-dashboard .dashboard .greeked-text {
  margin-top: 17px;
}
h4 portal-dashboard .dashboard .greeked-text {
  height: 10px;
  min-width: 75px;
  width: 50%;
  line-height: 1.2;
}
portal-dashboard .dashboard .anchored-thumbnail {
  width: 100%;
}
portal-dashboard .dashboard .fill-thumbnail {
  max-height: 100%;
  object-fit: cover;
}
portal-dashboard .dashboard .greeked-image {
  background: rgba(0, 0, 0, 0.1) !important;
}
portal-dashboard .stop-button {
  float: left;
}
@media (min-width: 768px) {
  portal-dashboard .dashboard.feature {
    border: none;
    margin: 0;
    padding: 0;
  }
  portal-dashboard .dashboard > div:last-child .carousel-rows {
    border-bottom: none;
  }
}
