featured-video-player-carousel .overlay-play {
  position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}
featured-video-player-carousel {
  display: block;
}
featured-video-player-carousel vb-flickity-carousel {
  padding-top: 30px;
}
.transparent-header featured-video-player-carousel vb-flickity-carousel {
  padding-top: 100px;
}
featured-video-player-carousel .carousel-cell {
  width: 100%;
  padding: 0;
}
@media (min-width: 992px) {
  featured-video-player-carousel .carousel-cell {
    padding: 0 150px;
  }
}
@media (min-width: 1400px) {
  featured-video-player-carousel .carousel-cell {
    padding: 0 300px;
  }
}
featured-video-player-carousel .flickity-prev-next-button {
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
featured-video-player-carousel .flickity-prev-next-button.previous {
  left: 20px;
}
featured-video-player-carousel .flickity-prev-next-button.next {
  right: 20px;
}
featured-video-player-carousel .flickity-page-dots {
  bottom: -33px;
}
featured-video-player-carousel .flickity-page-dots .dot {
  border-width: 1px;
  border-style: solid;
}
featured-video-player-carousel .fixed-ratio {
  box-shadow: 0 0 13px 5px rgba(0, 0, 0, 0.5);
}
featured-video-player-carousel .slide-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  z-index: 50;
  max-width: 100%;
  width: 100%;
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
featured-video-player-carousel .slide-info-overlay h1 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  text-align: center;
}
featured-video-player-carousel .slide-info-overlay:hover {
  cursor: pointer;
}
featured-video-player-carousel .slide-info-overlay:hover h1 a {
  color: inherit;
}
featured-video-player-carousel .slide-info-overlay:hover h1 a:hover {
  border-bottom: none;
  text-decoration: none;
}
featured-video-player-carousel .slide-info-overlay h1 {
  margin: 8px 16px;
  position: relative;
}
featured-video-player-carousel .slide-info-overlay h1 a {
  color: #fff;
  position: relative;
  padding-right: 24px;
  display: inline-block;
  width: auto;
  max-width: 100%;
  line-height: 1.2;
  font-weight: normal;
}
featured-video-player-carousel .slide-info-overlay h1 a .glyphicons {
  font: 1rem 'Glyphicons Regular';
  color: rgba(255, 255, 255, 0.5);
  top: 0;
  margin: 0 4px;
}
featured-video-player-carousel .overlay-play {
  text-align: center;
  z-index: 10;
  cursor: pointer;
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
featured-video-player-carousel .overlay-play video-360-indicator {
  position: absolute;
  right: 16px;
  top: 2.75rem;
}
featured-video-player-carousel .overlay-play .circle {
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  height: 50px;
  width: 50px;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.5);
  background: rgba(48, 48, 48, 0.9);
  -webkit-transition: all linear 0.25s;
  transition: all linear 0.25s;
}
featured-video-player-carousel .overlay-play .glyphicons {
  margin-top: 9px;
  margin-left: 2px;
}
@media (max-width: 768px) {
  featured-video-player-carousel {
    background: #ebebeb;
    border-bottom: 1px solid #cbcbcb;
  }
  featured-video-player-carousel vb-flickity-carousel {
    padding: 0 0 48px;
  }
  .transparent-header featured-video-player-carousel vb-flickity-carousel {
    padding-top: 56px;
  }
  featured-video-player-carousel .flickity-page-dots {
    position: absolute;
    left: 60px;
    right: 60px;
    bottom: -30px;
    margin: auto;
    width: auto;
  }
  featured-video-player-carousel .flickity-prev-next-button {
    top: auto;
    bottom: -68px;
    background: transparent;
  }
}
