.editWebcastFormSidebar {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.editWebcastFormSidebar [class^="col-"],
.editWebcastFormSidebar [class^="control-"] {
  width: 100% !important;
}
.editWebcastFormSidebar [ng-transclude-slot=control] {
  width: 75% !important;
}
.editWebcastFormSidebar [validation="required"],
.editWebcastFormSidebar label[validation] {
  width: 90% !important;
}
.editWebcastFormSidebar :global(.required > .control-label:after) {
  content: "*";
  color: #af0000;
  position: absolute;
  right: auto;
  top: 10px;
  display: inline-block;
  margin: 0 0 0 5px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  vertical-align: middle;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.editWebcastFormSidebar :global(.required-field:before) {
  display: none;
}
.editWebcastForm {
  display: block;
  padding: 15px 15px 0 15px;
}
.editWebcastForm [insight] {
  border: 1px solid #cbcbcb;
  border-radius: 5px;
}
.editWebcastForm select[disabled] {
  margin-left: -8px;
  background: none;
  border: none;
  box-shadow: none;
}
.editWebcastForm vb-ui-toolbar {
  padding-right: 15px;
}
.editWebcastForm vb-ui-category-settings vb-ui-form-group,
.editWebcastForm vb-ui-tags-settings vb-ui-form-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
@media only screen and (max-width: 505px) {
  .editWebcastForm vb-ui-category-settings vb-ui-form-group,
  .editWebcastForm vb-ui-tags-settings vb-ui-form-group {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media all and (max-width: 768px) {
  .editWebcastForm vb-ui-category-settings vb-ui-form-group,
  .editWebcastForm vb-ui-tags-settings vb-ui-form-group {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 16px;
    padding-right: 16px;
    margin: 10px 0;
  }
}
.editWebcastForm vb-ui-category-settings vb-ui-form-group div[ng-transclude-slot='label'],
.editWebcastForm vb-ui-tags-settings vb-ui-form-group div[ng-transclude-slot='label'] {
  width: 25.5%;
  -webkit-align-self: center;
  align-self: center;
  color: #898989;
  font-weight: normal;
}
@media only screen and (max-width: 505px) {
  .editWebcastForm vb-ui-category-settings vb-ui-form-group div[ng-transclude-slot='label'],
  .editWebcastForm vb-ui-tags-settings vb-ui-form-group div[ng-transclude-slot='label'] {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .editWebcastForm vb-ui-category-settings vb-ui-form-group div[ng-transclude-slot='label'] > label,
  .editWebcastForm vb-ui-tags-settings vb-ui-form-group div[ng-transclude-slot='label'] > label {
    margin-bottom: 0 !important;
  }
}
.editWebcastForm vb-ui-category-settings vb-ui-form-group div[ng-transclude-slot='control'],
.editWebcastForm vb-ui-tags-settings vb-ui-form-group div[ng-transclude-slot='control'] {
  width: 36%;
}
@media only screen and (max-width: 505px) {
  .editWebcastForm vb-ui-category-settings vb-ui-form-group div[ng-transclude-slot='control'],
  .editWebcastForm vb-ui-tags-settings vb-ui-form-group div[ng-transclude-slot='control'] {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  .editWebcastForm vb-ui-category-settings vb-ui-form-group div[ng-transclude-slot='control'],
  .editWebcastForm vb-ui-tags-settings vb-ui-form-group div[ng-transclude-slot='control'] {
    display: block !important;
    width: 100% !important;
  }
}
.editWebcastForm .presentationStatus {
  position: absolute;
  top: 0;
  right: 36px;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin-bottom: 0;
  border-radius: 3px 0 0 3px;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 36px;
}
.editWebcastForm .presentationStatus.fullWidth {
  right: 0;
}
.editWebcastForm .selectedTeam {
  color: #898989;
  font-size: 0.875rem;
}
.editWebcastForm .radioGroup {
  line-height: 1.25rem;
  padding-left: 20px;
}
.editWebcastForm .radioGroup label {
  padding-left: 0;
}
.editWebcastForm .checkLabel,
.editWebcastForm .radioGroup label,
.editWebcastForm :global(.selected-ppt-file) {
  color: #303030;
}
.editWebcastForm .presentationDownloadWrapper {
  margin-top: -2rem;
}
.editWebcastForm .presentationDownloadWrapper > input {
  margin-right: 0;
}
.editWebcastFormSidebar.editWebcastForm .presentationDownloadWrapper {
  margin-top: 0;
}
@media (max-width: 768px) {
  .editWebcastForm .presentationDownloadWrapper {
    margin-top: 0;
  }
}
.editWebcastForm .presentationFileWrapper {
  margin-right: 0.5rem;
  margin-top: 10px;
}
edit-webcast {
  display: block;
  position: relative;
  min-height: 100vh;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
edit-webcast image-selector input {
  width: 100% !important;
}
edit-webcast.sidebarLayout {
  padding: 1px;
}
edit-webcast::-webkit-scrollbar {
  display: none;
}
.toolbarWrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
.shortcutCalendar {
  position: absolute;
  top: 0;
  right: -13px;
}
.helpBlock {
  composes: help-block from global;
  margin-top: 10px;
}
.errorBlock {
  composes: helpBlock;
  color: #c41616 !important;
}
.noZIndex:global(.vb-input-field) :global(.input-group .form-control),
.noZIndex:global(.vb-input-field) :global(.input-group .btn) {
  z-index: 0;
}
