@import "~rev-less/global/variables";

help-menu, help-menu-mobile {
	display: block;

	.help-tray {
		padding: 5px 0 !important;
	}
	.help-nav {
		margin: 0;
		padding: 0;
		max-height: 300px;
		overflow-y: auto;
		&-link {
			margin: 0;
			/* override specificity from .navbar */
			padding: 10px 12px !important;
			&:hover {
				text-decoration: underline !important;
			}
		}
		.divider {
			margin: 0;
		}
	}
}

