.root {
  display: flex;
}
.openQuestionWrapper {
  cursor: pointer;
}
.input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
  width: 75%;
  display: inline-block;
  flex: 1 !important;
}
.submitBtn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.form {
  width: 100%;
  padding: 10px;
  border-width: 2px;
  border-style: solid;
  -webkit-transition: display linear 0.5s;
  transition: display linear 0.5s;
}
.form .textArea:focus {
  border: 3px solid rgba(0, 0, 0, 0.75);
}
.textArea {
  border: 0;
}
.anonymousChkBox {
  display: inline-block;
  margin-top: 8px;
}
