.form {
  padding: 10px 20px;
}
.form vb-ui-form-group {
  display: block;
  margin-bottom: 10px;
}
.columnContainer {
  display: flex;
  justify-content: stretch;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin-top: 50px;
}
@media all and (max-width: 1199px) {
  .columnContainer {
    margin-top: 0;
    padding: 0 25px;
  }
}
.columnLeft {
  padding-right: 2rem;
  border-width: 0 1px 0 0;
  border-style: solid;
}
.columnRight {
  padding-left: 2rem;
}
.profilePicture img {
  max-height: 200px !important;
}
.loadingSpinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media all and (max-width: 1199px) {
  .columnContainer {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .columnLeft,
  .columnRight {
    flex: 100%;
  }
  .columnLeft {
    border-width: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .columnRight {
    margin-top: 30px;
    padding-left: 0;
  }
}
