.eventIcon {
  composes: theme-accent from global;
  border: 1px solid transparent;
  border-radius: 30px;
  padding: 10px;
  font-size: 2rem;
}
.eventTitle {
  padding: 10px;
  margin: 0 auto;
}
.eventTitleLink,
.eventTitleLink:hover {
  color: rgba(34, 41, 48, 0.75);
}
.eventTitleLink {
  composes: TextClip from global;
  font-size: 1.25rem;
  font-weight: 500;
  display: block;
  line-height: 1.2;
}
@media all and (min-width: 1200px) {
  .eventTitleLink {
    min-width: 180px;
  }
}
.eventDate {
  font-size: 0.875rem;
  padding: 10px;
  padding-top: 0;
  color: rgba(48, 48, 48, 0.5);
  margin: 0 auto;
}
.eventDescription {
  margin: 0 auto;
  color: rgba(48, 48, 48, 0.5);
  max-height: 45px;
  word-break: break-word;
  overflow: hidden;
}
