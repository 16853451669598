@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

vb-webcast-questions-list {
	display: block;

	.webcast-questions-list {
		.card-holder {
			border-bottom-width: 1px;
			border-bottom-style: solid;
		}

		.manage-card-holder {
			border-bottom-width: 0;
		}
	}

	.ui-sortable-placeholder {
		height: 100px;
	}

	.noQuestions {
		text-align: center;
		margin-top: 20px;
	}
}
