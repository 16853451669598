@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.admin-movable-item {
	margin: 0;
	.RoundedShape(3px);
	background: @white;

	vertical-align: middle;
	font-weight: bold;

	&:hover {
		cursor: default;
		cursor: pointer;
		cursor: hand;
	}

	.hover-wrap {
		padding: 2px 2px 2px 6px;
		.RoundedShape(3px);
		border: none;
		.BoxShadow(0 1px 0 fade(@black, 20%), 0 0 2px @blackFade10, inset 0 1px 0 @white;);
		background: #f0f0f0;
		line-height: 2;
		word-break: break-all;

		&:hover {
			border-color: rgba(0,85,128,0.25);
			.BoxShadow(0 0 1px rgba(0,85,128,0.35));
			background: rgb(243,251,255);

			.write-access .glyphicons {
				color: @color1;
			}

			.available-items & > .btn-assigned-item,
			.selected-items  & > .btn-remove {
				display: inline-block;
				opacity: 1;
			}

			.available-items & > .btn-remove,
			.selected-items  & > .btn-assigned-item{
				display: none;
				opacity: 0;
			}
		}

		&:not(:last-child):not(:first-child) {
			.RoundedShape(0);
		}

		&:first-child:not(:last-child) {
			.BorderTopRightRadius(0);
			.BorderBottomRightRadius(0);

			.available-items & {
				.RoundedShape(3px);
			}
		}

		&:last-child:not(:first-child) {
			.BorderTopLeftRadius(0);
			.BorderBottomLeftRadius(0);
		}

		.icon-right(@size) {
			background-repeat: no-repeat;
			background-position: right center;
			padding-right: 100px !important;
			background-size: @size;
			background-position: 99%;
		}

		&.icon-could-stream {
			background-image: url(/img/cloud-stream.png);
			.icon-right(9%);
		}

		&.icon-vbrick-multicast-stream {
			background-image: url(/img/vbrick-multicast-stream.png);
			.icon-right(7%);
		}

		&.icon-unicast-stream {
			background-image: url(/img/unicast-stream.png);
			.icon-right(7%);
		}

		&.icon-multicast-stream {
			background-image: url(/img/multicast-stream.png);
			.icon-right(10%);
		}

		&.icon-reflection-stream {
			background-image: url(/img/reflection-stream.png);
			.icon-right(10%);
		}
	}

	.write-access .glyphicons {
		top: 2px;
	}

	&.sub-dir {
		margin-left: 40px;
	}

	&.locked {
		&,
		.lock {
			color: #aaa;
		}

		&:hover {
			background: #f2f2f2;
			cursor: default;
		}
	}

	.btn{
		margin-right: 5px;
		padding: 2px 4px;

	}
}

@media (max-width: 600px){
	.admin-movable-item .btn {
		min-height: 0;
		font-size: 14px;
	}
}

.btn-movable-selector {
	background: none;
	border:1px solid @blackFade10;
	padding: 6px 8px;
	.BoxShadow(inset 0 0 1px @white);

	.available-items & {
		&.btn-assigned-item {
			opacity: 0;
			display: none;
		}

		&.btn-remove {
			opacity: 1;
			display: inline-block;
		}
	}

	.selected-items & {
		&.btn-remove {
			opacity: 0;
			display: none;
		}

		&.btn-assigned-item {
			opacity: 1;
			display: inline-block;
			background: fade(@accentColor, 15%);
			border-color: fade(@black, 15%);
			color: rgb(0, 85, 128);
			.BoxShadow(inset 0 -1px 6px @accentFade20);
		}
	}

	.admin .combined-toolbar .btn& {
		padding: 6px 8px;
	}
}
