legal-hold-sidebar .dropdown-menu {
  border: transparent;
  box-shadow: none;
}
legal-hold-sidebar .legalhold .sidebar-dropdown {
  right: 51px;
  top: 10px;
}
@media all and (max-width: 992px) {
  legal-hold-sidebar .legalhold .sidebar-dropdown:before,
  legal-hold-sidebar .legalhold .sidebar-dropdown:after {
    margin-top: -9px;
    margin-left: 0;
  }
}
legal-hold-sidebar .sidebar-dropdown {
  background-color: #fff;
  border: 1px solid #303030;
  border-radius: 4px;
  padding: 10px 35px;
  position: absolute;
  right: 51px;
  top: 12px;
  width: auto;
}
@media all and (max-width: 992px) {
  legal-hold-sidebar .sidebar-dropdown {
    right: 8px !important;
    top: -54px !important;
  }
}
legal-hold-sidebar .sidebar-dropdown:after,
legal-hold-sidebar .sidebar-dropdown:before {
  left: 100%;
  top: 25%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
@media all and (max-width: 992px) {
  legal-hold-sidebar .sidebar-dropdown:after,
  legal-hold-sidebar .sidebar-dropdown:before {
    left: 80%;
    top: 113%;
  }
}
legal-hold-sidebar .sidebar-dropdown:before {
  border-left-color: #303030;
  border-width: 13px;
  margin-top: -1px;
  margin-left: 0;
}
@media all and (max-width: 992px) {
  legal-hold-sidebar .sidebar-dropdown:before {
    border-left-color: transparent;
    border-top-color: #303030;
    margin-top: -8px;
  }
}
legal-hold-sidebar .sidebar-dropdown:after {
  border-color: rgba(248, 248, 248, 0);
  border-left-color: #fff;
  border-width: 12px;
  margin-top: -1px;
  margin-left: -1px;
}
@media all and (max-width: 992px) {
  legal-hold-sidebar .sidebar-dropdown:after {
    border-top-color: #fff;
    border-left-color: transparent;
    margin-top: -8px;
    margin-left: 0;
  }
}
legal-hold-sidebar .sidebar-menu {
  top: -12px;
}
legal-hold-sidebar .menu-link {
  color: #222930 !important;
  font-size: 0.875rem !important;
  line-height: 1.4 !important;
  padding: 5px !important;
  text-align: left;
}
legal-hold-sidebar .dropdown-menu {
  background-color: transparent;
}
