@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.error {
	color: @warningColor;
}

.unavailable {
	color: @gray;
}

.caution {
	color: @alertColor;
}

.normal {
	color: @green;
}

.vbIcon {
	composes: vb-icon from global;
	line-height: inherit !important;
}

.iconPosition {
	margin-right: 2px;
	top: 2px;
}

.highlight {
	background-color: @highlight;
	color: @black;
}

.statusDropdown {
	composes: dropdown-menu from global;
	position: absolute;
	left: -5vw;
	padding: 0;
	margin-top: 8px;
	background: @white;
	min-width: 225px;
	border: 1px solid @borderColor;
	border-radius: 3px;
	font-size: @type14;

	&:before {
		content: '';
		width: 0;
		height: 0;
		.TriangleTop(10px, @borderColor);
		position: absolute;
		left: 18px;
		top: -10px;
	}

	&:after {
		content: '';
		width: 0;
		height: 0;
		.TriangleTop(8px, @white);
		position: absolute;
		left: 20px;
		top: -8px;
	}
}

.statusMetric {
	composes: dropdown-item from global;
	display: block;
	padding: 10px 5px;
	line-height: 1;

	&:not(:first-child) {
		border-top: 1px solid @borderColorLight15;
	}
}

.openChevron {
	transform: rotate(90deg);
}
