.menuWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menuItemWrapper:hover {
  background-color: #f5f5f5;
}
.menuItem {
  display: block;
  padding: 3px 20px;
  line-height: 1.4;
  color: #303030;
  white-space: nowrap;
}
.menuItem:hover {
  color: #303030;
  text-decoration: none;
}
