vb-checkbox {
  display: inline-block;
  vertical-align: middle;
}
vb-checkbox .labelWrapper {
  word-break: break-word;
}
vb-checkbox button,
vb-checkbox .vb-checkbox-label {
  font-size: 0.875rem;
}
vb-checkbox button[disabled],
vb-checkbox .vb-checkbox-label[disabled] {
  cursor: not-allowed;
}
vb-checkbox button {
  border-radius: 4px;
  border: 1px solid #909090;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  overflow: hidden;
  padding: 2px;
  position: relative;
  height: 18px;
  width: 18px;
}
vb-checkbox button:not(.active) {
  background-color: #fff;
}
vb-checkbox button:not(.active) .glyphicons {
  visibility: hidden;
}
vb-checkbox button .glyphicons {
  vertical-align: top !important;
}
vb-checkbox button[disabled] {
  opacity: 0.5;
}
vb-checkbox.large button {
  font-size: 1rem;
  width: 24px;
  height: 24px;
}
