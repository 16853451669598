media-menu {
  color: #303030;
  text-transform: initial;
}
media-menu.dropdown-menu {
  border: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  left: 21px !important;
  padding: 0;
  position: absolute;
  top: 52px !important;
  z-index: 1031;
}
media-menu > section {
  border-style: solid;
  border-width: 1px;
  display: flex;
  min-height: 180px;
  right: -25px;
  top: 10px;
  z-index: 1032;
}
media-menu .media-videos {
  flex: 0 0 20%;
  min-width: 265px;
  padding: 1em 3em 1em 3em;
}
media-menu .media-teams {
  min-width: 40vw;
  padding: 1em 3em;
  position: relative;
}
media-menu .no-teams {
  margin-top: 2em;
  text-align: center;
}
media-menu .media-box-header {
  display: inline-block;
  font-size: 150%;
  font-weight: normal;
  margin: 0;
}
media-menu .media-box-list {
  list-style: none;
  padding: 1em 0 0 0;
}
media-menu .media-box-list > li:last-child .media-video-item {
  border-bottom: 0;
}
media-menu .media-team-links-wrapper {
  padding: 1em 0 0 0;
  vertical-align: top;
  height: 180px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
media-menu .media-video-item {
  border-bottom: 1px solid #b0b0b0;
  color: #303030;
  display: block;
  padding: 0.75em 0;
}
media-menu .media-video-item.active,
media-menu .media-video-item:hover {
  color: #00a0f0;
}
media-menu .media-video-item .text-label {
  font-size: 1rem !important;
}
media-menu .media-team-link {
  font-size: 110%;
  display: block;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  -webkit-hyphens: auto !important;
  -moz-hyphens: auto !important;
  -ms-hyphens: auto !important;
  height: 35px;
  width: 12vw;
  padding-top: 7px;
  text-decoration: none !important;
}
media-menu .media-team-link:hover {
  text-decoration: none !important;
  box-shadow: inset 0 0 0 99999px rgba(255, 255, 255, 0.2);
}
media-menu .media-team-link-logo {
  height: 20px;
  width: 20px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
media-menu .media-team-link-logo img {
  max-width: 100%;
  max-height: 100%;
  font-size: 0;
}
media-menu .media-team-link-name {
  position: relative;
  top: -5px;
}
media-menu .arrow-container {
  display: block;
  height: 40px;
  left: -26px;
  position: absolute;
  top: -37px;
  width: 70px;
}
media-menu .media-arrow-bg {
  border-style: solid;
  border-width: 0 14px 14px 14px;
  border-color: transparent transparent rgba(0, 0, 0, 0.5) transparent;
  left: 46px;
  position: absolute;
  top: 23px;
  z-index: 1;
}
media-menu .media-arrow-fg {
  border-style: solid;
  border-width: 0 12px 12px 12px;
  border-color: transparent transparent #dedede transparent;
  left: 48px;
  position: absolute;
  top: 26px;
  z-index: 2;
}
media-menu .teams-section-header {
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: flex;
}
media-menu .teams-section-header .teams-header-text-section {
  display: flex;
}
media-menu .teams-section-header .header-link-wrapper {
  display: flex;
  align-items: flex-end;
}
media-menu .teams-section-header .all-teams-section {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
}
media-menu .teams-section-header .create-new-team-section {
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
@media all and (min-width: 1200px) {
  media-menu .teams-section-header .create-new-team-section {
    padding-left: 10px;
    margin-left: 10px;
    border-left-width: 1px;
    border-left-style: solid;
    margin-top: 0;
  }
}
media-menu .teams-section-header .teams-section-header-link {
  font-size: 1rem;
}
media-menu .teams-section-header .teams-section-header-link .glyphicons {
  font-size: 0.75rem !important;
  top: -1px;
  left: 2px;
}
@media all and (max-width: 991px) {
  media-menu > section {
    display: none;
  }
}
@media all and (max-width: 1199px) {
  media-menu .media-videos {
    min-width: 275px;
  }
  media-menu .media-arrow-bg {
    left: 37px;
  }
  media-menu .media-arrow-fg {
    border-bottom: 12px solid #dedede;
    left: 39px;
  }
  media-menu .all-teams-section {
    align-items: flex-start !important;
  }
  media-menu .media-team-links-wrapper {
    height: 255px;
  }
  media-menu .media-teams {
    width: 45vw;
  }
  media-menu .media-team-link {
    width: 20vw;
  }
  media-menu .teams-header-text-section {
    flex-direction: column;
  }
}
@media all and (min-width: 1200px) {
  media-menu .arrow-container {
    left: -35px;
  }
}
