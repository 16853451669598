.dropDown {
  position: relative;
  display: inline-block;
}
.dropDown .dropDownButton {
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
  font-size: 1rem;
  padding: 0.5em;
}
.dropDown .dropDownContent {
  background: #636363;
  border-radius: 4px;
  border: 1px solid #909090;
  box-shadow: 1px 1px 1px 1px rgba(48, 48, 48, 0.5);
  overflow: hidden;
  padding: 0;
  position: absolute;
  min-width: 150px;
  z-index: 100;
}
.dropDown .dropDownContent .listWrap {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.dropDown .alignMenuRight {
  right: 0;
}
