featured-videos {
  display: block;
}
featured-videos .featured-videos-wrap {
  position: relative;
  padding-bottom: 75px;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  featured-videos .featured-videos-wrap {
    padding-bottom: 0;
    min-height: auto;
  }
}
featured-videos .bottom-nav {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  text-align: center;
}
featured-videos .bottom-nav .down-link {
  font-size: 1.5em;
}
featured-videos .bottom-nav .down-link .glyphicon {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  text-shadow: 1px 2px 10px rgba(0, 0, 0, 0.5);
}
@media (max-width: 768px) {
  featured-videos .bottom-nav {
    display: none;
  }
}
