@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@focusErrorBorderPadding: 10px 14px 8px 14px !important;

vb-ui-select-wrap {
	.ui-select-container {
		.ui-select-toggle {
			background-color: @white;
			height: 40px !important;
			font-size: @type14;
			overflow: hidden;
			padding: 12px 16px 10px 16px !important;
			.RoundedShape(4px);

			&:before {
				bottom: 0;
				content: '';
				pointer-events: none;
				position: absolute;
				right: 0;
				top: 0;
				width: 40px;
				height: 38px;
			}

			.caret {
				border-right-width: 6px;
				border-left-width: 6px;
				pointer-events: none;
				top: 17px;
				right: 13px;
			}
		}

		&.ng-invalid:not(.ng-untouched) .ui-select-match:not(.btn-default-focus) .ui-select-toggle {
			border: @invalidFieldBorder;
			padding: @focusErrorBorderPadding;

			.caret {
				right: 11px;
				top: 15px;
			}
		}

		.ui-select-match {
			&:not(.btn-default-focus) .ui-select-toggle {
				border: 1px solid @lightBorder;
			}

			&.btn-default-focus {
				.BoxShadow(none);
				background-color: transparent;
				border-color: transparent;
				outline: 0;

				.ui-select-toggle {
					border-width: 3px;
					.BoxShadow(inset 0px 0px 5px 0px @blackFade25);
					outline: 0;
					padding: @focusErrorBorderPadding;

					.caret {
						right: 11px;
						top: 15px;
					}
				}
			}
		}
	}

	.ui-select-choices-row {
		&:not(.active) {
			> span:hover {
				background-color: #f0f0f0 !important;
				color: @primaryDark !important;
			}
		}

		> span {
			padding: 3px 16px;
			font-size: @type14;

			&:last-child > .select-choices-row-items {
				border-bottom: 0;
			}
		}
	}
}