video-expiration-options {
  display: block;
}
video-expiration-options .video-expiration-fixed-height {
  display: block;
  min-height: 130px;
}
video-expiration-options .expiration-rule {
  /*override the select.css(from ui-select) settings as per our need*/
}
video-expiration-options .expiration-rule .ui-select-container .ui-select-match {
  outline: none;
}
video-expiration-options .expiration-rule .ui-select-container .ui-select-match .form-control {
  height: 31px;
  padding-top: 8px;
  font-size: 0.875rem;
}
video-expiration-options .expiration-rule .ui-select-container.ul-select-lg .ui-select-match {
  outline: none;
}
video-expiration-options .expiration-rule .ui-select-container.ul-select-lg .ui-select-match .form-control {
  height: 46px;
  padding-top: 13px;
  font-size: 0.875rem;
}
video-expiration-options .expiration-rule .ui-select-container .btn-default-focus {
  border: 1px solid #00a0f0;
  outline: 0 !important;
  box-shadow: inset 0 0 0 1px #00a0f0, 0 0 10px rgba(0, 0, 0, 0.25);
}
video-expiration-options .expiration-rule .ui-select-container .ui-select-choices .select-choices-row-items {
  padding: 3px 20px;
}
video-expiration-options .expiration-rule .ui-select-bootstrap .ui-select-choices-row > span {
  padding: 0;
}
video-expiration-options .expiration-rule .ui-select-placeholder {
  display: block;
}
