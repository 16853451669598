.webcast-header,
.webcast-header h2 {
  text-overflow: ellipsis;white-space: nowrap;overflow: hidden;-webkit-hyphens: auto;-moz-hyphens: auto;-ms-hyphens: auto;
}
.webcast-wrap-fs,
.resize-fill {
  position: absolute;top: 0;right: 0;bottom: 0;left: 0;
}
.event-not-started {
  position: absolute;top: 50%;left: 50%;-webkit-transform: translateY(-50%) translateX(-50%);transform: translateY(-50%) translateX(-50%);
}
.webcast .modal.in {
  z-index: 10001 !important;
}
.webcast-manage-questions-form-wrapper vb-toolbar:nth-child(2) {
  border-bottom: 1px solid #a2a3a4;
}
.webcast-manage-questions-form-wrapper vb-toolbar:nth-child(2) .btn-group > .btn {
  padding: 8px;
}
.webcast-manage-questions-form-wrapper vb-toolbar:nth-child(2) .btn-group > .btn:not(.active) {
  background: #dedfdf;
}
.webcast-manage-questions-form-wrapper vb-toolbar:nth-child(2) .btn-radius0 {
  border-radius: 0;
}
.webcast-manage-questions-form-wrapper vb-toolbar:nth-child(2) .btn-inbox-show-new-questions {
  font-size: 0.75rem;
  padding: 5px;
}
.webcast-manage-questions-form-wrapper vb-toolbar:nth-child(2) [flex] {
  padding: 0;
}
.webcast .webcast-status-msg {
  position: relative;
  top: auto;
}
.webcast .webcast-body {
  position: relative;
  overflow: hidden;
}
.webcast-wrap-fs {
  background: #000;
}
.webcast-header {
  padding-right: 15px;
}
.webcast-header h2 {
  margin: 0;
  color: #ebebeb;
}
.webcast-toolbar {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  line-height: 47px;
  font-weight: bold;
  font-size: 0.875rem;
}
.presentation-slides .webcast-toolbar {
  border-top-width: 1px;
  border-top-style: solid;
}
.resize-fill {
  height: 100%;
  -webkit-flex: none !important;
  flex: none !important;
}
.webcast-main-content {
  position: relative;
  width: 100%;
}
@media (max-width: 991px) {
  .webcast-main-content {
    padding-bottom: 48px;
  }
}
.main-webcast-region {
  max-height: 100%;
  padding-bottom: 40px;
  overflow: hidden;
  position: absolute;
  top: 76px;
  left: 0;
  right: 0;
  bottom: 0;
}
.main-webcast-region.no-status-bar {
  top: 47px;
}
@media (min-width: 992px) {
  .main-webcast-region {
    height: auto;
    padding: 0;
  }
}
@media (max-width: 505px) {
  .main-webcast-region.presenter {
    top: 125px;
  }
}
.vertical-layout .viewer-content:not(.ng-hide):not([hidden]) {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-flex-flow: column nowrap !important;
  flex-flow: column nowrap !important;
  -webkit-align-items: stretch !important;
  align-items: stretch !important;
  justify-content: stretch !important;
}
.webcast-wrap {
  position: relative;
}
.webcast-wrap .webcast-main-content {
  -webkit-transition: all linear 0.125s;
  transition: all linear 0.125s;
}
.webcast-wrap .presentation-column {
  border-left-width: 1px;
  border-left-style: solid;
}
.webcast-wrap .vertical-layout .presentation-column {
  border-left: 0;
}
.webcast-wrap .webcast-player-presentation {
  position: absolute;
  top: 0 ;
  left: 0;
  right: 0;
  bottom: 256px;
}
.webcast-wrap .webcast-player-presentation > div {
  -webkit-align-self: stretch;
  align-self: stretch;
}
.webcast-wrap-fs {
  padding-top: 2px;
}
.webcast-wrap .right-sidebar {
  background-color: #f3f3f3;
}
@media (min-width: 992px) {
  .webcast-wrap .right-sidebar {
    height: auto;
  }
}
.webcast-wrap .right-sidebar .right-sidebar-content {
  height: 100%;
  min-height: 0 !important;
}
@media (max-width: 991px) {
  .webcast-wrap .webcast-main-content:not(.no-video) .presentation-column {
    border-left: 0;
    border-top-width: 1px;
    border-top-style: solid;
  }
}
@media (max-width: 991px) {
  .webcast-wrap .btn-open-in-new-window {
    padding-right: 50px;
  }
}
.event-not-started {
  background-color: #f3f3f3;
  border-radius: 4px;
  max-height: 60vh;
  margin-top: 50px;
  max-width: 50%;
  padding: 16px;
  position: absolute;
  left: 50%;
  overflow-y: auto;
  text-align: center;
  top: 50%;
  width: 50%;
}
.event-not-started p {
  margin-top: 20px;
}
.event-not-started h2 {
  margin-top: 0;
}
.event .attendee-box {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.event form {
  margin-bottom: 1em;
}
.event form .btn-white {
  height: 31px;
}
.event-message {
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  line-height: 1;
  display: block;
  padding: 8px 16px;
}
.event-message.error,
.event-message.recording {
  background: #c41616;
}
.event-message.pre-production {
  padding: 13px 0;
  background: #6f7377;
  color: #fff;
  font-size: 1rem;
}
.event-message.warning {
  background: #ffcb00;
  border-bottom: 1px solid #e8af04;
  color: #303030;
}
.event-message.processing {
  background: rgba(48, 48, 48, 0.5);
}
.event-message.broadcasting {
  background: #00a0f0;
}
@media (min-width: 992px) {
  .webcast-wrap {
    height: 100vh;
  }
}
@media all and (max-width: 900px) and (min-width: 525px) {
  .well {
    padding: 8px;
  }
}
webcast-comments,
webcast-comments > [layout] {
  height: 100%;
}
@media all and (max-width: 991px) {
  webcast-comments,
  webcast-comments > [layout] {
    /* take into consideration that the sidebar icons are on bottom in mobile */
    height: calc(100% - 24px);
  }
}
.comment-scroll-box {
  overflow-y: auto;
  padding: 8px;
  padding-right: 18px;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 12px;
  border-radius: 4px;
}
.comment-scroll-box .comment {
  padding-bottom: 1px;
  border: 0 none;
}
.comment-scroll-box p {
  color: #1e1e1e;
}
.comment-scroll-box .chat-username {
  color: #00a1f0;
  font-weight: bold;
}
.comment-scroll-box .chat-time-stamp {
  color: #999;
  font-style: italic;
  font-size: 11px;
  font-weight: normal;
}
.comment-input-box {
  font-size: 12px;
  overflow-y: hidden;
}
.comment-input-box .btn {
  padding-left: 10px;
  padding-right: 10px;
}
.comment-input-box .input-group input {
  height: 35px;
  box-shadow: inset 0 1px 1px #cbcbcb, 0 1px 0 rgba(0, 0, 0, 0.25);
}
.comment-input-box .input-group .btn {
  padding: 8px 12px;
}
@media all and (max-width: 900px) and (min-width: 525px) {
  .comment-scroll-box {
    margin-bottom: 4px;
  }
}
.video-viewport {
  position: relative;
}
@media (max-width: 991px) {
  .video-viewport.ng-hide {
    display: block !important;
  }
  .video-viewport .webcast-video-wrap:not(.mobile-hide-for-real):not(.ng-hide) {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .video-viewport {
    overflow: hidden;
    position: relative;
  }
}
.video-viewport.webcast-video-hidden {
  position: fixed;
  left: -200vw;
}
.video-viewport .webcast-video-wrap {
  width: 100%;
  position: relative;
}
.video-viewport .webcast-video-wrap [vb-webcast-player] {
  margin: 0 auto;
}
.video-viewport .webcast-video-wrap .player-wrap {
  overflow: hidden;
  position: relative;
}
.video-viewport .webcast-video {
  width: 100%;
  min-height: 50px;
  position: relative;
  background: #000;
}
.video-viewport .webcast-video .text-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  color: #fff;
}
@media (max-width: 991px) {
  .webcast-main-content:not(.no-presentation):not(.no-video) .video-viewport,
  .webcast-main-content:not(.no-presentation):not(.no-video) .presentation-viewport {
    max-height: 50%;
  }
}
@media (min-width: 992px) {
  .no-presentation .video-viewport {
    -webkit-flex: 1 1 100%;
    flex: 1 1 100%;
  }
}
@media (min-width: 1200px) {
  .video-height-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .webcast-toolbar > div:first-child {
    padding-left: 32px !important;
  }
}
.video-height-container {
  flex: 1 0 !important;
  height: calc(100% - 40px);
  overflow: hidden;
}
.webcast-video-not-supported {
  margin: 0 auto;
}
