@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root {
	.displayFlex();
	flex-flow: column;
	overflow-y: auto;
	height: 100%;
}

.askQuestionWrapper {
	padding: 16px;
}

.rightToolSection {
	margin-left: auto;
}

.closedQuestionsFilterMenu {
	left: -100px;
}

.questionListWrap {
	position: relative;
	.flex(1 1 auto);

	vb-webcast-questions-list {
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: auto;
		display: block;
	}
}
