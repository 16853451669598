@import (reference) "~rev-less/global/variables";

.root {
	composes: btn-group from global; // bootstrap
}

.button {
	composes: webcastNavBtn from '../WebcastNav.module.less';
}

.active {
	composes: active from '../WebcastNav.module.less';
}

@media(max-width: (@viewPortMedWidth - 1px)) {
	.label,
	.slides {
		display: none;
	}
}
