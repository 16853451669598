.spinner {
  height: 150px;
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}
.timer {
  font-size: 3rem;
  line-height: 1.25;
}
@media (max-width: 505px) {
  .timer {
    font-size: 2.25rem;
    line-height: 1.75;
  }
  .stopBtn {
    margin-bottom: 1rem;
  }
}
