.contentWrapper {
  display: block;
}
.toggleBtnWapper {
  margin-top: 15px;
}
.toggleBtn {
  text-transform: uppercase;
  padding: 2px;
  font-size: 0.6875rem;
}
