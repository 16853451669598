.progressBarWrap {
  margin-top: 10px;
  background: #999999;
  height: 3px;
  margin-right: 1%;
  width: 93%;
}
@media all and (max-width: 992px) {
  .progressBarWrap {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
}
.progressBar {
  height: 100%;
}
