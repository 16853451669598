@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.presentation-slides-wrapper {
	height: 256px;

	@media(max-width: 991px) {
		display: none;
	}
}

.presentation-slides {
	height: 256px;
	position: absolute;
	right: 0;
	left: 0;
	bottom: 0;

	&-toolbar-wrap {
		z-index: 1;

		.form-upload-files {
			line-height: 16px;
			padding-top: 6px;
		}
	}

	.slides-box {
		position: absolute;
		top: 45px;
		left: 0;
		right: 0;
		bottom: 0;
		overflow-x: hidden;
		overflow-y: scroll;
		white-space: normal;
		padding: 8px 16px;
		.TransitionAll(all linear 0.125s);

		@media(min-width: 1200px) {
			padding: 8px 16px 8px 32px;
		}

		img {
			width: 144px;
			overflow: hidden;
			border-width: 4px;
			border-style: solid;
			border-color: @blackFade10;
			margin: 4px;

		}

		[webcast-background] & {
			background: transparent;
		}
	}
}

.no-presentation.no-video .presentation-slides {
	height: 100%;
}
