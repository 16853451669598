manage-team .admin-team .preview-wrapper {
  background-color: #ebebeb;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 10px;
  width: auto;
}
manage-team .admin-team .btn-file-wrapper {
  text-align: right;
}
manage-team .admin-team .btn-file-wrapper,
manage-team .admin-team .team-logo {
  display: table-cell;
}
manage-team .admin-team .team-logo {
  font-size: 0.75rem;
  height: 75px;
  width: 75px;
}
manage-team .admin-team .team-logo .circle {
  width: 50px;
  height: 50px;
  font-size: 32px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  vertical-align: middle;
}
manage-team .admin-team .logo-info {
  font-size: 0.875rem;
  font-style: italic;
}
manage-team .admin-team .logo-preview-wrapper {
  padding-top: 5%;
}
manage-team .admin-team .logo-preview {
  min-height: 75px;
  min-width: 100px;
}
manage-team .admin-team .logo {
  max-height: 100%;
  max-width: 100%;
}
manage-team .admin-team .company-name-preview {
  margin-left: 20px;
}
manage-team .admin-team .color-title {
  font-size: 0.875rem;
}
manage-team .teams-panel .color-picker-wrapper {
  border-top-left-radius: 0;
  left: 0;
}
manage-team .teams-panel .color-picker-wrapper:before {
  margin-left: -167px;
}
manage-team .teams-panel .color-picker-wrapper:after {
  margin-left: -166px;
  top: -15px;
}
manage-team .teams-panel .danger {
  color: #c41616;
}
manage-team .from-media-state.admin-team {
  margin-left: 0;
  margin-right: 0;
}
manage-team .from-media-state .col-xs-12 {
  padding-left: 0;
  padding-right: 0;
}
manage-team .from-media-state .col-xs-12 .panel {
  margin-bottom: 0px;
  border: 0;
  box-shadow: initial;
}
manage-team .from-media-state .col-xs-12 .panel .media-state-row {
  padding-left: 16px;
}
