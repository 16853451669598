@import (reference) '~rev-less/global/mixins';
@import (reference) '~rev-less/global/variables';

.root {
	height: 100%;
	display: block;
	overflow: hidden;
}

.pollInfoWrapper {
	display: flex;
	flex-flow: column;
	padding-bottom: 5px;
}

.flickityCarousel {
	composes: theme-primary-font-border-fade-50 from global;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	:global(.flickity-page-dots) {
		top: 0;
		bottom: auto;
		padding-bottom: 5px;
		z-index: 1;
		left: 35px;
		width: calc(100% - 70px);

		:global(.dot) {
			&:global(.is-selected) {
				width: 12px;
				height: 12px;
			}
		}
	}
	:global(.flickity-prev-next-button) {
		top: 10px;
		width: 25px;
		height: 25px;
		z-index: 2;
		.TransitionAll();
		&:global(.previous) {
			left: 10px !important;
		}
		&:global(.next) {
			right: 10px !important;
		}
	}
	:global(.flickity-carousel-container) {
		height: 100%;
	}
	:global(.flickity-viewport) {
		height: 100% !important;
	}
}

.carouselEntry {
	composes: theme-primary-font-border-fade-50 from global;
	width: 100%;
	top: 35px;
	overflow-y: auto;
	border-top-width: 1px;
	border-top-style: solid;
	padding-bottom: 10px;
	&:global(.is-selected) {
		bottom: 10px;
	}
}

.pollSubFooter {
	border-top-width: 1px;
	border-top-style: solid;
	font-size: 0.75rem;
	line-height: 1.5rem;
	padding: 0 12px;
	text-align: left;
}

.pollOption {
	text-align: left;
	padding: 0 32px;
}


.pollAuthoringTools {
	display: flex;
	flex-flow: row wrap;
	padding: 10px 25px;
	justify-content: center;
}

.managePollResponseWrapper {
    overflow-y: auto;
    margin-top: 5px;
}

.editPollViewRoot {
	display: flex;
	flex-flow: column;
	height: 100%;
	overflow-y: auto;
}

.editPollFormWrapper {
	margin: 30px 16px;
}

.editPollHeader {
	padding: 8px 16px;
	height: 30px;
	border-width: 1px;
	border-style: solid;
	h3 {
		margin: 0;
		font-size: @type14;
	}
}

.multiChoice {
	font-size: @type14;
}
