.webcastBackground.fixed {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.webcastBackground:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: ' ';
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-filter: brightness(0.8);
  -moz-filter: brightness(0.8);
  -ms-filter: brightness(0.8);
  filter: brightness(0.8);
}
.webcastBackground.webcastBackgroundFill:before {
  background-size: cover;
}
