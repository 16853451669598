@import  (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.header {
	border-bottom: 1px solid;
	line-height: 47px;
	font-weight: bold;
	font-size: @type14;

	.presentation-slides & {
		border-top: 1px solid @borderColorLight;
	}
}

.videoWrapper {
	width: 100%;
	position: relative;
	display: flex !important;

	[vb-webcast-player] {
		margin: 0 auto;
	}

	:global(.player-wrap) {
		// fix control animation
		overflow: hidden;
		position: relative;
	}
}
