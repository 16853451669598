notifications-menu-button {
  display: block;
}
notifications-menu-button .notification-menu {
  width: 400px;
  left: auto;
  right: 0;
}
@media (max-width: 768px) {
  notifications-menu-button .notification-menu {
    width: auto;
    position: fixed;
    left: 0 !important;
    right: 0 !important;
  }
}
notifications-menu-button:not(.count-bubble) .btn.notifications.high {
  background: #c41616;
  color: #fff;
}
notifications-menu-button.count-bubble .notifications {
  background-color: transparent;
}
notifications-menu-button.count-bubble .notifications:not(.open):hover,
notifications-menu-button.count-bubble .notifications:not(.open).high {
  background-color: transparent !important;
}
notifications-menu-button.count-bubble .notifications.open .notifications-count {
  display: none;
}
notifications-menu-button.count-bubble .notifications .notifications-count {
  border: 1px solid transparent;
  border-radius: 24px;
  padding: 3px;
  font-size: 0.625rem;
  height: 20px;
  min-width: 1.5em;
  line-height: 1.25em !important;
  position: absolute;
  top: 0;
  right: 0;
}
notifications-menu-button.count-bubble .notifications.high .notifications-count {
  color: #fff;
  background: #c41616;
}
