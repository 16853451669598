.admin-multiselect-compact {
  border-spacing: 1rem 0;
}
.admin-multiselect-compact .all-elements,
.admin-multiselect-compact .assigned-elements {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 8px;
  width: 50%;
}
.admin-multiselect-compact .assigned-elements {
  width: 50%;
}
.admin-multiselect-compact .assigned-elements:after {
  content: none;
}
.admin-multiselect-compact .assigned-elements h4:after {
  line-height: 0 !important;
}
.admin-multiselect-compact .available-items,
.admin-multiselect-compact .selected-items {
  margin: 0;
  padding: 0;
}
.admin-multiselect-compact .table-cell-spacer {
  width: 15px;
}
.admin-multiselect-compact h4 {
  font-size: 13px;
  margin-top: 0;
  text-transform: uppercase;
  font-weight: bold;
  color: #7d7d7d;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}
.admin-multiselect-compact .admin-movable-item .hover-wrap {
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.1);
}
.admin-multiselect-compact .btn-movable-selector {
  padding: 3px 5px;
}
.admin-multiselect-compact .panel-sub-heading {
  padding: 8px 0 0;
  background: none;
}
.admin-multiselect-compact .panel-sub-heading .table-cell-fill {
  display: block;
}
@media (max-width: 991px) {
  .admin-multiselect-compact.table-display {
    display: block;
  }
  .admin-multiselect-compact .assigned-elements,
  .admin-multiselect-compact .all-elements {
    display: block;
    width: 100%;
  }
  .admin-multiselect-compact .assigned-elements {
    margin-bottom: 8px;
  }
}
