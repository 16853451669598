.errorMsg {
  color: #ffffb3;
  font-size: 1.313rem;
}
.errorMsgReason {
  color: #fff;
  font-size: 0.875rem;
}
.msTeamsConnecting {
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  max-width: 220px;
}
