.imagePreview {
  background: #f3f3f3;
  border: 1px solid #d8d8d8;
  margin: 16px 0;
  padding: 8px;
  border-radius: 3px;
}
.imagePreview button {
  float: right;
  top: 5px;
  clear: both;
}
.imagePreview > img {
  max-height: 50px;
}
.requiredCt {
  min-width: 150px;
}
.requiredIndicator:after {
  right: 1.2rem !important;
}
