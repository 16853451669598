share-embed form > div {
  margin-bottom: 16px;
}
share-embed label {
  color: #898989;
}
share-embed label[for="videoShareEmbedSizeSelect"] {
  display: block;
}
share-embed .video-share-embed-invalid {
  color: #c41616;
}
share-embed .embed-options {
  border-top: 1px solid #d8d8d8;
  margin-top: 16px;
  display: block;
}
@media (max-width: 767px) {
  share-embed .embed-options {
    font-size: 0.75rem;
  }
  share-embed .embed-options select,
  share-embed .embed-options input,
  share-embed .embed-options label {
    font-size: 0.875rem;
  }
}
share-embed .embed-options input {
  width: 60px;
  display: inline-block;
  text-align: center;
  padding: 2px;
}
share-embed .embed-options input.form-control[disabled] {
  background: none;
  border: 1px solid #e5e5e5;
  box-shadow: none;
}
share-embed .embed-options input[type=number]::-webkit-inner-spin-button,
share-embed .embed-options input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}
share-embed .embed-options .select-cell {
  padding-right: 32px;
}
share-embed .embed-options .label-cell {
  padding-right: 16px;
}
share-embed textarea {
  display: block;
  width: 100%;
  resize: none;
  cursor: default;
  border: 1px solid #d8d8d8;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  padding: 0.5rem;
}
share-embed textarea[readonly] {
  cursor: default;
  border: 1px solid #d8d8d8;
  background: #f8f8f8;
}
share-embed .textarea-bottom-controls {
  border: 1px solid #d8d8d8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 8px;
  background: #fff;
}
share-embed input[type="text"],
share-embed input[type="number"] {
  display: inline-block;
}
share-embed input[type="text"].ng-invalid,
share-embed input[type="number"].ng-invalid {
  border-color: #c41616 !important;
}
