.admin-movable-item {
  margin: 0;
  border-radius: 3px;
  background: #fff;
  vertical-align: middle;
  font-weight: bold;
}
.admin-movable-item:hover {
  cursor: default;
  cursor: pointer;
  cursor: hand;
}
.admin-movable-item .hover-wrap {
  padding: 2px 2px 2px 6px;
  border-radius: 3px;
  border: none;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2), 0 0 2px rgba(0, 0, 0, 0.1), inset 0 1px 0 #fff;
  background: #f0f0f0;
  line-height: 2;
  word-break: break-all;
}
.admin-movable-item .hover-wrap:hover {
  border-color: rgba(0, 85, 128, 0.25);
  box-shadow: 0 0 1px rgba(0, 85, 128, 0.35);
  background: #f3fbff;
}
.admin-movable-item .hover-wrap:hover .write-access .glyphicons {
  color: #303030;
}
.available-items .admin-movable-item .hover-wrap:hover > .btn-assigned-item,
.selected-items .admin-movable-item .hover-wrap:hover > .btn-remove {
  display: inline-block;
  opacity: 1;
}
.available-items .admin-movable-item .hover-wrap:hover > .btn-remove,
.selected-items .admin-movable-item .hover-wrap:hover > .btn-assigned-item {
  display: none;
  opacity: 0;
}
.admin-movable-item .hover-wrap:not(:last-child):not(:first-child) {
  border-radius: 0;
}
.admin-movable-item .hover-wrap:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.available-items .admin-movable-item .hover-wrap:first-child:not(:last-child) {
  border-radius: 3px;
}
.admin-movable-item .hover-wrap:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.admin-movable-item .hover-wrap.icon-could-stream {
  background-image: url(/img/cloud-stream.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 100px !important;
  background-size: 9%;
  background-position: 99%;
}
.admin-movable-item .hover-wrap.icon-vbrick-multicast-stream {
  background-image: url(/img/vbrick-multicast-stream.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 100px !important;
  background-size: 7%;
  background-position: 99%;
}
.admin-movable-item .hover-wrap.icon-unicast-stream {
  background-image: url(/img/unicast-stream.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 100px !important;
  background-size: 7%;
  background-position: 99%;
}
.admin-movable-item .hover-wrap.icon-multicast-stream {
  background-image: url(/img/multicast-stream.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 100px !important;
  background-size: 10%;
  background-position: 99%;
}
.admin-movable-item .hover-wrap.icon-reflection-stream {
  background-image: url(/img/reflection-stream.png);
  background-repeat: no-repeat;
  background-position: right center;
  padding-right: 100px !important;
  background-size: 10%;
  background-position: 99%;
}
.admin-movable-item .write-access .glyphicons {
  top: 2px;
}
.admin-movable-item.sub-dir {
  margin-left: 40px;
}
.admin-movable-item.locked,
.admin-movable-item.locked .lock {
  color: #aaa;
}
.admin-movable-item.locked:hover {
  background: #f2f2f2;
  cursor: default;
}
.admin-movable-item .btn {
  margin-right: 5px;
  padding: 2px 4px;
}
@media (max-width: 600px) {
  .admin-movable-item .btn {
    min-height: 0;
    font-size: 14px;
  }
}
.btn-movable-selector {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 6px 8px;
  box-shadow: inset 0 0 1px #fff;
}
.available-items .btn-movable-selector.btn-assigned-item {
  opacity: 0;
  display: none;
}
.available-items .btn-movable-selector.btn-remove {
  opacity: 1;
  display: inline-block;
}
.selected-items .btn-movable-selector.btn-remove {
  opacity: 0;
  display: none;
}
.selected-items .btn-movable-selector.btn-assigned-item {
  opacity: 1;
  display: inline-block;
  background: rgba(0, 160, 240, 0.15);
  border-color: rgba(0, 0, 0, 0.15);
  color: #005580;
  box-shadow: inset 0 -1px 6px rgba(0, 160, 240, 0.2);
}
.admin .combined-toolbar .btn.btn-movable-selector {
  padding: 6px 8px;
}
