.statusIconOffline {
  border-radius: 9999px;
  background: #808080;
  display: inline-block;
  height: 0.75rem;
  width: 0.75rem;
}
.statusIconOnline {
  composes: statusIconOffline;
  background: #009b01;
}
