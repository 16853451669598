@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.video-viewport {
	position: relative;

	@media(max-width: 991px){

		&.ng-hide {
			display: block !important;
		}

		.webcast-video-wrap:not(.mobile-hide-for-real):not(.ng-hide) {
			display: block !important;
		}
	}

	@media(min-width: @viewPortMedWidth) {
		overflow: hidden;
		position: relative;
	}

	&.webcast-video-hidden {
		position: fixed;
		left: -200vw;
	}

	.webcast-video-wrap {
		width: 100%;
		position: relative;

		[vb-webcast-player] {
			margin: 0 auto;
		}

		.player-wrap {
			// fix control animation
			overflow: hidden;
			position: relative;
		}

	}

	.webcast-video {
		width: 100%;
		min-height: 50px;
		position: relative;
		background: @black;

		.text-center {
			.AbsoluteCenter;
			color: @white;
		}
	}
}

@media(max-width: 991px){
	.webcast-main-content {
		&:not(.no-presentation):not(.no-video) {
			.video-viewport,
			.presentation-viewport {
				max-height: 50%; // workaround for iOS 10 Safari bug where it screws up the flex layout on resurface of a region (fixed in 11)
			}
		}
	}
}

@media(min-width: @viewPortMedWidth) {
	.no-presentation .video-viewport {
		.flexItem(1 1 100%);
	}
}

@media(min-width: @viewPortLargeWidth) {
	.video-height-container {
		padding-left: 16px;
		padding-right: 16px;
	}

	.webcast-toolbar {
		>div:first-child {
			padding-left: 32px !important;
		}
	}
}

.video-height-container {
	flex: 1 0 !important;
	height: ~'calc(100% - 40px)'; //accounts for the height of the header
	overflow: hidden;
}

.webcast-video-not-supported {
	margin: 0 auto;
}
