vb-ui-select-wrap .ui-select-container .ui-select-toggle {
  background-color: #fff;
  height: 40px !important;
  font-size: 0.875rem;
  overflow: hidden;
  padding: 12px 16px 10px 16px !important;
  border-radius: 4px;
}
vb-ui-select-wrap .ui-select-container .ui-select-toggle:before {
  bottom: 0;
  content: '';
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 38px;
}
vb-ui-select-wrap .ui-select-container .ui-select-toggle .caret {
  border-right-width: 6px;
  border-left-width: 6px;
  pointer-events: none;
  top: 17px;
  right: 13px;
}
vb-ui-select-wrap .ui-select-container.ng-invalid:not(.ng-untouched) .ui-select-match:not(.btn-default-focus) .ui-select-toggle {
  border: 3px solid #c41616;
  padding: 10px 14px 8px 14px !important;
}
vb-ui-select-wrap .ui-select-container.ng-invalid:not(.ng-untouched) .ui-select-match:not(.btn-default-focus) .ui-select-toggle .caret {
  right: 11px;
  top: 15px;
}
vb-ui-select-wrap .ui-select-container .ui-select-match:not(.btn-default-focus) .ui-select-toggle {
  border: 1px solid #909090;
}
vb-ui-select-wrap .ui-select-container .ui-select-match.btn-default-focus {
  box-shadow: none;
  background-color: transparent;
  border-color: transparent;
  outline: 0;
}
vb-ui-select-wrap .ui-select-container .ui-select-match.btn-default-focus .ui-select-toggle {
  border-width: 3px;
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  outline: 0;
  padding: 10px 14px 8px 14px !important;
}
vb-ui-select-wrap .ui-select-container .ui-select-match.btn-default-focus .ui-select-toggle .caret {
  right: 11px;
  top: 15px;
}
vb-ui-select-wrap .ui-select-choices-row:not(.active) > span:hover {
  background-color: #f0f0f0 !important;
  color: #141414 !important;
}
vb-ui-select-wrap .ui-select-choices-row > span {
  padding: 3px 16px;
  font-size: 0.875rem;
}
vb-ui-select-wrap .ui-select-choices-row > span:last-child > .select-choices-row-items {
  border-bottom: 0;
}
