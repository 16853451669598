.root {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding-top: 10px;
  height: 100%;
}
.root vb-ui-tab-panel [role="tabpanel"] {
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}
.root vb-ui-tab-panel button[role="tab"]:first-child {
  border-left: 0;
}
.noQuestionAsked {
  display: flex;
  justify-content: center;
  padding: 16px;
}
