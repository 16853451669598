.Round {
  border-radius: 9999px;
}
.RoundedButton {
  border-radius: 3px;
}
.PointerCursor {
  cursor: pointer;
}
.TextClip,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item > span,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item > span,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item > small,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item > small {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.whiteSpacePreWrap {
  white-space: pre-wrap;
  /* css-3 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
.HyphensAuto {
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
}
.WordBreak {
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: break-word;
}
.GlyphiconFont {
  font-family: 'Glyphicons Regular';
}
.ButtonGlyphicons {
  font-size: 1rem;
  top: 3px;
}
.clearFix {
  clear: both;
  content: "";
  display: table;
}
/**
 * Flex Layout Support
 * ----------------
 * Prefixed = IE10, Safari 8-, iOS 8.1-, Android 4.3-
 * Standard = IE11+, Chrome, Android 4.4+
 */
.flexDisplay {
  display: -webkit-flex;
  display: flex;
}
.flexContainerStatic {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}
/*
 * IE9 not supported
 * IE10+ unprefixed
 * FF unprefixed
 * Safari 6.1+ unprefixed
 * Chrome 26+ unprefixed
 * Android 4.0 - 4.3 prefixed, 4.4+ unprefixed
 */
.AbsoluteFill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.FixedFill {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.BtnWhite {
  background: linear-gradient(to bottom, #fff, #f3f3f3);
  border-color: #b2b2b2;
  box-shadow: inset 0 1px 0 #fff;
  color: #303030;
}
.BtnWhiteActive {
  color: #005c8a;
  border-color: rgba(0, 92, 138, 0.25);
  background: #d7f2ff;
  box-shadow: none;
}
.focusedInput {
  border-color: #00a0f0;
  outline: 0;
  box-shadow: inset 0 0 0 1px #00a0f0, 0 0 10px rgba(0, 0, 0, 0.25);
}
.focusedItem {
  /* bootstrap */
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.AbsoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.VerticalCenter {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ScrollContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
.primary-color {
  color: #00a0f0;
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item {
  font-size: 13px;
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item > span,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item > span,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item > small,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item > small {
  display: block;
  padding-right: 0.5rem;
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item > span,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item > span {
  font-weight: bold;
  line-height: 22px;
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-item > small,
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .category-autocomplete-item > small {
  width: 100%;
  line-height: 12px;
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .tags .tag-item {
  background: #d7f2ff;
  color: #005c8a;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.25);
  height: auto !important;
  position: relative;
  padding: 0.5rem 2rem 0.5rem 0.75rem;
  width: 48%;
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .tags .tag-item .remove-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  color: inherit;
  text-align: center;
  height: 100%;
  border-left: 1px solid rgba(0, 90, 130, 0.1);
  font-size: 11px;
  padding-top: 12px;
  color: rgba(0, 90, 130, 0.25);
}
.right-sidebar .right-sidebar-content .filters-sidebar tags-input.category-tags-input .tags .tag-item .remove-button:hover {
  text-decoration: none;
  background: rgba(0, 90, 130, 0.2);
  color: #005c8a;
}
