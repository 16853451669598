.root {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  height: 100%;
}
.askQuestionWrapper {
  padding: 16px;
}
.rightToolSection {
  margin-left: auto;
}
.closedQuestionsFilterMenu {
  left: -100px;
}
.questionListWrap {
  position: relative;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.questionListWrap vb-webcast-questions-list {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: block;
}
