featured-video-thumbnail {
  display: block;
  height: 100%;
  position: relative;
}
featured-video-thumbnail .thumbnail-bg {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
featured-video-thumbnail .thumbnail-bg .thumbnail-gradient {
  height: 100%;
}
featured-video-thumbnail .thumbnail-content {
  position: absolute;
  width: 100%;
  color: #fff;
}
featured-video-thumbnail .thumbnail-content .btn {
  font-size: 1.5em;
  padding: 13px 25px;
  font-weight: 600;
  text-transform: uppercase;
}
featured-video-thumbnail .thumbnail-content .btn .circle {
  width: 1.5em;
  height: 1.5em;
  margin-left: 10px;
  border-width: 1px;
  border-style: solid;
}
featured-video-thumbnail .thumbnail-content .btn .circle .glyphicons {
  top: 4px;
  color: inherit;
}
featured-video-thumbnail h1 {
  margin-top: 0;
}
featured-video-thumbnail h1 .overflow-container {
  line-height: 1em;
  max-height: 2em;
  overflow: hidden;
  word-wrap: break-word;
  font-size: 2em;
}
@media (max-width: 991px) {
  featured-video-thumbnail h1 .overflow-container {
    max-height: 1em;
    font-size: 1em;
  }
}
featured-video-thumbnail[align-title-left] .thumbnail-bg .thumbnail-gradient {
  background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
}
featured-video-thumbnail[align-title-left] .thumbnail-content {
  top: 50%;
  left: 80px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 992px) {
  featured-video-thumbnail[align-title-left] .thumbnail-content {
    width: calc(50% - 80px);
  }
}
featured-video-thumbnail[align-title-bottom] .btn {
  font-size: 0.9em;
  padding: 8px 13px;
}
featured-video-thumbnail[align-title-bottom] .btn .circle .glyphicons {
  top: 2px;
}
featured-video-thumbnail[align-title-bottom] .overflow-container {
  margin: 0 20px;
  font-size: 0.9em;
}
featured-video-thumbnail[align-title-bottom] .thumbnail-bg .thumbnail-gradient {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
}
featured-video-thumbnail[align-title-bottom] .thumbnail-content {
  bottom: 40px;
  text-align: center;
  -webkit-transform: none;
  transform: none;
}
