@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.carouselCell {
	background: @white;
	border: 1px solid @color1;
	display: block;
	padding: 4px 8px 4px 4px;
	.RoundedShape(2px);
	.BoxShadow(0 0 1px @blackFade50);

	@media all and (max-width: (@viewPortMedWidth - 1px)) {
		background: @white;
		border: none;
		border-radius: 0;
		padding: 2px 8px 2px 2px;
	}
	@media all and (min-width: @viewPortMedWidth) {
		background: @black;
		display: inline-block;
		padding: 0;
		width: 95%;
	}
}

.carouselCellItem {
	@media all and (max-width: (@viewPortMedWidth - 1px)) {
		border-top: transparent;
		left: 0 !important;

		&:first-child {
			border-top: 1px solid @borderColorLight;
		}
	}
}

.imageWrapper {
	height: 40px;
	margin-right: 10px;
	width: 100%;

	@media all and (max-width: (@viewPortMedWidth - 1px)) {
		display: inline-block;
	}

	@media all and (min-width: @viewPortMedWidth) {
		height: 100%;
	}
}

.infoContainer {
	padding: 8px;
	text-align: center;
	vertical-align: top;
	height: 56px;

	@media all and (max-width: (@viewPortMedWidth - 1px)) {
		display: inline-block;
		height: auto;
		margin-top: 10px;
		max-width: 65%;
		padding-top: 2px;
		text-align: left;
	}

}

.infoContainer h4 {
	color: @color1Lighten50;
	margin: 0;
	padding: 0;
	.TextClip;
}

.infoContainer h5 {
	display: none;
	@media all and (min-width: @viewPortMedWidth) {
		color: @color1Lighten20;
		display: block;
		font-size: @type12;
		.TextClip;
	}
}

.infoContainer a {
	color: @color1Lighten50;;
	font-size: @type16;
}

.fixedRatioWrapper {
	@media all and (max-width: @viewPortVerySmallWidth) {
		margin-right: 0;
	}

	@media all and (min-width: (@viewPortVerySmallWidth + 1)) and (max-width: @viewPortMedWidth) {
		margin-right: 15px;
	}

	@media all and (max-width: @viewPortMedWidth) {
		display: inline-block;
		height: 45px;
		width: 75px;
	}
}


.borderSolidBottom {
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.header {
	.flexWrap(nowrap);
}

.headerPrimaryWrapper {
	max-width: 85%;
}

.title {
	color: @white;
	margin: 0;
	padding: 6px;
	.TextClip;
	outline: 1px solid @blackFade00;
	width: 30rem;
	max-width: 30rem;
}

.title,
.titleLink {
	font-size: @type18;
}

.marginBottom {
	margin-bottom: 30px;

	@media all and (max-width: @viewPortMedWidth) {
		margin-bottom: 10px;
	}
}

.browseAll {
	.displayFlex();
	.alignItems(center);
	.flexItem(1);
	.flexJustifyContent(flex-end);
}

.browseAll > a:hover {
	text-decoration: underline;
}
