@import "~rev-less/global/variables";

.webex-import {
	.input-fields {
		padding: 32px 32px 16px !important;
	}

	.username,
	.password {
		position: relative;
	}

	.glyphicons.user,
	.glyphicons.lock,
	.glyphicon.glyphicon-lock {
		position: absolute;
		top: 12px;
		left: 12px;
		font-size: 16px;
		color: @color1LightText;
	}

	input {
		&.input-with-icon {
			padding: 0 15px 0 40px;
			font-size: @type16;
			height: 40px;
			line-height: 40px;
			vertical-align: middle;
			color: @color1;
			margin-bottom: 16px;

		}

		&.form-control:focus {
			background: @white;
		}

		&.ng-dirty.ng-invalid {
			border-color: @borderColorDark !important;
		}
	}

	.modal-lg {
		@media(min-width: 1200px) {
			width: 80%;
		}
	}

	.modal-body {
		min-height: 158px;
		padding: 0;
	}

	.modal-footer {
		margin-top: 0;
	}

	.system-msg {
		font-size: @type16;
		margin-bottom: 0;
		line-height: @type21;
		padding: 16px;

		.glyphicons {
			margin-right: 5px;
			top: 2px;
		}
	}
}

.import-webex-grid-list {
	> ul {
		height: 60vh;

		> li {

			.grid-list-cell {
				font-size: @type12;
			}

			.grid-list-cell:nth-child(2) {
				font-weight: bold;
				font-size: @type14;
			}
		}
	}
}