.name,
.email {
  text-overflow: ellipsis;white-space: nowrap;overflow: hidden;-webkit-hyphens: auto;-moz-hyphens: auto;-ms-hyphens: auto;
}
.row {
  line-height: 1;
  padding: 5px;
  display: flex;
}
.personalInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 95%;
  justify-content: center;
}
.name {
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 1.1;
}
.email {
  font-size: 0.625rem;
  opacity: 0.8;
  margin-top: 5px;
  line-height: 1.1;
}
.removed {
  background-color: rgba(0, 0, 0, 0.1);
  color: #666666;
}
.imgComp {
  margin-right: 5px;
  margin-top: 1px;
}
.removeRestoreBtn {
  padding: 3px 6px;
}
