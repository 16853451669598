[vb-toggle-button] {
  cursor: pointer;
  position: relative;
}
[vb-toggle-button]:hover:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  content: '';
}
[vb-toggle-button]:not(.active):hover {
  color: #fff;
}
[vb-toggle-button] > [ng-transclude] {
  position: relative;
}
