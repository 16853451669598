.header {
  border-bottom: 1px solid;
  line-height: 47px;
  font-weight: bold;
  font-size: 0.875rem;
}
.presentation-slides .header {
  border-top: 1px solid #d8d8d8;
}
.videoWrapper {
  width: 100%;
  position: relative;
  display: flex !important;
}
.videoWrapper [vb-webcast-player] {
  margin: 0 auto;
}
.videoWrapper :global(.player-wrap) {
  overflow: hidden;
  position: relative;
}
