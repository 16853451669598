.presentation-slides-wrapper {
  height: 256px;
}
@media (max-width: 991px) {
  .presentation-slides-wrapper {
    display: none;
  }
}
.presentation-slides {
  height: 256px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}
.presentation-slides-toolbar-wrap {
  z-index: 1;
}
.presentation-slides-toolbar-wrap .form-upload-files {
  line-height: 16px;
  padding-top: 6px;
}
.presentation-slides .slides-box {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  white-space: normal;
  padding: 8px 16px;
  -webkit-transition: all linear 0.125s;
  transition: all linear 0.125s;
}
@media (min-width: 1200px) {
  .presentation-slides .slides-box {
    padding: 8px 16px 8px 32px;
  }
}
.presentation-slides .slides-box img {
  width: 144px;
  overflow: hidden;
  border-width: 4px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  margin: 4px;
}
[webcast-background] .presentation-slides .slides-box {
  background: transparent;
}
.no-presentation.no-video .presentation-slides {
  height: 100%;
}
