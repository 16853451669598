.vbUiBtnDanger {
  composes: vbUiBtn from './vb-btn-secondary.module.less';
  background-color: #c41616;
  border-color: #c41616;
  color: #fff;
  cursor: pointer;
  position: relative;
  border-width: 1px;
  border-style: solid;
}
.vbUiBtnDanger:hover:not([disabled]),
.vbUiBtnDanger:focus {
  background: rgba(196, 22, 22, 0.75);
  color: #fff;
  text-decoration: none;
}
.vbUiBtnDanger[disabled] {
  opacity: 0.5;
}
.vbUiBtnDanger :global(.glyphicons) {
  color: inherit !important;
}
