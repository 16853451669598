.root {
  composes: btn-group from global;
}
.button {
  composes: webcastNavBtn from '../WebcastNav.module.less';
}
.active {
  composes: active from '../WebcastNav.module.less';
}
@media (max-width: 991px) {
  .label,
  .slides {
    display: none;
  }
}
