.tooltipContainer {
	:global(.tooltip-arrow) {
		left: 10px !important;
	}
}

.questionWrapper {
	white-space: pre-line;
	max-height: 30vh;
	overflow-y: auto;
}

.header {
	composes: theme-primary-font-border-fade-50 from global;
	background: none;
}
