@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-shared/media/shareEmbed/share-embed.less";

share-link {
	label {
		&:extend(share-embed label);
	}

	textarea {
		&:extend(share-embed textarea);

		&[readonly] {
			&:extend(share-embed textarea[readonly]);
		}
	}

	input[type="text"] {
		&:extend(share-embed input[type="text"]);

		&.ng-invalid {
			&:extend(share-embed input[type="text"].ng-invalid);
		}
	}

	.textarea-bottom-controls {
		&:extend(share-embed .textarea-bottom-controls);
	}

	.video-share-link-start-at {
		width: 60px;

		&.form-control[disabled] {
			background: none;
			border: none;
			.BoxShadow(none);
		}
	}

	.video-share-start-at-invalid {
		color: @warningColor;
		margin: @typeUnit1x;
	}

	.btn.spark-button {
		padding: 16px 20px;

		.spark-icon {
			left: 25%;
			top: 6px;
		}
	}
}