@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

video-expiration-options {
	display: block;

	.video-expiration-fixed-height {
		display: block;
		min-height: 130px;
	}

	.expiration-rule {
		/*override the select.css(from ui-select) settings as per our need*/
		.ui-select-container {
			.ui-select-match-wrapper(@type14, 31px, 8px);

			&.ul-select-lg {
				.ui-select-match-wrapper(@type18, 46px, 13px);
			}

			.btn-default-focus {
				border: 1px solid @accentColor;
		 	    outline: 0 !important;
		 	    box-shadow: inset 0 0 0 1px @accentColor, 0 0 10px @blackFade25;
			}

			.ui-select-choices {
				.select-choices-row-items {
					padding: 3px 20px;
				}
			}
		}

		.ui-select-match-wrapper(@fontSize, @controlHeight, @paddingTop) {
			.ui-select-match {
				outline: none;

				.form-control {
				 	height: @controlHeight;
				 	padding-top: @paddingTop;
				 	font-size: @type14;
				 }
			}
		}

		.ui-select-bootstrap .ui-select-choices-row {
			> span {
				padding: 0;
			}

		}
		.ui-select-placeholder {
			display: block;
		}
	}
}
