@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.ppt-loading-wrapper {
	text-align: center;
	padding: 2em;
}

.presentation-slide {
	text-align: center;

	img {
		max-width: 100%;
		margin: 0 auto;
	}
}

.presentation-is-full-screen {
	.presentation-slide {
		text-align: center;
	}
}

.presentation-controls {
	.icon-slide-prev,
	.icon-slide-next {
		position: absolute;
		top: 50%;
		font-size: @type48;
		color: @gray50;
		text-shadow: 1px 1px 4px @black;
		opacity: .25;
	}

	.icon-slide-prev {
		left: 0;
	}

	.icon-slide-next {
		right: 0;
	}
}

.webcast-presentation-msg-container {
	&:extend(.AbsoluteCenter);
	.RoundedShape(@defaultBorderRadius);
}
